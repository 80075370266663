import { TowRequestStatus } from '@enums/tow-request-status.enum';
import { TowRequest } from '@models/tow-request.model';
import { createAction, props } from '@ngrx/store';

// Get Tow Requests
export const getTowRequestsRequest = createAction(
    '[TowRequest] Get Tow Requests Request',
    props<{ towId?: string, userId?: string, statuses?: TowRequestStatus[], vehicleId?: string }>()
);
export const getTowRequestsFailure = createAction(
    '[TowRequest] Get Tow Requests Failure',
    props<{ error: string }>()
);
export const getTowRequestsSuccess = createAction(
    '[TowRequest] Get Tow Requests Success',
    props<{ towRequests: TowRequest[] }>()
);

// Get Tow Requests Changes
export const getTowRequestsChangesRequest = createAction(
    '[TowRequest] Get Tow Requests Changes Request',
    props<{ towId?: string, userId?: string, statuses?: TowRequestStatus[], vehicleId?: string, notExpired?: boolean }>()
);
export const getTowRequestsChangesLoaded = createAction(
    '[TowRequest] Get Tow Requests Changes Loaded'
);
export const getTowRequestsChangesSuccess = createAction(
    '[TowRequest] Get Tow Requests Changes Success',
    props<{ towRequests: TowRequest[] }>()
);

// Get Tow Request
export const getTowRequestRequest = createAction(
    '[TowRequest] Get Tow Request Request',
    props<{ id: string }>()
);
export const getTowRequestFailure = createAction(
    '[TowRequest] Get Tow Request Failure',
    props<{ error: string }>()
);
export const getTowRequestSuccess = createAction(
    '[TowRequest] Get TowR equest Success',
    props<{ towRequest: TowRequest }>()
);

// Save Tow Request
export const saveTowRequestRequest = createAction(
    '[TowRequest] Save Tow Request Request',
    props<{ towRequest: TowRequest }>()
);
export const saveTowRequestFailure = createAction(
    '[TowRequest] Save Tow Request Failure',
    props<{ error: string }>()
);
export const saveTowRequestSuccess = createAction(
    '[TowRequest] Save Tow Request Success',
    props<{ towRequest: TowRequest }>()
);

// Delete Tow Request
export const deleteTowRequestRequest = createAction(
    '[TowRequest] Delete Tow Request Request',
    props<{ towRequest: TowRequest }>()
);
export const deleteTowRequestFailure = createAction(
    '[TowRequest] Delete Tow Request Failure',
    props<{ error: string }>()
);
export const deleteTowRequestSuccess = createAction(
    '[TowRequest] Delete Tow Request Success',
    props<{ id: string }>()
);

// Clear Tow Requests
export const clearTowRequests = createAction(
    '[TowRequest] Clear Tow Requests',
    props<{ userId: string }>()
);

// Unsubscribe Tow Requests Changes
export const unsubscribeTowRequestsChangesRequest = createAction(
    '[TowRequest] Unsubscribe Tow Requests Changes Request'
);
export const unsubscribeTowRequestsChangesSuccess = createAction(
    '[TowRequest] Unsubscribe Tow Requests Changes Success'
);

// Update Tow Requests
export const updateTowRequestsRequest = createAction(
    '[TowRequest] Update Tow Requests Request',
    props<{ towRequests: TowRequest[], clearStore?: boolean, userId?: string }>()
);
export const updateTowRequestsFailure = createAction(
    '[TowRequest] Update Tow Requests Failure',
    props<{ error: string }>()
);
export const updateTowRequestsSuccess = createAction(
    '[TowRequest] Update Tow Requests Success',
    props<{ towRequests: TowRequest[] }>()
);
