import { DocumentData, QuerySnapshot } from '@angular/fire/firestore';
import { Sort } from '@models/sort.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UtilityHelper } from './utility-helper';
import { KeyValue } from '@angular/common';
import { Filter } from '@models/filter.model';
import { DateHelper } from './date-helper';

export class ListHelper {
    static castObservableList<T>(list$: Observable<any[]>): Observable<T[]> {
        return list$.pipe(
            map(list => list.map(x => x as T))
        );
    }

    static convertObservableQuerySnapshotToList<T>(data$: Observable<QuerySnapshot<DocumentData>>): Observable<T[]> {
        return data$.pipe(
            map(data => {
                const list: T[] = [];
                data.docs.forEach(x => {
                    const obj = { ...x.data(), id: x.id } as unknown as T;
                    list.push(obj);
                });
                return list;
            })
        );
    }

    static splitListIntoChunks<T>(list: T[], chunkSize: number): T[][] {
        const newList: T[][] = [];

        for (let i = 0; i < list.length; i += chunkSize) {
            const chunk = list.slice(i, i + chunkSize) as T[];
            newList.push(chunk);
        }

        return newList;
    }

    static sortList<T>(list: T[], sort: Sort): T[] {
        return [...list].sort((a, b) => {
            const result = UtilityHelper.compareValues(this.getValue(a, sort.field), this.getValue(b, sort.field));
            return sort.direction === 'asc' ? result : -result;
        });
    }

    static removeDuplicatesFromList<T>(list: T[]): T[] {
        if (DateHelper.isDate(list[0])) {
            return this.removeDuplicateDatesFromList(list as Date[]) as T[];
        }

        return [...new Set(list)];
    }

    static removeDuplicateDatesFromList(list: Date[]): Date[] {
        return list.map(x => x.getTime())
            .filter((time, i, array) => array.indexOf(time) === i)
            .map(time => new Date(time));
    }

    static removeDuplicatesFromListByField<T>(list: T[], field: string): T[] {
        const unique: T[] = [];
        list.forEach(item => {
            if (!unique.find(x => (x as any)[field] === (item as any)[field])) {
                unique.push(item);
            }
        });
        return unique;
    }

    static cloneList<T>(list: T): T {
        return Object.assign([], list);
    }

    static getEnumList<T>(enumType: T): T[] {
        const values = Object.values(enumType as any);
        const numbers = values.filter(x => typeof x === 'number');
        const enums = numbers.map(x => x as T);
        return enums;
    }

    static getEnumStringList<T>(enumType: T): string[] {
        const e = enumType as any;
        return Object.keys(e).map(key => e[key]).filter(value => typeof value === 'string') as string[];
    }

    static getEnumKeyValueList<T>(enumType: T): KeyValue<number, string>[] {
        const list: KeyValue<number, string>[] = [];
        const e = enumType as any;
        const keys = this.getEnumList(enumType);
        keys.forEach(key => {
            const value = e[key];
            const kv: KeyValue<number, string> = { key: +key, value };
            list.push(kv);
        });
        return list;
    }

    static convertDictionaryToKeyValueList<TKey, TValue>(dictionary: any): KeyValue<TKey, TValue>[] {
        const list: KeyValue<TKey, TValue>[] = [];
        const keys = Object.keys(dictionary) as TKey[];
        keys.forEach(key => {
            const value = dictionary[key];
            const kv: KeyValue<TKey, TValue> = { key, value };
            list.push(kv);
        });
        return list;
    }

    static getNumberList(start: number, end: number): number[] {
        return [...Array(end - start + 1).keys()].map(x => x + start);
    }

    static getMinValueFromPropertyInList<T>(list: T[], property: string): number {
        return Math.min(...list.map(x => (x as any)[property]));
    }

    static getMaxValueFromPropertyInList<T>(list: T[], property: string): number {
        return Math.max(...list.map(x => (x as any)[property]));
    }

    static filterList<T>(list: T[], filters: Filter[]): T[] {
        return list.filter(x => {
            let matchesFound = 0;

            for (const filter of filters) {
                // Skip if the value is null or the key they wanna filter by is not part of this object
                if (filter.value === null || !(filter.field)) {
                    matchesFound++;
                    continue;
                }

                const obj = x as any;
                const temp = this.getValue(obj, filter.field);
                const value = (`${temp}`).toLowerCase().replace('null', '');
                const filterValue = filter.value;

                switch (filter.operator) {
                    case '==':
                        if (value === filterValue.toString().toLowerCase()) {
                            matchesFound++;
                        }
                        break;
                    case '>=':
                        if (obj[filter.field] >= filterValue) {
                            matchesFound++;
                        }
                        break;
                    case '>':
                        if (obj[filter.field] > filterValue) {
                            matchesFound++;
                        }
                        break;
                    case '<=':
                        if (obj[filter.field] <= filterValue) {
                            matchesFound++;
                        }
                        break;
                    case '<':
                        if (obj[filter.field] < filterValue) {
                            matchesFound++;
                        }
                        break;
                    case '!=':
                        if (value !== filterValue.toString().toLowerCase()) {
                            matchesFound++;
                        }
                        break;
                }
            }

            return matchesFound === filters.length;
        });
    }

    static convertMapToList<TKey, TValue>(m: Map<TKey, TValue>): TValue[] {
        const list = Array.from(m.values());
        return list;
    }

    private static getValue<T1, T2>(obj: T1, property: string): T2 {
        const nestedProperties = this.getNestedProperties(property);
        if (Array.isArray(nestedProperties)) {
            const value = this.getNestedValue(obj, nestedProperties);
            return value as T2;
        }

        return (obj as any)[property];
    }

    private static getNestedProperties(str: string): string[] | string {
        const delimiter = '.';
        if (!str.includes(delimiter)) {
            return str;
        }

        const nestedProperties = str.split(delimiter);
        return nestedProperties;
    }

    private static getNestedValue<T1, T2>(obj: T1, nestedProperties: string[]): T2 | T1 {
        if (nestedProperties.length === 0) {
            return obj;
        }

        const property = nestedProperties.shift()!;
        const temp = (obj as any)[property];
        if (typeof temp === 'object') {
            return this.getNestedValue(temp, nestedProperties);
        }

        return temp;
    }
}
