import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UtilityHelper } from '@classes/utility-helper';
import { environment } from '@environments/environment';
import { GeoData } from '@models/geo-data.model';

@Component({
    selector: 'app-directions',
    templateUrl: './directions.component.html',
    styleUrls: ['./directions.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DirectionsComponent implements OnInit {
    @Input() text!: string;
    @Input() geoData!: GeoData;
    @Input() color = 'primary';

    url!: string;

    ngOnInit() {
        let url = environment.googleApi.directionsUrl.replace('{address}', encodeURIComponent(this.geoData.address!));

        if (UtilityHelper.isIOS()) {
            url = url.replace('https://', 'maps://');
        }

        this.url = url;
    }
}
