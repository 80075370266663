import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { State } from './state';
import { Tow } from '@models/tow.model';
import { TowStatus } from '@enums/tow-status.enum';
import { DateHelper } from '@classes/date-helper';
import { ListHelper } from '@classes/list-helper';

export const TOW_IN_PROGRESS_STATUSES = [TowStatus.Pending, TowStatus.Started, TowStatus.DriverOnWay, TowStatus.PickedUp,
    TowStatus.TrailerOnWay, TowStatus.Delivered, TowStatus.Completed, TowStatus.Rated];

export const DRIVER_MOVING_TOW_STATUSES = [TowStatus.DriverOnWay, TowStatus.PickedUp, TowStatus.TrailerOnWay, TowStatus.Delivered];

const removeExpiredTows = (tows: Tow[], driverUserId?: string, excludeStatuses: TowStatus[] = [],
    includeStatuses: TowStatus[] = []): Tow[] => {
    // Keep the ones that are in progress (except 'Pending') as those are still in route
    excludeStatuses.push(TowStatus.Pending);
    let statuses = TOW_IN_PROGRESS_STATUSES.filter(x => !excludeStatuses.includes(x));
    statuses = statuses.concat(includeStatuses);
    let towsInProgress = tows.filter(x => statuses.includes(x.status));

    if (driverUserId) {
        // Only keep the tows in progress that are assigned to the logged-in user (driverUserId)
        towsInProgress = towsInProgress.filter(x => x.approvedRequest?.driverDetails.user.id === driverUserId);
    }

    // Remove the pending ones that are expired
    const pending = tows.filter(x => x.status === TowStatus.Pending);
    const nonExpired = pending.filter(x => DateHelper.compareDates(x.datetime, '>=', new Date()));
    const activeTows = towsInProgress.concat(nonExpired);
    return activeTows;
};

export const getState: MemoizedSelector<object, State> = createFeatureSelector<State>('tow');

export const getTotal: MemoizedSelector<object, number> = createSelector(getState, (state: State) => state.total as number);

export const getLoading: MemoizedSelector<object, boolean> = createSelector(getState, (state: State) => state.loading);

export const getError: MemoizedSelector<object, string> = createSelector(getState, (state: State) => state.error);

export const getTows = (userId?: string, excludeDriverUserId?: string) => createSelector(getState, (state: State) => {
    let tows = Object.values(state.entities) as Tow[];

    if (userId) {
        tows = tows.filter(x => x.user.id === userId);
    }

    if (excludeDriverUserId) {
        tows = tows.filter(x => x.user.id !== excludeDriverUserId);
    }

    const activeTows = removeExpiredTows(tows, excludeDriverUserId);
    return activeTows;
});

export const getTow = (id: string) => createSelector(getState, (state: State) => state.entities[id] as Tow);

export const getTowsInProgress = (excludeStatuses: TowStatus[] = [], includeStatuses: TowStatus[] = [], userId?: string,
    excludeUserId?: string) => createSelector(getState, (state: State) => {
    let tows = Object.values(state.entities) as Tow[];

    if (userId) {
        tows = tows.filter(x => x.user.id === userId);
    }

    if (excludeUserId) {
        tows = tows.filter(x => x.user.id !== excludeUserId);
    }

    const activeTows = removeExpiredTows(tows, undefined, excludeStatuses, includeStatuses);
    return activeTows;
});

export const getRatedAndArchivedTows = (userId?: string, driverId?: string) => createSelector(getState, (state: State) => {
    const tows = Object.values(state.entities) as Tow[];
    let archivedTows = tows.filter(x => [TowStatus.Rated, TowStatus.Archived].includes(x.status));

    if (userId) {
        archivedTows = archivedTows.filter(x => x.user.id === userId);
    }

    if (driverId) {
        archivedTows = archivedTows.filter(x => x.approvedRequest?.driverDetails.user.id === driverId);
    }

    return archivedTows;
});

export const getTowStartingSoonest = (driverUserId: string) => createSelector(getState, (state: State) => {
    const tows = Object.values(state.entities) as Tow[];
    let startedTows = tows.filter(x => x.status === TowStatus.Started);

    if (driverUserId) {
        startedTows = startedTows.filter(x => x.approvedRequest?.driverDetails.user.id === driverUserId);
    }

    const nonExpired = startedTows.filter(x => DateHelper.compareDates(x.datetime, '>=', new Date()));
    const sorted = ListHelper.sortList(nonExpired, { field: 'datetime', direction: 'asc' });
    return sorted.length > 0 ? sorted[0] : undefined;
});
