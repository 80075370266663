import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { adapter, State } from './state';
import { Rating } from '@models/rating.model';

export const getState: MemoizedSelector<object, State> = createFeatureSelector<State>('rating');

export const getLoading: MemoizedSelector<object, boolean> = createSelector(getState, (state: State) => state.loading);

export const getError: MemoizedSelector<object, string> = createSelector(getState, (state: State) => state.error);

export const getTotal: MemoizedSelector<object, number> = createSelector(getState, (state: State) => state.total);

export const getRatings: MemoizedSelector<object, Rating[]> = createSelector(getState, adapter.getSelectors().selectAll);

export const getRating = (id: string) => createSelector(getState, (state: State) => state.entities[id] as Rating);
