import { Injectable } from '@angular/core';
import { DbService } from './db.service';
import { Observable, of, tap } from 'rxjs';
import { GasPrice } from '@models/gas-price.model';
import { Cache } from '@classes/cache';

const COLLECTION = 'gasPrices';

@Injectable({
    providedIn: 'root'
})
export class GasPriceService {
    constructor(
        private dbService: DbService
    ) {}

    getGasPrice(state: string): Observable<GasPrice> {
        const cache = Cache.get<GasPrice>(state);
        if (cache) {
            return of(cache);
        }

        return this.dbService.getObj<GasPrice>(COLLECTION, state).pipe(
            tap(gasPrice => Cache.add(state, gasPrice))
        );
    }
}
