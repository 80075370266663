import { ObjectHelper } from './object-helper';

export class DateHelper {
    static addSeconds(datetime: Date, seconds: number): Date {
        const date = new Date(datetime);
        date.setSeconds(datetime.getSeconds() + seconds);
        return date;
    }

    static subtractSeconds(datetime: Date, seconds: number): Date {
        const date = new Date(datetime);
        date.setSeconds(datetime.getSeconds() - seconds);
        return date;
    }

    static addMinutes(datetime: Date, minutes: number): Date {
        const date = new Date(datetime);
        date.setMinutes(datetime.getMinutes() + minutes);
        return date;
    }

    static subtractMinutes(datetime: Date, minutes: number): Date {
        const date = new Date(datetime);
        date.setMinutes(datetime.getMinutes() - minutes);
        return date;
    }

    static addHours(datetime: Date, hours: number): Date {
        const date = new Date(datetime);
        date.setHours(datetime.getHours() + hours);
        return date;
    }

    static addDays(datetime: Date, days: number): Date {
        const date = new Date(datetime);
        date.setDate(datetime.getDate() + days);
        return date;
    }

    static subtractDays(datetime: Date, days: number): Date {
        const date = new Date(datetime);
        date.setDate(datetime.getDate() - days);
        return date;
    }

    static addYears(datetime: Date, years: number): Date {
        const date = new Date(datetime);
        date.setFullYear(datetime.getFullYear() + years);
        return date;
    }

    static subtractYears(datetime: Date, years: number): Date {
        const date = new Date(datetime);
        date.setFullYear(datetime.getFullYear() - years);
        return date;
    }

    static compareDates(date1: Date, operator: '<' | '<=' | '>' | '>=' | '==', date2: Date): boolean {
        const time1 = date1.getTime();
        const time2 = date2.getTime();
        switch (operator) {
            case '<':
                return time1 < time2;
            case '<=':
                return time1 <= time2;
            case '>':
                return time1 > time2;
            case '>=':
                return time1 >= time2;
        }

        return time1 === time2;
    }

    static isTimestamp(date: any): boolean {
        // If it has a property called seconds is a Timestamp object
        return !!date.seconds;
    }

    static convertTimestampToDate(date: any): Date {
        return new Date(date.seconds * 1000);
    }

    static getDateWithNoTime(date: Date): Date {
        const month = date.getMonth();
        const day = date.getDate();
        const year = date.getFullYear();
        const noTimeDate = new Date(year, month, day);
        return noTimeDate;
    }

    static getToday(): Date {
        const now = new Date();
        const today = this.getDateWithNoTime(now);
        return today;
    }

    static getDaysBetweenDates(smallerDate: Date, biggerDate: Date): number {
        const time = biggerDate.getTime() - smallerDate.getTime();
        const days = time / (1000 * 60 * 60 * 24);
        return Math.ceil(days);
    }

    static isDate<T>(date: T): boolean {
        return date instanceof Date;
    }

    static setTime(date: Date, hours = 0, minutes = 0, seconds = 0): Date {
        const updatedDate = ObjectHelper.cloneObject(date);
        updatedDate.setHours(hours);
        updatedDate.setMinutes(minutes);
        updatedDate.setSeconds(seconds);
        return updatedDate;
    }

    static convertSecondsToMinutes(seconds: number): number {
        const minutes = Math.ceil(seconds / 60);
        return minutes;
    }

    static getDaysHoursMinutesAndSecondsRemaining(smallerDate: Date, biggerDate: Date):
        { days: number, hours: number, minutes: number, seconds: number } {
        // Get total seconds between the times
        let delta = Math.abs(biggerDate.getTime() - smallerDate.getTime()) / 1000;

        // Calculate (and subtract) whole days
        const days = Math.floor(delta / 86400);
        delta -= days * 86400;

        // Calculate (and subtract) whole hours
        const hours = Math.floor(delta / 3600) % 24;
        delta -= hours * 3600;

        // Calculate (and subtract) whole minutes
        const minutes = Math.floor(delta / 60) % 60;
        delta -= minutes * 60;

        // What's left is seconds
        const seconds = Math.floor(delta) % 60;
        return { days, hours, minutes, seconds };
    }
}
