import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { UserRatings } from '@models/user-ratings.model';

export const adapter: EntityAdapter<UserRatings> = createEntityAdapter<UserRatings>({
    selectId: userUserRatings => userUserRatings.userId
});

export interface State extends EntityState<UserRatings> {
    loading: boolean;
    error?: any;
}

export const initialState: State = adapter.getInitialState({
    loading: false,
    error: null
});
