import { RatingType } from '@enums/rating-type.enum';
import { UserRatings } from '@models/user-ratings.model';
import { createAction, props } from '@ngrx/store';

// Get User Ratings
export const getUserRatingsRequest = createAction(
    '[UserRatings] Get User Ratings Request',
    props<{ userId: string, ratingType: RatingType }>()
);
export const getUserRatingsFailure = createAction(
    '[UserRatings] Get User Ratings Failure',
    props<{ error: string }>()
);
export const getUserRatingsSuccess = createAction(
    '[UserRatings] Get User Ratings Success',
    props<{ userRatings: UserRatings }>()
);

// Clear User Ratings
export const clearUserRatings = createAction(
    '[UserRatings] Clear User Ratings'
);
