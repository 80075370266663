import { ChangeDetectionStrategy, Component, EventEmitter, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ChatUpdateRequest } from '@models/chat-update-request.model';
import { Chat } from '@models/chat.model';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-trailer-map-chat',
    templateUrl: './trailer-map-chat.component.html',
    styleUrls: ['./trailer-map-chat.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TrailerMapChatComponent {
    @Input() chat$!: Observable<Chat>;
    @Input() userId!: string;
    @Input() updateChat: EventEmitter<ChatUpdateRequest>;

    constructor(
        private modalController: ModalController
    ) {}

    onUpdateChat(chatUpdateRequest: ChatUpdateRequest): void {
        this.updateChat.emit(chatUpdateRequest);
    }

    onClose(): void {
        this.modalController.dismiss();
    }
}
