import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
    name: 'twoLineAddress'
})
export class TwoLineAddressPipe implements PipeTransform {
    constructor(
        private sanitizer: DomSanitizer
    ) {}

    transform(value?: string): SafeHtml {
        if (!value) {
            return '';
        }

        const separator = ', ';
        const arr = value.split(separator);
        const index = arr.length - 3;
        arr.splice(index, 0, '<br>');
        const temp = arr.join(separator);
        const address = temp.replace(', <br>, ', '<br>');
        const html = this.sanitizer.bypassSecurityTrustHtml(address);
        return html;
    }
}
