import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as actions from './actions';
import { NotificationService } from '@services/notification.service';

@Injectable()
export class NotificationStoreEffects {
    constructor(
        private actions$: Actions,
        private notificationService: NotificationService
    ) {}

    // Request Permission
    requestPermission$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.requestPermissionRequest),
            switchMap(() => this.notificationService.requestPermission().pipe(
                map(token => actions.requestPermissionSuccess({ token })),
                catchError(error => of(actions.requestPermissionFailure({ error })))
            ))
        )
    );

    // Push Notification
    pushNotification$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.pushNotificationRequest),
            switchMap(action => this.notificationService.pushNotification(action.notification).pipe(
                map(() => actions.pushNotificationSuccess()),
                catchError(error => of(actions.pushNotificationFailure({ error })))
            ))
        )
    );
}
