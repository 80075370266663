import { NotificationPermission } from '@models/notification-permission.model';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export const ID = 0;

export const adapter: EntityAdapter<NotificationPermission> = createEntityAdapter<NotificationPermission>({
    selectId: () => ID
});

export interface State extends EntityState<NotificationPermission> {
    loading: boolean;
    error?: any;
}

export const initialState: State = adapter.getInitialState({
    loading: false,
    error: null,
    entities: {
        [ID]: {}
    }
});
