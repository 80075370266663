import { Injectable } from '@angular/core';
import { addDoc, collection, deleteDoc, doc, documentId, endAt, Firestore, getDoc, getDocs, onSnapshot, orderBy, Query, query,
    setDoc, startAt, Unsubscribe, updateDoc, where, writeBatch, getCountFromServer, getAggregateFromServer,
    sum } from '@angular/fire/firestore';
import { ListHelper } from '@classes/list-helper';
import { ObjectHelper } from '@classes/object-helper';
import { Filter } from '@models/filter.model';
import { Sort } from '@models/sort.model';
import { DocumentData, DocumentReference } from 'rxfire/firestore/interfaces';
import { catchError, from, map, NotFoundError, Observable, Subject, switchMap, take } from 'rxjs';

// Note: take(1) added to all calls cause they were executing again when logging out

@Injectable({
    providedIn: 'root'
})
export class DbService {
    constructor(
        private firestore: Firestore
    ) {}

    getList<T>(path: string, filters?: Filter[], sort?: Sort, startAtValue?: any, endAtValue?: any,
        otherFixes?: Function): Observable<T[]> {
        const q = this.list(path, filters, sort, startAtValue, endAtValue);
        const data$ = from(getDocs(q));
        return ListHelper.convertObservableQuerySnapshotToList<T>(data$).pipe(
            map(list => {
                list.forEach(obj => {
                    this.fixObj(obj, undefined, otherFixes);
                });
                return list;
            }),
            catchError(error => {
                console.error(error);
                throw error;
            }),
            take(1)
        );
    }

    getListChanges<T>(path: string, sub$: Subject<T[]>, filters?: Filter[], sort?: Sort, startAtValue?: any, endAtValue?: any,
        otherFixes?: Function):
        Unsubscribe {
        const q = this.list(path, filters, sort, startAtValue, endAtValue);
        const unsubs = onSnapshot(q, x => {
            const list: T[] = [];
            x.docs.forEach(y => {
                const obj = y.data() as T;
                otherFixes?.(obj);
                this.fixObj(obj, y.id);
                list.push(obj);
            });
            sub$?.next(list);
        });
        return unsubs;
    }

    getObj<T>(path: string, id: string): Observable<T> {
        const docRef = doc(this.firestore, path, id);
        return from(getDoc(docRef)).pipe(
            map(x => {
                const obj = x.data() as T;
                if (!obj) {
                    throw NotFoundError;
                }

                this.fixObj(obj, id);
                return obj;
            }),
            catchError(error => {
                console.error(error);
                throw error;
            }),
            take(1)
        );
    }

    getObjChanges<T>(path: string, id: string, sub$: Subject<T>, otherFixes?: Function): Unsubscribe {
        const docRef = doc(this.firestore, path, id);
        const unsub = onSnapshot(docRef, x => {
            const obj = x.data() as T;
            if (!obj) {
                throw NotFoundError;
            }

            this.fixObj(obj, id, otherFixes);
            sub$.next(obj);
        });
        return unsub;
    }

    saveObj<T>(path: string, obj: T, type: 'add' | 'set' = 'add'): Observable<T> {
        obj = ObjectHelper.cloneObject(obj);
        const data = obj as any;
        const id = data.id;
        delete data.id;
        ObjectHelper.removeEmptyProperties(data);

        const collectionRef = collection(this.firestore, path);
        let docRef!: DocumentReference<DocumentData>;
        if (id) {
            docRef = doc(this.firestore, path, id);
        }

        switch (type) {
            case 'set':
                return from(getDoc(docRef)).pipe(
                    switchMap(ds => {
                        if (ds.exists()) {
                            return this.update<T>(data, id, docRef);
                        }

                        data.creationDate = new Date();
                        return from(setDoc(doc(this.firestore, path, id), data)).pipe(
                            map(() => {
                                return { ...data, id } as T;
                            })
                        );
                    }),
                    catchError(error => {
                        console.error(error);
                        throw error;
                    }),
                    take(1)
                );
            case 'add':
            default:
                if (id) {
                    return this.update<T>(data, id, docRef);
                }

                data.creationDate = new Date();
                return from(addDoc(collectionRef, data)).pipe(
                    map(x => {
                        return { ...data, id: x.id } as T;
                    }),
                    catchError(error => {
                        console.error(error);
                        throw error;
                    }),
                    take(1)
                );
        }
    }

    deleteObj(path: string, id: string): Observable<string> {
        const docRef = doc(this.firestore, path, id);
        return from(deleteDoc(docRef)).pipe(
            map(() => id),
            catchError(error => {
                console.error(error);
                throw error;
            }),
            take(1)
        );
    }

    updateList<T>(path: string, list: T[]): Observable<T[]> {
        const batch = writeBatch(this.firestore);

        list.forEach(obj => {
            const docRef = doc(this.firestore, path, (obj as any).id);
            batch.update(docRef, obj as any);
        });

        return from(batch.commit()).pipe(
            map(() => list)
        );
    }

    updateObjProperties<T>(path: string, id: string, data: T): Observable<T> {
        const docRef = doc(this.firestore, path, id);
        return this.update<T>(data, id, docRef);
    }

    getCount(path: string, filters?: Filter[]): Observable<number> {
        const q = this.list(path, filters);
        const snapshot$ = from(getCountFromServer(q));
        return snapshot$.pipe(map(snapshot => snapshot.data().count));
    }

    getSum(path: string, field: string, filters?: Filter[]): Observable<number> {
        const q = this.list(path, filters);
        const snapshot$ = from(getAggregateFromServer(q, {
            sum: sum(field)
        }));
        return snapshot$.pipe(map(snapshot => snapshot.data().sum));
    }

    private list(path: string, filters?: Filter[], sort?: Sort, startAtValue?: any, endAtValue?: any): Query<DocumentData> {
        const collectionRef = collection(this.firestore, path);
        let q = query(collectionRef);

        filters?.forEach(filter => {
            if (filter.field === 'id') {
                q = query(q, where(documentId(), filter.operator, filter.value));
                return;
            }

            q = query(q, where(filter.field, filter.operator, filter.value));
        });

        if (sort) {
            q = query(q, orderBy(sort.field, sort.direction));
        }

        if (startAtValue) {
            q = query(q, startAt(startAtValue));
        }

        if (endAtValue) {
            q = query(q, endAt(endAtValue));
        }

        return q;
    }

    private fixObj<T>(obj: T, id?: string, otherFixes?: Function): void {
        if (id) {
            (obj as any).id = id;
        }

        ObjectHelper.fixDate(obj, 'creationDate');
        otherFixes?.(obj);
    }

    private update<T>(data: T, id: string, docRef: DocumentReference<DocumentData>): Observable<T> {
        return from(updateDoc(docRef, data as any)).pipe(
            map(() => {
                return { ...data, id } as T;
            }),
            catchError(error => {
                console.error(error);
                throw error;
            }),
            take(1)
        );
    }
}
