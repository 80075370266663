import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as actions from './actions';
import { RateService } from '@services/rate.service';

@Injectable()
export class RateStoreEffects {
    constructor(
        private actions$: Actions,
        private rateService: RateService
    ) {}

    // Get Rates
    getRates$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getRatesRequest),
            switchMap(action => this.rateService.getRates(action.category, action.rateType).pipe(
                map(rates => actions.getRatesSuccess({ rates })),
                catchError(error => of(actions.getRatesFailure({ error })))
            ))
        )
    );
}
