import { AfterViewInit, ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { IonFooter, ModalController } from '@ionic/angular';

@Component({
    selector: 'app-terms-of-use',
    templateUrl: './terms-of-use.component.html',
    styleUrls: ['./terms-of-use.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TermsOfUseComponent implements AfterViewInit {
    @ViewChild(IonFooter) footer: any;

    constructor(
        private modalController: ModalController
    ) {}

    ngAfterViewInit(): void {
        this.footer.el.style.opacity = 1; // Hack to solve issue with iOS
    }

    onClose(): void {
        this.modalController.dismiss();
    }
}
