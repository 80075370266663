<div>
    <ng-container *ngIf="driverAddress">
        <div>
            <i class="material-symbols-outlined">{{ experience === 'trailer' ? 'person_pin_circle' : isHome ? 'home_pin' : 'person_pin_circle' }}</i>
            <div>
                <label>{{ driverAddressLabel }}</label>
                <div [innerHTML]="driverAddress | twoLineAddress"></div>
            </div>
        </div>
        <app-down-arrow class="arrow"></app-down-arrow>
    </ng-container>
    <div>
        <i class="material-symbols-outlined">location_on</i>
        <div>
            <label><app-directions text="Pick-Up" [geoData]="start"></app-directions></label>
            <div [innerHTML]="start.address | twoLineAddress"></div>
        </div>
    </div>
    <app-down-arrow class="arrow"></app-down-arrow>
    <div>
        <i class="material-symbols-outlined">where_to_vote</i>
        <div>
            <label><app-directions text="Drop-Off" [geoData]="destination"></app-directions></label>
            <div [innerHTML]="destination.address | twoLineAddress"></div>
        </div>
    </div>
</div>
