import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { ListHelper } from '@classes/list-helper';
import { ModalController } from '@ionic/angular';
import { DriverDetails } from '@models/driver-details.model';
import { SortOption } from '@models/sort-option.model';
import { TowRequest } from '@models/tow-request.model';
import { BehaviorSubject, Observable, combineLatest, map } from 'rxjs';
import { SubSink } from 'subsink';

const DEFAULT_DRIVERS_SORT: SortOption = {
    field: 'payment.cost',
    direction: 'asc',
    name: 'Sort by Cost'
};

@Component({
    selector: 'app-trailer-map-drivers',
    templateUrl: './trailer-map-drivers.component.html',
    styleUrls: ['./trailer-map-drivers.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TrailerMapDriversComponent implements OnInit, OnDestroy {
    @Input() driverDetailsList$!: Observable<DriverDetails[]>;
    @Input() towRequests$!: Observable<TowRequest[]>;
    @Input() sendTowRequest!: EventEmitter<DriverDetails>;
    @Input() cancelTowRequest!: EventEmitter<TowRequest>;

    private driversSort$ = new BehaviorSubject<SortOption>(DEFAULT_DRIVERS_SORT);
    private subs = new SubSink();

    driversSortOptions: SortOption[] = [
        DEFAULT_DRIVERS_SORT,
        { field: 'user.driver.completedTows', direction: 'desc', name: 'Sort by Completed Tows' },
        { field: 'user.driver.averageRating', direction: 'desc', name: 'Sort by Rating' },
        { field: 'vehicle.towingCapacity', direction: 'desc', name: 'Sort by Tow Capacity' }
    ];
    driversSort = DEFAULT_DRIVERS_SORT;
    drivers$!: Observable<DriverDetails[]>;
    towRequests = new Map<string, TowRequest>();

    constructor(
        private modalController: ModalController,
        private changeDetectorRef: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.drivers$ = combineLatest([
            this.driverDetailsList$,
            this.driversSort$
        ]).pipe(
            map(([driverDetailsList, sort]) => {
                const sortedTowRequests = ListHelper.sortList(driverDetailsList, sort);
                return sortedTowRequests;
            })
        );

        this.subs.add(
            this.towRequests$.subscribe(towRequests => {
                towRequests.forEach(towRequest => {
                    this.towRequests.set(towRequest.driverDetails.user.id, towRequest);
                });
                this.changeDetectorRef.detectChanges();
            })
        );
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    onDriversSort(sort: SortOption): void {
        this.driversSort = sort;
        this.driversSort$.next(sort);
    }

    onSendTowRequest(driverDetails: DriverDetails): void {
        this.sendTowRequest.emit(driverDetails);
    }

    onCancelTowRequest(towRequest: TowRequest): void {
        this.cancelTowRequest.emit(towRequest);
    }

    onClose(): void {
        this.modalController.dismiss();
    }
}
