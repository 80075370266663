import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { adapter, State } from './state';
import { UserRatings } from '@models/user-ratings.model';
import { RatingType } from '@enums/rating-type.enum';

export const getState: MemoizedSelector<object, State> = createFeatureSelector<State>('userRatings');

export const getLoading: MemoizedSelector<object, boolean> = createSelector(getState, (state: State) => state.loading);

export const getError: MemoizedSelector<object, string> = createSelector(getState, (state: State) => state.error);

export const getAllUserRatings: MemoizedSelector<object, UserRatings[]> =
    createSelector(getState, adapter.getSelectors().selectAll);

export const getRatings = (userId: string, type: RatingType) => createSelector(getState, (state: State) => {
    const userRatings = state.entities[userId];
    const ratings = userRatings?.ratings.filter(x => x.type === type) ?? [];
    return ratings;
});
