import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { adapter, State } from './state';
import { Support } from '@models/support.model';

export const getState: MemoizedSelector<object, State> = createFeatureSelector<State>('support');

export const getLoading: MemoizedSelector<object, boolean> = createSelector(getState, (state: State) => state.loading);

export const getError: MemoizedSelector<object, string> = createSelector(getState, (state: State) => state.error);

export const getSupports: MemoizedSelector<object, Support[]> = createSelector(getState, adapter.getSelectors().selectAll);

export const getSupport = (id: string) => createSelector(getState, (state: State) => state.entities[id]);

export const getTotal: MemoizedSelector<object, number> = createSelector(getState, (state: State) => state.total);

export const getUnresolvedSupport = () => createSelector(getState, (state: State) => {
    const supports = Object.values(state.entities) as Support[];
    const support = supports.find(x => !x.resolved);
    return support;
});

export const getUnresolvedSupports = (excludeUserId: string) => createSelector(getState, (state: State) => {
    const supports = Object.values(state.entities) as Support[];
    const unresolvedSupports = supports.filter(x => !x.resolved && x.userId !== excludeUserId);
    return unresolvedSupports;
});
