import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Session } from '@models/session.model';

export const ID = 0;

export const adapter: EntityAdapter<Session> = createEntityAdapter<Session>({
    selectId: () => ID
});

export interface State extends EntityState<Session> {
    loading: boolean;
    error?: any;
}

export const initialState: State = adapter.getInitialState({
    loading: false,
    error: null,
    entities: {
        [ID]: {}
    }
});
