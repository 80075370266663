import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { DriverBusyTimeService } from '@services/driver-busy-time.service';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as actions from './actions';

@Injectable()
export class DriverBusyTimeStoreEffects {
    constructor(
        private actions$: Actions,
        private driverBusyTimeService: DriverBusyTimeService
    ) {}

    // Get Drivers Busy Times
    getDriversBusyTimes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getDriversBusyTimesRequest),
            switchMap(action => this.driverBusyTimeService.getDriversBusyTimes(action.driverIds, action.start, action.end).pipe(
                map(driversBusyTimes => actions.getDriversBusyTimesSuccess({ driversBusyTimes })),
                catchError(error => of(actions.getDriversBusyTimesFailure({ error })))
            ))
        )
    );

    // Get Driver Busy Times
    getDriverBusyTimes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getDriverBusyTimesRequest),
            switchMap(action => this.driverBusyTimeService.getDriverBusyTimes(action.driverId, action.start).pipe(
                map(driverBusyTimes => actions.getDriverBusyTimesSuccess({ driverBusyTimes })),
                catchError(error => of(actions.getDriverBusyTimesFailure({ error })))
            ))
        )
    );

    // Save Driver Busy Time
    saveDriverBusyTime$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.saveDriverBusyTimeRequest),
            switchMap(action => this.driverBusyTimeService.saveDriverBusyTime(action.driverBusyTime).pipe(
                map(driverBusyTime => actions.saveDriverBusyTimeSuccess({ driverBusyTime })),
                catchError(error => of(actions.saveDriverBusyTimeFailure({ error })))
            ))
        )
    );

    // Delete Driver Busy Time
    deleteDriverBusyTime$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.deleteDriverBusyTimeRequest),
            switchMap(action => this.driverBusyTimeService.deleteDriverBusyTime(action.towRequestId).pipe(
                map(id => actions.deleteDriverBusyTimeSuccess({ id })),
                catchError(error => of(actions.deleteDriverBusyTimeFailure({ error })))
            ))
        )
    );
}
