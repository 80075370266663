import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, Input,
    OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { UtilityHelper } from '@classes/utility-helper';
import { Address } from '@models/address.model';
import { State } from '@models/state.model';
import { DataService } from '@services/data.service';
import { Observable } from 'rxjs';
import { SubSink } from 'subsink';

@Component({
    selector: 'app-address',
    templateUrl: './address.component.html',
    styleUrls: ['./address.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        // eslint-disable-next-line no-use-before-define
        useExisting: forwardRef(() => AddressComponent),
        multi: true
    }]
})
export class AddressComponent implements OnInit, OnDestroy, ControlValueAccessor, AfterViewInit {
    @ViewChild('address') addressInput: ElementRef;
    @Input() address!: Address | null;
    @Output() valid = new EventEmitter<boolean>();

    private subs = new SubSink();
    private autocomplete?: google.maps.places.Autocomplete;
    private listener?: google.maps.MapsEventListener;

    form!: FormGroup;
    states$!: Observable<State[]>;

    constructor(
        private formBuilder: FormBuilder,
        private dataService: DataService,
        private changeDetectorRef: ChangeDetectorRef
    ) {}

    onTouched: () => void = () => {};

    writeValue(_: any): void {}

    registerOnChange(fn: any): void {
        this.subs.add(
            this.form.valueChanges.subscribe(fn)
        );
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            address: new FormControl<string | null>(null, [Validators.required]),
            city: new FormControl<string | null>(null, [Validators.required]),
            state: new FormControl<string | null>('', [Validators.required]),
            zipCode: new FormControl<string | null>(null, [Validators.required, Validators.minLength(5)])
        });

        if (this.address) {
            this.form.patchValue(this.address);
        }

        this.states$ = this.dataService.getStates();
        this.setAutocomplete();

        this.subs.add(
            this.form.valueChanges.subscribe(() => this.valid.emit(this.form.valid))
        );
    }

    ngAfterViewInit(): void {
        if (!UtilityHelper.isIOS()) {
            return;
        }

        // Adjust the autocomplete options position (there is an issue in iOS devices with it)
        const timer = setInterval(() => {
            const addressInputBottom = this.addressInput.nativeElement.getBoundingClientRect().bottom;
            if (!!addressInputBottom) {
                const css = `
                    .pac-container {
                        top: ${addressInputBottom}px !important;
                    }
                `;
                UtilityHelper.addStyleTag('pac-container', css);
                clearInterval(timer);
            }
        }, 100);

        // Fallback
        setTimeout(() => clearInterval(timer), 1000);
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    private setAutocomplete(): void {
        // Let's first remove them (in case they were already set on previous navigations)
        this.removeAutocomplete();

        const timer = setInterval(() => {
            // If google is undefined, the library hasn't loaded yet so skip so it will retry in the next iteration
            if (!window.google) {
                return;
            }

            const control = 'address';
            const input = document.querySelector(`input[formcontrolname=${control}]`) as HTMLInputElement;
            const autocomplete = new google.maps.places.Autocomplete(input, {
                fields: ['address_components']
            });
            const listener = autocomplete.addListener('place_changed', () => {
                const place = autocomplete.getPlace();
                const address = this.getAddress(place);
                this.form.patchValue(address);
                this.form.setErrors(null);
                this.form.markAsTouched();
                this.changeDetectorRef.detectChanges();
            });
            this.autocomplete = autocomplete;
            this.listener = listener;

            clearInterval(timer);
        }, 500);
    }

    private getAddress(result: google.maps.places.PlaceResult): Address {
        const address = {
            address: `${this.getAddressComponent(result, 'street_number')} ${this.getAddressComponent(result, 'route')}`,
            city: this.getAddressComponent(result, 'locality'),
            state: this.getAddressComponent(result, 'administrative_area_level_1'),
            zipCode: this.getAddressComponent(result, 'postal_code')
        } as Address;
        return address;
    }

    private getAddressComponent(place: google.maps.places.PlaceResult, type: string): string {
        const obj = place.address_components?.find(x => x.types.includes(type));
        return obj?.short_name ?? '';
    }

    private removeAutocomplete(): void {
        if (this.listener) {
            google.maps.event.removeListener(this.listener);
        }

        if (this.autocomplete) {
            google.maps.event.clearInstanceListeners(this.autocomplete);
        }

        this.autocomplete = undefined;
        this.listener = undefined;
        document.querySelectorAll('.pac-container').forEach(x => x.remove());
    }
}
