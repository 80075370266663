import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { reducer } from './reducer';
import { EffectsModule } from '@ngrx/effects';
import { VehicleStoreEffects } from './effects';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature('vehicle', reducer),
        EffectsModule.forFeature([VehicleStoreEffects])
    ],
    providers: []
})
export class VehicleStoreModule {}
