import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { adapter, State } from './state';
import { User } from '@models/user.model';
import { UserRole } from '@enums/user-role.enum';

export const getState: MemoizedSelector<object, State> = createFeatureSelector<State>('user');

export const getTotal: MemoizedSelector<object, number> = createSelector(getState, (state: State) => state.total as number);

export const getLoading: MemoizedSelector<object, boolean> = createSelector(getState, (state: State) => state.loading);

export const getError: MemoizedSelector<object, string> = createSelector(getState, (state: State) => state.error);

export const getUsers: MemoizedSelector<object, User[]> = createSelector(getState, adapter.getSelectors().selectAll);

export const getOtherUsers = (id: string) => createSelector(getState, (state: State) => {
    const users = Object.values(state.entities) as User[];
    return users.filter(x => x.id !== id);
});

export const getUser = (id: string) => createSelector(getState, (state: State) => state.entities[id] as User);

export const getUserOnline = (id: string) => createSelector(getState, (state: State) => !!state.entities[id]?.online);

export const getUserCurrentLocation = (id: string) => createSelector(getState, (state: State) =>
    state.entities[id]?.currentLocation);

export const getOtherDriverUsers = (id: string) => createSelector(getState, (state: State) => {
    const users = Object.values(state.entities) as User[];
    return users.filter(x => x.id !== id && !!x.driver);
});

export const getAdmins: MemoizedSelector<object, User[]> = createSelector(getState, (state: State) => {
    const users = Object.values(state.entities) as User[];
    return users.filter(x => x.role === UserRole.Admin);
});
