import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as actions from './actions';
import { TrailerService } from '@services/trailer.service';

@Injectable()
export class TrailerStoreEffects {
    constructor(
        private actions$: Actions,
        private trailerService: TrailerService
    ) {}

    // Get Trailers
    getTrailers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getTrailersRequest),
            switchMap(action => this.trailerService.getTrailers(action.userId).pipe(
                map(trailers => actions.getTrailersSuccess({ trailers })),
                catchError(error => of(actions.getTrailersFailure({ error })))
            ))
        )
    );

    // Get Trailer
    getTrailer$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getTrailerRequest),
            switchMap(action => this.trailerService.getTrailer(action.id).pipe(
                map(trailer => actions.getTrailerSuccess({ trailer })),
                catchError(error => of(actions.getTrailerFailure({ error })))
            ))
        )
    );

    // Save Trailer
    saveTrailer$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.saveTrailerRequest),
            switchMap(action => this.trailerService.saveTrailer(action.trailer).pipe(
                map(trailer => actions.saveTrailerSuccess({ trailer })),
                catchError(error => of(actions.saveTrailerFailure({ error })))
            ))
        )
    );

    // Delete Trailer
    deleteTrailer$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.deleteTrailerRequest),
            switchMap(action => this.trailerService.deleteTrailer(action.trailer).pipe(
                map(id => actions.deleteTrailerSuccess({ id })),
                catchError(error => of(actions.deleteTrailerFailure({ error })))
            ))
        )
    );
}
