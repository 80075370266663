import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { State, adapter } from './state';
import { Trailer } from '@models/trailer.model';

const getTrailersByType = (state: State, isCargo: boolean): Trailer[] => {
    const trailers = Object.values(state.entities) as Trailer[];
    const filteredTrailers = trailers.filter(x => isCargo ? x?.type === 'Cargo' : x?.type !== 'Cargo');
    return filteredTrailers;
};

export const getState: MemoizedSelector<object, State> = createFeatureSelector<State>('trailer');

export const getTotal: MemoizedSelector<object, number> = createSelector(getState, (state: State) => state.total as number);

export const getLoading: MemoizedSelector<object, boolean> = createSelector(getState, (state: State) => state.loading);

export const getError: MemoizedSelector<object, string> = createSelector(getState, (state: State) => state.error);

export const getTrailers: MemoizedSelector<object, Trailer[]> = createSelector(getState, adapter.getSelectors().selectAll);

export const getTrailer = (id: string) => createSelector(getState, (state: State) => state.entities[id] as Trailer);

export const getNonCargoTrailers: MemoizedSelector<object, Trailer[]> = createSelector(getState, (state: State) =>
    getTrailersByType(state, false));

export const getTotalNonCargoTrailers: MemoizedSelector<object, number> = createSelector(getState, (state: State) =>
    getTrailersByType(state, false).length);
