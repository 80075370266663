import { createAction, props } from '@ngrx/store';

// Get Years
export const getAllowedVehicleYearsRequest = createAction(
    '[Data] Get Allowed Vehicle Years Request'
);
export const getAllowedVehicleYearsFailure = createAction(
    '[Data] Get Allowed Vehicle Years Failure',
    props<{ error: string }>()
);
export const getAllowedVehicleYearsSuccess = createAction(
    '[Data] Get Allowed Vehicle Years Success',
    props<{ years: number[] }>()
);
