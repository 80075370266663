import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { State, ID } from './state';

export const getState: MemoizedSelector<object, State> = createFeatureSelector<State>('notification');

export const getLoading: MemoizedSelector<object, boolean> = createSelector(getState, (state: State) => state.loading);

export const getError: MemoizedSelector<object, string> = createSelector(getState, (state: State) => state.error);

export const getToken: MemoizedSelector<object, string> = createSelector(getState, (state: State) =>
    state.entities[ID]?.token as string);
