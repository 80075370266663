import { Injectable } from '@angular/core';
import { State } from '@models/state.model';
import { map, Observable, of, tap } from 'rxjs';
import states from '../../../assets/data/states.json';
import trailerClasses from '../../../assets/data/trailer-classes.json';
import couplerSizes from '../../../assets/data/coupler-sizes.json';
import usStates from '../../../assets/data/us-states.json';
import driveTypes from '../../../assets/data/drive-types.json';
import trailerTypes from '../../../assets/data/trailer-types.json';
import { CouplerSize } from '@models/coupler-size.model';
import { KeyValue } from '@angular/common';
import { ListHelper } from '@classes/list-helper';
import { TrailerClass } from '@models/trailer-class.model';
import { DbService } from './db.service';
import { Cache } from '@classes/cache';
import { AllowedVehicle } from '@models/allowed-vehicle.model';
import { TrailerType } from '@models/trailer-type.model';
import { BedLength } from '@models/bed-length.model';
import bedLengths from '../../../assets/data/bed-lengths.json';
import { PasswordChecklistItem } from '@models/password-checklist-item.model';
import passwordChecklist from '../../../assets/data/password-checklist.json';
import vehicleMileCosts from '../../../assets/data/vehicle-mile-costs.json';
import timeSurgeCosts from '../../../assets/data/time-surge-costs.json';
import { VehicleMileCost } from '@models/vehicle-mile-cost.model';
import { TimeSurgeCost } from '@models/time-surge-cost.model';
import towCosts from '../../../assets/data/tow-costs.json';
import { TowCost } from '@models/commute-cost.model';
import trailerInsuranceCoverages from '../../../assets/data/trailer-insurance-coverages.json';
import vehicleInsuranceCoverages from '../../../assets/data/vehicle-insurance-coverages.json';
import { InsuranceCoverage } from '@models/insurance-coverage.model';
import trailerSafetyChecklist from '../../../assets/data/trailer-safety-checklist.json';

@Injectable({
    providedIn: 'root'
})
export class DataService {
    constructor(
        private dbService: DbService
    ) {}

    getStates(): Observable<State[]> {
        return of(states);
    }

    getTrailerClasses(): Observable<TrailerClass[]> {
        return of(trailerClasses);
    }

    getCouplerSizes(): Observable<CouplerSize[]> {
        return of(couplerSizes);
    }

    getAllowedVehicleYears(): Observable<number[]> {
        const key = 'allowedVehicleYears';
        const cache = Cache.get<number[]>(key);
        if (cache) {
            return of(cache);
        }

        return this.dbService.getList<AllowedVehicle>('allowedVehicles').pipe(
            tap(allowedVehicles => {
                // Since we are pulling all the documents here to build the years list,
                // might as well cache them so they can be read from the allowed vehicle service
                Cache.add('allowedVehicles', allowedVehicles);
            }),
            map(allowedVehicles => {
                let years = allowedVehicles.map(x => x.year);
                years = ListHelper.removeDuplicatesFromList(years);
                years.sort();
                years.reverse();
                return years;
            }),
            tap(years => Cache.add(key, years))
        );
    }

    getUsStates(): Observable<KeyValue<string, string>[]> {
        const list = ListHelper.convertDictionaryToKeyValueList<string, string>(usStates);
        return of(list);
    }

    getDriveTypes(): Observable<string[]> {
        return of(driveTypes);
    }

    getTrailerTypes(): Observable<TrailerType[]> {
        return of(trailerTypes);
    }

    getTrailerInsuranceCoverages(): Observable<InsuranceCoverage[]> {
        return of(trailerInsuranceCoverages);
    }

    getBedLengths(): Observable<BedLength[]> {
        return of(bedLengths);
    }

    getPasswordChecklist(): Observable<PasswordChecklistItem[]> {
        return of(passwordChecklist);
    }

    getVehicleInsuranceCoverages(): Observable<InsuranceCoverage[]> {
        return of(vehicleInsuranceCoverages);
    }

    getVehicleMileCosts(): Observable<VehicleMileCost[]> {
        return of(vehicleMileCosts);
    }

    getTimeSurgeCosts(): Observable<TimeSurgeCost[]> {
        return of(timeSurgeCosts);
    }

    getTowCosts(): Observable<TowCost[]> {
        return of(towCosts);
    }

    getTrailerSafetyChecklist(): Observable<string[]> {
        return of(trailerSafetyChecklist);
    }
}
