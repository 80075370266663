import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from '@environments/environment';
import { AppModule } from './app/app.module';
import { browserTracingIntegration, init, replayIntegration } from '@sentry/angular';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import pack from '../package.json';

if (environment.production) {
    enableProdMode();
}

// Setup logging (Sentry)
init({
    environment: environment.name,
    dsn: environment.sentryDsn,
    release: `${pack.name}@${pack.version}`,

    // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring
    // Is recommended adjusting this value in production
    tracesSampleRate: environment.production ? 0.2 : 1.0,

    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: ['localhost', environment.apiBaseUrl],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,

    integrations: [
        // Registers and configures the Tracing integration, which automatically instruments your application to monitor its
        // performance, including custom Angular routing instrumentation
        browserTracingIntegration(),
        // Registers the Replay integration,
        // which automatically captures Session Replays
        replayIntegration()
    ]
});

defineCustomElements(window);

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.error(err));
