import { ChatUpdateRequest } from '@models/chat-update-request.model';
import { Chat } from '@models/chat.model';
import { createAction, props } from '@ngrx/store';

// Save Chat
export const saveChatRequest = createAction(
    '[Chat] Save Chat Request',
    props<ChatUpdateRequest>()
);
export const saveChatFailure = createAction(
    '[Chat] Save Chat Failure',
    props<{ error: string }>()
);
export const saveChatSuccess = createAction(
    '[Chat] Save Chat Success',
    props<ChatUpdateRequest>()
);

// Get Chat Changes
export const getChatChangesRequest = createAction(
    '[Chat] Get Chat Changes Request',
    props<{ id: string }>()
);
export const getChatChangesLoaded = createAction(
    '[Chat] Get Chat Changes Loaded'
);
export const getChatChangesSuccess = createAction(
    '[Chat] Get Chat Changes Success',
    props<{ chat: Chat }>()
);

// Get Chats Changes by Tow ID
export const getChatsChangesByTowIdRequest = createAction(
    '[Chat] Get Chats Changes by Tow ID Request',
    props<{ towId: string }>()
);
export const getChatsChangesByTowIdLoaded = createAction(
    '[Chat] Get Chats Changes Loaded'
);
export const getChatsChangesByTowIdSuccess = createAction(
    '[Chat] Get Chats Changes by Tow ID Success',
    props<{ chats: Chat[] }>()
);

// Unsubscribe Chat Changes
export const unsubscribeChatChangesRequest = createAction(
    '[Chat] Unsubscribe Chat Changes Request'
);
export const unsubscribeChatChangesSuccess = createAction(
    '[Chat] Unsubscribe Chat Changes Success'
);

// Get Chats
export const getChatsRequest = createAction(
    '[Chat] Get Chats Request',
    props<{ ids: string[] }>()
);
export const getChatsFailure = createAction(
    '[Chat] Get Chats Failure',
    props<{ error: string }>()
);
export const getChatsSuccess = createAction(
    '[Chat] Get Chats Success',
    props<{ chats: Chat[] }>()
);
