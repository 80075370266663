import { SMS } from '@models/sms.model';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export const ID = 0;

export const adapter: EntityAdapter<SMS> = createEntityAdapter<SMS>({
    selectId: () => ID
});

export interface State extends EntityState<SMS> {
    loading: boolean;
    error?: any;
}

export const initialState: State = adapter.getInitialState({
    loading: false,
    error: null,
    entities: {
        [ID]: {}
    }
});
