import { Trailer } from '@models/trailer.model';
import { createAction, props } from '@ngrx/store';

// Get Trailers
export const getTrailersRequest = createAction(
    '[Trailer] Get Trailers Request',
    props<{ userId: string }>()
);
export const getTrailersFailure = createAction(
    '[Trailer] Get Trailers Failure',
    props<{ error: string }>()
);
export const getTrailersSuccess = createAction(
    '[Trailer] Get Trailers Success',
    props<{ trailers: Trailer[] }>()
);

// Get Trailer
export const getTrailerRequest = createAction(
    '[Trailer] Get Trailer Request',
    props<{ id: string }>()
);
export const getTrailerFailure = createAction(
    '[Trailer] Get Trailer Failure',
    props<{ error: string }>()
);
export const getTrailerSuccess = createAction(
    '[Trailer] Get Trailer Success',
    props<{ trailer: Trailer }>()
);

// Save Trailer
export const saveTrailerRequest = createAction(
    '[Trailer] Save Trailer Request',
    props<{ trailer: Trailer }>()
);
export const saveTrailerFailure = createAction(
    '[Trailer] Save Trailer Failure',
    props<{ error: string }>()
);
export const saveTrailerSuccess = createAction(
    '[Trailer] Save Trailer Success',
    props<{ trailer: Trailer }>()
);

// Delete Trailer
export const deleteTrailerRequest = createAction(
    '[Trailer] Delete Trailer Request',
    props<{ trailer: Trailer }>()
);
export const deleteTrailerFailure = createAction(
    '[Trailer] Delete Trailer Failure',
    props<{ error: string }>()
);
export const deleteTrailerSuccess = createAction(
    '[Trailer] Delete Trailer Success',
    props<{ id: string }>()
);

// Clear Trailers
export const clearTrailers = createAction(
    '[Trailer] Clear Trailers'
);
