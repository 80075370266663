import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '@environments/environment';
import { DriverStoreModule } from './driver-store';
import { SessionStoreModule } from './session-store';
import { TrailerStoreModule } from './trailer-store';
import { UserStoreModule } from './user-store';
import { VehicleStoreModule } from './vehicle-store';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { RouteStoreModule } from './route-store';
import { DataStoreModule } from './data-store';
import { TowStoreModule } from './tow-store';
import { TowRequestStoreModule } from './tow-request-store';
import { RatingStoreModule } from './rating-store';
import { UserRatingsStoreModule } from './user-ratings-store';
import { reducers } from './reducers';
import { metaReducers } from './meta-reducers';
import { RateStoreModule } from './rate-store';
import { NotificationStoreModule } from './notification-store';
import { PaymentStoreModule } from './payment-store';
import { TransactionStoreModule } from './transaction-store';
import { PayoutStoreModule } from './payout-store';
import { AllowedVehicleStoreModule } from './allowed-vehicle-store';
import { SupportStoreModule } from './support-store';
import { SMSStoreModule } from './sms-store';
import { DisbursementStoreModule } from './disbursement-store';
import { EmailStoreModule } from './email-store';
import { GasPriceStoreModule } from './gas-price-store';
import { EarningStoreModule } from './earning-store';
import { DriverBusyTimeStoreModule } from './driver-busy-time-store';
import { ChatStoreModule } from './chat-store';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forRoot(reducers, { metaReducers }),
        EffectsModule.forRoot([]),
        environment.production ? [] : StoreDevtoolsModule.instrument(),
        StoreRouterConnectingModule.forRoot(),
        AllowedVehicleStoreModule,
        ChatStoreModule,
        DataStoreModule,
        DisbursementStoreModule,
        DriverStoreModule,
        DriverBusyTimeStoreModule,
        EarningStoreModule,
        EmailStoreModule,
        GasPriceStoreModule,
        NotificationStoreModule,
        PaymentStoreModule,
        PayoutStoreModule,
        RateStoreModule,
        RatingStoreModule,
        RouteStoreModule,
        SessionStoreModule,
        SMSStoreModule,
        SupportStoreModule,
        TowStoreModule,
        TowRequestStoreModule,
        TrailerStoreModule,
        TransactionStoreModule,
        UserStoreModule,
        UserRatingsStoreModule,
        VehicleStoreModule
    ]
})
export class RootStoreModule {}
