import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Subject, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as actions from './actions';
import { SupportService } from '@services/support.service';
import { Unsubscribe } from '@angular/fire/firestore';
import { Support } from '@models/support.model';
import { SubSink } from 'subsink';
import { Store } from '@ngrx/store';

@Injectable()
export class SupportStoreEffects {
    private unsubs: Unsubscribe[] = [];
    private subs = new SubSink();
    private multipleUnsubs: Unsubscribe[] = [];
    private multipleSubs = new SubSink();

    constructor(
        private actions$: Actions,
        private supportService: SupportService,
        private store: Store
    ) {}

    // Get Supports
    getSupports$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getSupportsRequest),
            switchMap(action => this.supportService.getSupports(action.userId, action.resolved).pipe(
                map(supports => actions.getSupportsSuccess({ supports })),
                catchError(error => of(actions.getSupportsFailure({ error })))
            ))
        )
    );

    // Save Support
    saveSupport$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.saveSupportRequest),
            switchMap(action => this.supportService.saveSupport(action.support).pipe(
                map(support => actions.saveSupportSuccess({ support })),
                catchError(error => of(actions.saveSupportFailure({ error })))
            ))
        )
    );

    // Get Support Changes
    getSupportChanges$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getSupportChangesRequest),
            map(action => {
                const sub$ = new Subject<Support>();
                this.subs.add(
                    sub$.subscribe(support => this.store.dispatch(actions.getSupportChangesSuccess({ support })))
                );
                const unsub = this.supportService.getSupportChanges(action.id, sub$);
                this.unsubs.push(unsub);
                return actions.getSupportChangesLoaded();
            })
        )
    );

    // Unsubscribe Support Changes
    unsubscribeSupportChanges$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.unsubscribeSupportChangesRequest),
            map(() => {
                this.unsubs.forEach(unsub => unsub());
                this.subs.unsubscribe();
                return actions.unsubscribeSupportChangesSuccess();
            })
        )
    );

    // Get Supports Changes
    getSupportsChanges$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getSupportsChangesRequest),
            map(action => {
                const sub$ = new Subject<Support[]>();
                this.multipleSubs.add(
                    sub$.subscribe(supports => this.store.dispatch(actions.getSupportsChangesSuccess({ supports })))
                );
                const unsub = this.supportService.getSupportsChanges(sub$, action.excludeUserId, action.resolved);
                this.multipleUnsubs.push(unsub);
                return actions.getSupportsChangesLoaded();
            })
        )
    );

    // Unsubscribe Supports Changes
    unsubscribeSupportsChanges$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.unsubscribeSupportsChangesRequest),
            map(() => {
                this.multipleUnsubs.forEach(unsub => unsub());
                this.multipleSubs.unsubscribe();
                return actions.unsubscribeSupportsChangesSuccess();
            })
        )
    );
}
