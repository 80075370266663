import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Auth, signOut } from '@angular/fire/auth';
import { NavigationEnd, Router } from '@angular/router';
import { DRIVER_ACTIVE, LocalStorage } from '@classes/local-storage';
import { UtilityHelper } from '@classes/utility-helper';
import { ModalController } from '@ionic/angular';
import { Driver } from '@models/driver.model';
import { User } from '@models/user.model';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, distinctUntilChanged, filter, map, merge, Observable, shareReplay, Subject, switchMap, tap } from 'rxjs';
import { DriverStoreActions, DriverStoreSelectors, SessionStoreSelectors, SupportStoreSelectors, UserStoreActions,
    UserStoreSelectors, VehicleStoreSelectors } from 'src/app/root-store';
import { MissionComponent } from 'src/app/shared/mission/mission.component';
import { PrivacyPolicyComponent } from 'src/app/shared/privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from 'src/app/shared/terms-of-use/terms-of-use.component';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuComponent implements OnInit {
    private driver!: Driver;
    private logout$ = new Subject();
    private user!: User;

    active$!: Observable<boolean>;
    activeDisabled = false;
    driver$!: Observable<Driver>;
    user$!: Observable<User>;
    displayDefaultPhoto = false;
    version!: string;
    trailerExperience$!: Observable<boolean>;
    driverExperience$!: Observable<boolean>;
    isHome$!: Observable<boolean>;
    isAdmin$!: Observable<boolean>;
    supportCount$!: Observable<number>;
    isIOS = UtilityHelper.isIOS();

    constructor(
        private auth: Auth,
        private store: Store,
        private router: Router,
        private actions: Actions,
        private modalController: ModalController
    ) {}

    ngOnInit(): void {
        this.version = UtilityHelper.getVersion();
        const userId$ = this.store.select(SessionStoreSelectors.getUserId);
        this.user$ = userId$.pipe(
            switchMap(userId => this.store.select(UserStoreSelectors.getUser(userId))),
            filter(user => !!user),
            tap(user => this.user = user)
        );
        this.driver$ = userId$.pipe(
            switchMap(userId => this.store.select(DriverStoreSelectors.getDriver(userId))),
            filter(driver => !!driver)
        );
        const vehicles$ = userId$.pipe(
            switchMap(userId => this.store.select(VehicleStoreSelectors.getVehicles(userId)))
        );
        this.active$ = combineLatest([this.driver$, vehicles$]).pipe(
            map(([driver, vehicles]) => {
                this.driver = driver;

                // If no vehicle is active, disable active toggle
                const hasActiveVehicle = vehicles.some(x => x.active);
                if (!hasActiveVehicle) {
                    this.activeDisabled = true;
                    return false;
                }

                this.activeDisabled = false;
                return driver.active;
            })
        );
        const experience$ = this.store.select(SessionStoreSelectors.getExperience).pipe(
            distinctUntilChanged(),
            shareReplay()
        );
        this.trailerExperience$ = experience$.pipe(
            map(experience => experience === 'trailer'),
            distinctUntilChanged(),
            shareReplay()
        );
        this.driverExperience$ = experience$.pipe(
            map(experience => experience === 'driver'),
            distinctUntilChanged(),
            shareReplay()
        );
        this.isHome$ = this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            map(event => (event as NavigationEnd).url === '/home')
        );
        this.isAdmin$ = this.store.select(SessionStoreSelectors.isAdmin).pipe(
            shareReplay()
        );
        this.supportCount$ = userId$.pipe(
            switchMap(userId => this.store.select(SupportStoreSelectors.getUnresolvedSupports(userId))),
            map(supports => supports.length)
        );

        // Handle update driver properties (after we set the active flag off)
        const saveDriverResult$ = merge([
            this.actions.pipe(ofType(DriverStoreActions.updateDriverPropertiesSuccess)),
            this.actions.pipe(ofType(DriverStoreActions.updateDriverPropertiesFailure))
        ]);
        const setUserCurrentLocationResult$ = merge([
            this.actions.pipe(ofType(UserStoreActions.setUserCurrentLocationSuccess)),
            this.actions.pipe(ofType(UserStoreActions.setUserCurrentLocationFailure))
        ]);
        combineLatest([
            this.logout$,
            this.store.select(DriverStoreSelectors.getLoading),
            saveDriverResult$,
            setUserCurrentLocationResult$
        ]).pipe(
            filter(([logout, loading, saveDriverResult, setUserCurrentLocationResult]) =>
                !!logout && !loading && !!saveDriverResult && !!setUserCurrentLocationResult)
        ).subscribe(async () => await this.logout());
    }

    onPhotoError(): void {
        this.displayDefaultPhoto = true;
    }

    async onOpenMission(): Promise<void> {
        const modal = await this.modalController.create({
            component: MissionComponent,
            componentProps: {
                fromMenu: true
            }
        });
        return await modal.present();
    }

    async onOpenPrivacyPolicy(): Promise<void> {
        const modal = await this.modalController.create({
            component: PrivacyPolicyComponent
        });
        return await modal.present();
    }

    async onOpenTermsOfUse(): Promise<void> {
        const modal = await this.modalController.create({
            component: TermsOfUseComponent
        });
        return await modal.present();
    }

    onActiveToggle(event: any): void {
        const active = event.detail.checked;
        const properties: Partial<Driver> = { active };
        this.store.dispatch(DriverStoreActions.updateDriverPropertiesRequest({ id: this.driver.id, properties }));
        LocalStorage.set<boolean>(DRIVER_ACTIVE, active);
    }

    onLogout(): void {
        if (this.driver) {
            // Set driver as inactive
            const active = false;
            const properties: Partial<Driver> = { active };
            this.store.dispatch(DriverStoreActions.updateDriverPropertiesRequest({ id: this.driver.id, properties }));
            LocalStorage.set<boolean>(DRIVER_ACTIVE, active);

            // Reset user's current location
            this.store.dispatch(UserStoreActions.setUserCurrentLocationRequest({ user: this.user }));
        }

        this.logout$.next(true);
    }

    doNothing(): void {} // Needed cause for some reason the onLogout click gets triggered when clicking on the Active item

    private async logout() {
        await signOut(this.auth);
        await this.router.navigate(['/']);
        this.logout$.next(false);
    }
}
