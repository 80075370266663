import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GeoHelper } from '@classes/geo-helper';
import { environment } from '@environments/environment';
import { Address } from '@models/address.model';
import { GeoData } from '@models/geo-data.model';
import { State } from '@models/state.model';
import { catchError, map, Observable, shareReplay, tap } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class GeoService {
    constructor(
        private http: HttpClient
    ) {}

    getGeoData(address: Address): Observable<GeoData> {
        const fullAddress = encodeURI(`${address.address} ${address.city} ${address.state} ${address.zipCode}`);
        const url = environment.googleApi.geocodeUrl
            .replace('{key}', environment.googleApi.openKey)
            .replace('{address}', fullAddress)
            .replace('{latlng}', '');
        return this.http.get<any>(url).pipe(
            map(data => this.getGeoDataFromResponse(data))
        );
    }

    getAddress(latitude: number, longitude: number): Observable<Address> {
        const latlng = `${latitude},${longitude}`;
        const url = environment.googleApi.geocodeUrl
            .replace('{key}', environment.googleApi.openKey)
            .replace('{latlng}', latlng)
            .replace('{address}', '');
        return this.http.get<any>(url).pipe(
            map(response => {
                const streetNumber = this.getReverseGeocodingData(response, 'street_number');
                const street = this.getReverseGeocodingData(response, 'route');
                const city = this.getReverseGeocodingData(response, 'locality');
                const state = this.getReverseGeocodingData(response, 'administrative_area_level_1');
                const zipCode = this.getReverseGeocodingData(response, 'postal_code');
                const geoData = this.getGeoDataFromResponse(response);
                const formatted = response.results[0].formatted_address;
                const address: Address = {
                    address: `${streetNumber} ${street}`,
                    city,
                    state,
                    zipCode,
                    geoData,
                    formatted
                };
                return address;
            })
        );
    }

    getState(latitude: number, longitude: number): Observable<State> {
        const latlng = `${latitude},${longitude}`;
        const url = environment.googleApi.geocodeUrl
            .replace('{key}', environment.googleApi.openKey)
            .replace('{latlng}', latlng)
            .replace('{address}', '');
        return this.http.get<any>(url).pipe(
            map(response => {
                const type = 'administrative_area_level_1';
                let obj = response.results[0].address_components.find((x: { types: string; }) => x.types.includes(type));
                if (!obj) {
                    const addressComponents = response.results.find((x: any) => x.types.includes(type)).address_components;
                    obj = addressComponents.find((x: any) => x.types.includes(type));
                }

                const state: State = {
                    abbr: obj?.short_name,
                    name: obj?.long_name
                };
                return state;
            })
        );
    }

    tryGetGeoData(address: Address, getCurrentPositionFn?: Function, getAddressGeoDataFn?: Function): Observable<GeoData> {
        const geoData$ = this.getCurrentPosition(address, getCurrentPositionFn).pipe(
            catchError(error => {
                console.error(error);
                return this.getAddressGeoData(address, getAddressGeoDataFn);
            }),
            tap(x => console.log('geoData', x)),
            shareReplay()
        );
        return geoData$;
    }

    private getCurrentPosition(address: Address, fn?: Function): Observable<GeoData> {
        return GeoHelper.getCurrentPositionGeoData().pipe(
            tap(x => console.log('getCurrentPositionGeoData', x)),
            tap(geoData => fn?.(geoData)),
            catchError(error => {
                console.error(error);
                return this.getAddressGeoData(address, fn);
            }),
        );
    }

    private getAddressGeoData(address: Address, fn?: Function): Observable<GeoData> {
        return this.getGeoData(address).pipe(
            tap(geoData => fn?.(geoData)),
            tap(x => console.log('addressGeoData', x)),
            shareReplay()
        );
    }

    private getGeoDataFromResponse(response: any): GeoData {
        const result = response.results[0];
        if (!result) {
            throw new Error(`Geocode: ${response.error_message}`);
        }

        const coords = result.geometry.location;
        const latitude = coords.lat;
        const longitude = coords.lng;
        const geohash = GeoHelper.getGeohash(latitude, longitude);
        const geoData: GeoData = {
            latitude,
            longitude,
            geohash
        };
        return geoData;
    }

    private getReverseGeocodingData(response: any, type: string): string {
        const obj = response.results[0].address_components.find((x: { types: string; }) => x.types.includes(type));
        return obj?.short_name;
    }
}
