import { adapter, initialState, State } from './state';
import { createReducer, on, Action } from '@ngrx/store';
import { getEarningsFailure, getEarningsRequest, getEarningsSuccess } from './actions';

export const earningReducer = createReducer(
    initialState,
    on(
        getEarningsRequest,
        state => ({
            ...state,
            loading: true,
            error: null
        })
    ),
    on(
        getEarningsFailure,
        (state, { error }) => ({
            ...state,
            loading: false,
            error
        })
    ),

    // Get Earnings
    on(
        getEarningsSuccess,
        (state, { earnings }) => adapter.setAll(earnings, {
            ...state,
            loading: false,
            error: null,
            total: earnings.length
        })
    )
);

export function reducer(state: State, action: Action) {
    return earningReducer(state, action);
}
