<ion-header>
    <ion-toolbar color="secondary">
        <ion-title>Release of Liability</ion-title>

        <ion-buttons slot="end">
            <ion-button (click)="onClose()">
                <i class="material-symbols-outlined">close</i>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content fullscreen>
    <main #main>
        <section>
            <h2>Activity Participation Acknowledgment</h2>
            <p>In consideration of participating in the towing activity ("Activity"), I, the undersigned ("Participant"), hereby voluntarily assume all associated risks. I acknowledge that I am participating entirely at my own risk, understanding the potential physical and psychological outcomes.</p>

            <h2>Restricted Items Policy</h2>
            <p>I understand and agree that for safety, legal compliance, and the protection of both myself and others, I am prohibited from transporting passengers, pets, livestock, fireworks, drugs, guns, weapons, fine art/antiques, or any illegal items. I acknowledge that this policy is implemented to uphold the integrity of the towing activity and reduce potential risks associated with the transportation of restricted items. I am expected to adhere to this policy at all times during the Activity.</p>

            <h2>Waiver and Release</h2>
            <p>I waive any claims, rights, or causes of action arising from my participation in the Activity and release Drive & Tow, including affiliates, from any property damage or injuries I may sustain during the Activity.</p>

            <h2>Assumption of Risks</h2>
            <p>I am aware that risks may result from negligence or conditions related to travel and the Activity location(s). I voluntarily assume all known and unknown risks.</p>

            <h2>Indemnification Agreement</h2>
            <p>I agree to indemnify and hold harmless Drive & Tow against any claims or actions, including legal fees, arising from my participation.</p>

            <h2>Medical Care Acknowledgment</h2>
            <p>I acknowledge that I am responsible for any medical costs incurred due to treatment and affirm that I carry my health insurance.</p>

            <h2>Express Release and Discharge</h2>
            <p>I have read and understand this release. I expressly release Drive & Tow and its affiliates from any claims, agreeing not to bring legal action for personal injury or property damage.</p>

            <h2>Insurance Requirement</h2>
            <p>
                <ng-container *ngIf="experience === 'trailer'; else insuranceDriver">
                    I understand and acknowledge that the driver's personal insurance does not cover any damage incurred to my trailer or cargo. Therefore, it is strongly advised that I carry my own trailer and cargo insurance to safeguard my assets during transportation activities.
                </ng-container>
                <ng-template #insuranceDriver>
                    I understand and accept the obligation to maintain auto insurance covering collision and liability throughout my involvement in the Activity. Failure to uphold adequate insurance will render me personally liable for any damages sustained during the towing activity. I understand that my personal driver insurance does not provide coverage for any damage incurred to the trailer or cargo unless I possess commercial and cargo insurance during the Activity.
                </ng-template>
            </p>

            <h2>Duration of Agreement</h2>
            <p>This agreement remains in effect throughout my participation in the Activity.</p>

            <h2>Legal Understanding</h2>
            <p>This agreement is entered into willingly, without coercion, understood as it is written, and deemed clear and unambiguous. Any invalid provision does not affect the overall agreement.</p>

            <h2>Cancellation Policy</h2>
            <p>
                By participating in towing activities with Drive & Tow, you acknowledge and agree to our cancellation policy.
                A minimum of {{ days }} days' notice is necessary for cancellation after approval. Failure to comply will result in a {{ cancellationFee | currency }} cancellation fee.
            </p>

            <h2>Severability</h2>
            <p>If any part of this agreement is deemed invalid, the remainder shall remain enforceable.</p>

            <form [formGroup]="form" (ngSubmit)="onAccept()">
                <mat-checkbox formControlName="accept" color="primary">
                    I understand and accept the terms in this document. I confirm that I've read the Release of Liability, agreeing to its conditions. This statement shows my informed consent, protecting parties involved from legal claims related to the outlined activities.
                </mat-checkbox>

                <ion-button type="submit" expand="block" [disabled]="form.invalid">Accept</ion-button>
                <div class="link" (click)="onClose()">Decline</div>
            </form>
        </section>
    </main>
</ion-content>
