import { Injectable } from '@angular/core';
import { GeoHelper } from '@classes/geo-helper';
import { ObjectHelper } from '@classes/object-helper';
import { Filter } from '@models/filter.model';
import { GeoData } from '@models/geo-data.model';
import { Sort } from '@models/sort.model';
import { User } from '@models/user.model';
import { catchError, combineLatest, filter, map, Observable, of, shareReplay, Subject, switchMap, take } from 'rxjs';
import { DbService } from './db.service';
import { FileService } from './file.service';
import { GeoService } from './geo.service';
import { Unsubscribe } from 'firebase/firestore';
import { CurrentLocation } from '@models/current-location.model';
import { GEO_LOCATION_GRANTED, LocalStorage } from '@classes/local-storage';
import { UserRole } from '@enums/user-role.enum';

const COLLECTION = 'users';
const IMAGES_FOLDER = 'images/user';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    constructor(
        private dbService: DbService,
        private fileService: FileService,
        private geoService: GeoService
    ) {}

    getUsers(center: GeoData, radius: number, excludeId?: string): Observable<User[]> {
        const latitude = center.latitude;
        const longitude = center.longitude;

        // Each item in 'bounds' represents a startAt/endAt pair. We'll issue a separate query for each pair
        const bounds = GeoHelper.getNearGeohashes(latitude, longitude, radius);
        if (bounds.length > 0) {
            const users$: Observable<User[]>[] = [];
            bounds.forEach(bound => {
                const sort: Sort = { field: 'currentLocation.geohash', direction: 'asc' };
                const list$ = this.dbService.getList<User>(COLLECTION, undefined, sort, bound[0], bound[1]);
                users$.push(list$);
            });
            return combineLatest(users$).pipe(
                map(users => {
                    const matchedUsers: User[] = [];
                    const list = users.flat();
                    list.forEach(user => {
                        // Skip excludeId
                        if (excludeId && user.id === excludeId) {
                            return;
                        }

                        // Skip users without a current location
                        if (!user.currentLocation) {
                            return;
                        }

                        // Let's filter out the false positives due to geohash accuracy
                        const isLocationInRadius = GeoHelper.isLocationInRadius(center, user.currentLocation, radius);
                        if (!isLocationInRadius) {
                            return;
                        }

                        this.fixDates(user);

                        matchedUsers.push(user);
                    });

                    return matchedUsers;
                })
            );
        }

        const filters: Filter[] = [];
        if (excludeId) {
            filters.push({ field: 'id', operator: '!=', value: excludeId });
        }
        return this.dbService.getList(COLLECTION, filters);
    }

    getUser(id: string): Observable<User> {
        return this.dbService.getObj<User>(COLLECTION, id).pipe(
            map(user => {
                this.fixDates(user);
                return user;
            })
        );
    }

    getUserChanges(id: string, sub$: Subject<User>): Unsubscribe {
        return this.dbService.getObjChanges(COLLECTION, id, sub$, this.fixDates);
    }

    saveUser(user: User): Observable<User> {
        // If they have supplied a photo, upload it
        if (user.photoFile) {
            const fileName = 'profile';
            const save$ = this.fileService.uploadFile(IMAGES_FOLDER, user.photoFile as File, fileName).pipe(
                switchMap(photo => this.save({ ...user, photo, photoFile: undefined }))
            );

            // Delete the previous photo (if any)
            if (user.photo) {
                const name = this.fileService.getFileName(user.photo, IMAGES_FOLDER);
                const deleteFile$ = this.fileService.deleteFile(IMAGES_FOLDER, name).pipe(
                    catchError(() => of([])) // Don't worry if the deletion fails
                );
                return combineLatest([save$, deleteFile$]).pipe(
                    map(([save]) => save)
                );
            }

            return save$;
        }

        return this.save(user);
    }

    setUserCurrentLocation(user: User): Observable<User> {
        const addressGeoData$ = !!user.address ? this.geoService.getGeoData(user.address) : of();
        const geoData$ = GeoHelper.getCurrentPositionGeoData().pipe(
            catchError(error => {
                console.error(error);
                return addressGeoData$;
            })
        );
        return geoData$.pipe(
            switchMap(geoData => {
                if (!geoData) {
                    return of(user);
                }

                // Check if the data is still the same and if so, stop
                if (geoData.latitude === user.currentLocation?.latitude && geoData.longitude === user.currentLocation.longitude) {
                    console.log('setUserCurrentLocation:', 'same');
                    return of(user);
                }

                console.log('setUserCurrentLocation:', 'saving');
                const currentLocation: CurrentLocation = { ...geoData, lastUpdateDate: new Date() };
                user = { ...user, currentLocation };
                return this.save(user);
            }),
            catchError(error => {
                console.error(error);
                throw error.message ?? error;
            })
        );
    }

    updateUserProperties(id: string, properties: Partial<User>): Observable<Partial<User>> {
        return this.dbService.updateObjProperties(COLLECTION, id, properties);
    }

    updateUserCurrentLocation(user$: Observable<User>): void {
        const granted$ = LocalStorage.get<boolean>(GEO_LOCATION_GRANTED, 'boolean');
        granted$.pipe(
            filter(granted => !!granted), // If they haven't granted to get their location yet, stop
            switchMap(() => user$), // Let's make sure the user is already logged-in (we'll have the user record in the store)
            switchMap(user => combineLatest([
                of(user),
                this.getUserCurrentLocation()
            ])),
            take(1),
            filter(([user, currentLocation]) => user.currentLocation?.latitude !== currentLocation.latitude &&
                user.currentLocation?.longitude !== currentLocation.longitude)
        ).subscribe(([user, currentLocation]) => {
            const properties: Partial<User> = { currentLocation };
            this.updateUserProperties(user.id, properties);
        });
    }

    getUserCurrentLocation(): Observable<CurrentLocation> {
        const currentPosition$ = GeoHelper.getCurrentPositionGeoData().pipe(
            shareReplay()
        );
        const address$ = currentPosition$.pipe(
            switchMap(({ latitude, longitude }) => this.geoService.getAddress(latitude, longitude))
        );
        return combineLatest([
            currentPosition$,
            address$
        ]).pipe(
            map(([currentPosition, address]) => {
                const { latitude, longitude, geohash } = currentPosition;
                const currentLocation: CurrentLocation = {
                    latitude,
                    longitude,
                    geohash,
                    address: address.formatted,
                    lastUpdateDate: new Date()
                };
                return currentLocation;
            })
        );
    }

    getUsersByRole(role: UserRole): Observable<User[]> {
        const filters: Filter[] = [{ field: 'role', operator: '==', value: role }];
        return this.dbService.getList<User>(COLLECTION, filters).pipe(
            map(users => {
                users.forEach(x => this.fixDates(x));
                return users;
            })
        );
    }

    private save(user: User): Observable<User> {
        // Remove the property driver from the user model
        user = { ...user, driver: undefined };

        return this.dbService.saveObj(COLLECTION, user, 'set');
    }

    private fixDates(user: User): void {
        ['lastActiveDate', 'dateOfBirth'].forEach(property => {
            ObjectHelper.fixDate(user, property);
        });
    }
}
