import { Injectable } from '@angular/core';
import { from, Observable, of, switchMap } from 'rxjs';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Auth } from '@angular/fire/auth';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private auth: Auth
    ) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        // Only do this for the API calls
        if (!request.url.includes(environment.apiBaseUrl)) {
            return next.handle(request);
        }

        const promise = this.auth.currentUser?.getIdToken();
        const token$ = !!promise ? from(promise) : of();
        return token$.pipe(
            switchMap(token => {
                request = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${token}`
                    }
                });
                return next.handle(request);
            })
        );
    }
}
