<div *ngIf="distancesDurations.commute">
    <label>{{ experience === 'trailer' ? 'Driver Location' : 'Home' }} to Pick-Up</label>
    {{ distancesDurations.commute.distance | number }} mi | {{ distancesDurations.commute.duration | duration }}
</div>
<div>
    <label>Pick-Up to Drop-Off</label>
    {{ distancesDurations.tow.distance | number }} mi | {{ distancesDurations.tow.duration | duration }}
</div>
<div *ngIf="distancesDurations.commute">
    <label>Total Trip</label>
    {{ distancesDurations.commute.distance + distancesDurations.tow.distance | number }} mi |
    {{ distancesDurations.commute.duration + distancesDurations.tow.duration | duration }}
</div>
