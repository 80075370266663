import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { adapter, State } from './state';
import { Payment } from '@models/payment.model';

export const getState: MemoizedSelector<object, State> = createFeatureSelector<State>('payment');

export const getLoading: MemoizedSelector<object, boolean> = createSelector(getState, (state: State) => state.loading);

export const getError: MemoizedSelector<object, string> = createSelector(getState, (state: State) => state.error);

export const getTotal: MemoizedSelector<object, number> = createSelector(getState, (state: State) => state.total);

export const getPayments: MemoizedSelector<object, Payment[]> = createSelector(getState, adapter.getSelectors().selectAll);
