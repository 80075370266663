import { environment } from '@environments/environment';
import { Stripe, StripeCardNumberElementChangeEvent, StripeElement, StripeElementChangeEvent, StripeElementStyle,
    StripeElementStyleVariant, StripeElementType, loadStripe } from '@stripe/stripe-js';
import { BehaviorSubject, Observable, Subject, filter, from, map, tap } from 'rxjs';
import { ListHelper } from './list-helper';

const DEFAULT_CARD_TYPE = 'unknown';
const DEFAULT_FIELD_VALUE = {
    isFocused: false,
    isEmpty: true,
    isValid: true
};

export class CreditCardHelper {
    private static stripe: Stripe;
    private static fields = new Map(Object.entries({
        number: DEFAULT_FIELD_VALUE,
        expirationDate: DEFAULT_FIELD_VALUE,
        cvc: DEFAULT_FIELD_VALUE
    }));
    private static readyFields = new Map(Object.entries({
        number: false,
        expirationDate: false,
        cvc: false
    }));
    private static element: StripeElement;

    static isFormInvalid$ = new BehaviorSubject(true);
    static cardType$ = new BehaviorSubject(DEFAULT_CARD_TYPE);
    static fieldChanges$ = new Subject<{ field: string, isFocused: boolean, isEmpty: boolean, isValid: boolean }>();
    static loading$ = new BehaviorSubject(false);

    static setCreditCardForm(): Observable<void> {
        this.loading$.next(true);
        const stripe$ = from(loadStripe(environment.stripe.publishableKey));
        return stripe$.pipe(
            filter(stripe => !!stripe),
            map(stripe => stripe as Stripe),
            tap(stripe => {
                this.stripe = stripe;
                const elements = stripe.elements();
                const styles: StripeElementStyleVariant = {
                    color: '#f2f2f2',
                    fontFamily: 'Roboto, "Helvetica Neue", sans-serif',
                    fontSize: '14px',
                    lineHeight: '56px'
                };
                const style: StripeElementStyle = {
                    base: styles,
                    invalid: styles
                };
                const placeholder = '';
                const cardNumber = elements.create('cardNumber', { style, placeholder });
                const cardExpiry = elements.create('cardExpiry', { style, placeholder });
                const cardCvc = elements.create('cardCvc', { style, placeholder });
                this.element = cardNumber; // Needed to submit later the form - any element would do

                cardNumber.mount('#number');
                cardExpiry.mount('#expirationDate');
                cardCvc.mount('#cvc');

                this.setElementListeners([cardNumber, cardExpiry, cardCvc]);
            }),
            map(() => undefined)
        );
    }

    static submitCreditCardForm(): Observable<string> {
        const source$ = from(this.stripe.createSource(this.element, {}));
        return source$.pipe(
            map(result => {
                if (result.error) {
                    throw new Error(result.error.message);
                }

                return result.source.id;
            })
        );
    }

    static reset(): void {
        this.cardType$.next(DEFAULT_CARD_TYPE);
    }

    private static setElementListeners(elements: any[]): void {
        elements.forEach(element => {
            element.on('ready', (event: any) => {
                this.handleReady(event);
            });
            element.on('focus', (event: { elementType: StripeElementType }) => {
                this.handleFocus(event, true);
            });
            element.on('blur', (event: { elementType: StripeElementType }) => {
                this.handleFocus(event, false);
            });
            element.on('change', (event: StripeElementChangeEvent) => {
                this.handleFieldChanges(event);
            });
        });
    }

    private static handleReady(event: { elementType: StripeElementType }): void {
        const field = this.getField(event.elementType);
        this.readyFields.set(field, true);
        this.setIsFormReady();
    }

    private static setIsFormReady(): void {
        const values = ListHelper.convertMapToList(this.readyFields).map(x => x);
        const isAnyFieldNotReady = values.filter(x => !x).length > 0;
        this.loading$.next(isAnyFieldNotReady);
    }

    private static handleFocus(event: { elementType: StripeElementType }, isFocused: boolean): void {
        const field = this.getField(event.elementType);
        const value = this.fields.get(field) ?? DEFAULT_FIELD_VALUE;
        this.fields.set(field, { ...value, isFocused });
        this.setFieldChanges(field);
    }

    private static handleFieldChanges(event: StripeElementChangeEvent): void {
        const field = this.getField(event.elementType);
        const isEmpty = event.empty;
        const isValid = event.complete && !event.error && !isEmpty;
        const value = this.fields.get(field) ?? DEFAULT_FIELD_VALUE;
        this.fields.set(field, { ...value, isEmpty, isValid });
        this.setIsFormInvalid();
        this.setFieldChanges(field);
        this.setCardType(event as StripeCardNumberElementChangeEvent);
    }

    private static setFieldChanges(field: string): void {
        const value = this.fields.get(field) ?? DEFAULT_FIELD_VALUE;
        const fieldChanges = { field, ...value };
        this.fieldChanges$.next(fieldChanges);
    }

    private static getField(elementType: StripeElementType): string {
        switch (elementType) {
            case 'cardNumber':
            default:
                return 'number';
            case 'cardExpiry':
                return 'expirationDate';
            case 'cardCvc':
                return 'cvc';
        }
    }

    private static setIsFormInvalid(): void {
        const isValid = ListHelper.convertMapToList(this.fields).map(x => x.isValid);
        const isAnyFieldInvalid = isValid.filter(x => !x).length > 0;
        const isEmpty = ListHelper.convertMapToList(this.fields).map(x => x.isEmpty);
        const isAnyFieldEmpty = isEmpty.filter(x => x).length > 0;
        const isFormInvalid = isAnyFieldInvalid || isAnyFieldEmpty;
        this.isFormInvalid$.next(isFormInvalid);
    }

    private static setCardType(event: StripeCardNumberElementChangeEvent): void {
        if (event.elementType !== 'cardNumber') {
            return;
        }

        const brand = event.brand ?? DEFAULT_CARD_TYPE;
        this.cardType$.next(brand);
    }
}
