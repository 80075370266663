import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { interval, merge, Observable, of, Subject } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-timer',
    templateUrl: './timer.component.html',
    styleUrls: ['./timer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimerComponent implements OnInit {
    @Input() countdownDuration!: number;
    @Output() timerEnded = new EventEmitter<void>();

    timer$!: Observable<string>;

    ngOnInit(): void {
        const date = new Date();
        date.setMinutes(date.getMinutes() + this.countdownDuration);
        date.setSeconds(date.getSeconds() + 2); // We need to add 2 secs for timer makes it start at :58 instead of 00
        const end = date.getTime();
        const ended$ = new Subject<boolean>();

        this.timer$ = merge(
            of(`${this.countdownDuration}:00`),
            interval(1000).pipe(
                takeUntil(ended$),
                map(() => {
                    const now = new Date().getTime();
                    const diff = end - now;
                    const mins = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
                    const secs = Math.floor((diff % (1000 * 60)) / 1000);

                    if (mins <= 0 && secs < 0) {
                        ended$.next(true);
                    }

                    return `${mins}:${this.prefixZero(secs)}`;
                })
            )
        );

        ended$.pipe(
            filter(x => !!x),
            take(1)
        ).subscribe(() => this.timerEnded.emit());
    }

    private prefixZero(num: number): string {
        return num < 10 ? `0${num}` : num.toString();
    }
}
