import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaymentMethod } from '@enums/payment-method.enum';
import { environment } from '@environments/environment';
import { Payment } from '@models/payment.model';
import { catchError, map, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PaymentService {
    constructor(
        private http: HttpClient
    ) {}

    getPayments(userId: string): Observable<Payment[]> {
        const url = `${environment.apiBaseUrl}/payments/${userId}/credit-cards`;
        return this.http.get<Payment[]>(url).pipe(
            map(payments => {
                const updatedPayments: Payment[] = [];
                payments.forEach(payment => {
                    const temp: Payment = { ...payment, method: PaymentMethod.CreditCard };
                    updatedPayments.push(temp);
                });
                return updatedPayments;
            }),
            catchError(response => {
                throw response.error;
            })
        );
    }

    savePayment(payment: Payment): Observable<Payment> {
        const url = `${environment.apiBaseUrl}/payments/${payment.userId}/credit-cards`;
        return this.http.post<Payment>(url, payment.token).pipe(
            map(x => {
                return { ...x, method: PaymentMethod.CreditCard };
            }),
            catchError(response => {
                throw response.error;
            })
        );
    }

    setDefaultPayment(payment: Payment): Observable<Payment> {
        const url = `${environment.apiBaseUrl}/payments/${payment.userId}/credit-cards/${payment.id}/default`;
        return this.http.patch<Payment>(url, null).pipe(
            map(() => {
                payment = { ...payment, default: true };
                return payment;
            }),
            catchError(response => {
                throw response.error;
            })
        );
    }

    deletePayment(payment: Payment): Observable<Payment> {
        const url = `${environment.apiBaseUrl}/payments/${payment.userId}/credit-cards/${payment.id}`;
        return this.http.delete<void>(url).pipe(
            map(() => payment),
            catchError(response => {
                throw response.error;
            })
        );
    }

    // getBankAccountSessionSecret(userId: string): Observable<string> {
    //     const url = `${environment.apiBaseUrl}/payments/${userId}/bank-accounts/session-secret`;
    //     return this.http.get<string>(url);
    // }
}
