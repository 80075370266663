import { Notification } from '@models/notification.model';
import { createAction, props } from '@ngrx/store';

// Request Permission
export const requestPermissionRequest = createAction(
    '[Notification] Request Permission Request'
);
export const requestPermissionFailure = createAction(
    '[Notification] Request Permission Failure',
    props<{ error: string }>()
);
export const requestPermissionSuccess = createAction(
    '[Notification] Request Permission Success',
    props<{ token: string }>()
);

// Push Notification
export const pushNotificationRequest = createAction(
    '[Notification] Push Notification Request',
    props<{ notification: Notification }>()
);
export const pushNotificationFailure = createAction(
    '[Notification] Push Notification Failure',
    props<{ error: string }>()
);
export const pushNotificationSuccess = createAction(
    '[Notification] Push Notification Success'
);
