import { Pipe, PipeTransform } from '@angular/core';
import { UtilityHelper } from '@classes/utility-helper';

@Pipe({
    name: 'duration'
})
export class DurationPipe implements PipeTransform {
    transform(value: number): string {
        return UtilityHelper.formatDuration(value);
    }
}
