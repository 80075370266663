import { Injectable } from '@angular/core';
import { getDownloadURL, ref, Storage, uploadBytesResumable, deleteObject } from '@angular/fire/storage';
import { UtilityHelper } from '@classes/utility-helper';
import { environment } from '@environments/environment';
import { Store } from '@ngrx/store';
import { filter, from, Observable, switchMap, take, throwError } from 'rxjs';
import { SessionStoreSelectors, UserStoreSelectors } from 'src/app/root-store';

@Injectable({
    providedIn: 'root'
})
export class FileService {
    constructor(
        private storage: Storage,
        private store: Store
    ) {}

    uploadFile(folder: string, file: File, name: string): Observable<string> {
        // Validate file size
        if (file.size > environment.maxFileSize) {
            return throwError(() =>
                `File (${this.getMB(file.size)}) exceeds the max size of ${this.getMB(environment.maxFileSize)}.`);
        }

        const user$ = this.store.select(SessionStoreSelectors.getUserId).pipe(
            filter(userId => !!userId),
            switchMap(userId => this.store.select(UserStoreSelectors.getUser(userId))),
            take(1)
        );
        return user$.pipe(
            switchMap(user => {
                name = `${user.lastName}-${user.firstName}-${name}`;
                return from(this.upload(folder, file, name));
            })
        );
    }

    deleteFile(folder: string, name: string): Observable<void> {
        const path = `${folder}/${name}`;
        const storageRef = ref(this.storage, path);
        return from(deleteObject(storageRef));
    }

    getFileName(url: string, folder: string): string {
        url = decodeURIComponent(url);
        const start = `/${folder}/`;
        const end = '?';
        let temp = url.substring(url.indexOf(start) + start.length);
        temp = temp.substring(temp.indexOf('/') + 1);
        const name = temp.substring(0, temp.indexOf(end));
        return name;
    }

    private async upload(folder: string, file: File, name: string): Promise<string> {
        const ext = file.name.substring(file.name.lastIndexOf('.'));
        const now = Date.now();
        name = UtilityHelper.removePunctuation(name);
        name = UtilityHelper.removeExtraSpaces(name);
        name = name.replace(/ /g, '-');
        const path = `${folder}/${name}-${now}${ext}`.toLowerCase();
        const storageRef = ref(this.storage, path);
        const task = uploadBytesResumable(storageRef, file);
        await task;
        return await getDownloadURL(storageRef);
    }

    private getMB(size: number): string {
        const mb = size / 1024 / 1024;
        return `${mb.toFixed(2)} MB`;
    }
}
