import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'app-tow-total',
    templateUrl: './tow-total.component.html',
    styleUrls: ['./tow-total.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TowTotalComponent {
    @Input() subtotal!: number;
    @Input() tip!: number;
}
