import { adapter, initialState, State, ID } from './state';
import { createReducer, on, Action } from '@ngrx/store';
import { getSession, saveSession, saveSessionProperty, saveSessionProperties, saveSessionChildProperty, clearSession,
    // getBankAccountSessionSecretRequest, getBankAccountSessionSecretSuccess, getBankAccountSessionSecretFailure
} from './actions';
import { Session } from '@models/session.model';
import { ObjectHelper } from '@classes/object-helper';

export const sessionReducer = createReducer(
    initialState,
    on(
        getSession,
        // getBankAccountSessionSecretRequest,
        state => ({
            ...state,
            loading: true,
            error: null
        })
    ),
    on(
        // getBankAccountSessionSecretFailure,
        (state, { error }) => ({
            ...state,
            loading: false,
            error
        })
    ),

    // Save Session
    on(
        saveSession,
        (state, { session }) => adapter.upsertOne(session, {
            ...state,
            loading: false,
            error: null
        })
    ),

    // Save Session Property
    on(
        saveSessionProperty,
        (state, { property, value }) => {
            const oldSession = ObjectHelper.cloneObject(state.entities[ID]) as Session;
            const session: Session = {
                ...oldSession,
                [property]: value
            };

            return adapter.upsertOne(session, {
                ...state,
                loading: false,
                error: null
            });
        }
    ),

    // Save Session Properties
    on(
        saveSessionProperties,
        (state, { properties }) => {
            let session = state.entities[ID] as Session;
            session = ObjectHelper.cloneObject(session);

            properties.forEach(x => {
                (session as any)[x.key] = x.value;
            });

            return adapter.upsertOne(session, {
                ...state,
                loading: false,
                error: null
            });
        }
    ),

    // Save Session Child Property
    on(
        saveSessionChildProperty,
        (state, { parentProperty, childProperty, value }) => {
            const session = ObjectHelper.cloneObject(state.entities[ID]) as Session;
            (session as any)[parentProperty] = ObjectHelper.cloneObject((session as any)[parentProperty]);
            (session as any)[parentProperty][childProperty] = value;

            return adapter.upsertOne(session, {
                ...state,
                loading: false,
                error: null
            });
        }
    ),

    // // Get Bank Account Session Secret
    // on(
    //     getBankAccountSessionSecretSuccess,
    //     (state, { bankAccountSessionSecret }) => {
    //         const session = { ...state.entities[ID]!, bankAccountSessionSecret };
    //         return adapter.upsertOne(session, {
    //             ...state,
    //             loading: false,
    //             error: null
    //         });
    //     }
    // ),

    // Clear Session
    on(
        clearSession,
        (state) => adapter.removeAll({
            ...state,
            loading: false,
            error: null
        })
    )
);

export function reducer(state: State, action: Action) {
    return sessionReducer(state, action);
}
