import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { State } from './state';

export const getState: MemoizedSelector<object, State> = createFeatureSelector<State>('payout');

export const getLoading: MemoizedSelector<object, boolean> = createSelector(getState, (state: State) => state.loading);

export const getError: MemoizedSelector<object, string> = createSelector(getState, (state: State) => state.error);

export const getTotal: MemoizedSelector<object, number> = createSelector(getState, (state: State) => state.total);

export const getPayout = (id: string) => createSelector(getState, (state: State) => state.entities[id]);

export const getAvailableBalance = (id: string) => createSelector(getState, (state: State) =>
    state.entities[id]?.balance.available ?? 0);

export const getPendingBalance = (id: string) => createSelector(getState, (state: State) =>
    state.entities[id]?.balance.pending ?? 0);
