import { Injectable } from '@angular/core';
import { Trailer } from '@models/trailer.model';
import { catchError, combineLatest, map, Observable, of, switchMap } from 'rxjs';
import { FileService } from './file.service';
import { DbService } from './db.service';
import { Filter } from '@models/filter.model';
import { Sort } from '@models/sort.model';
import { ObjectHelper } from '@classes/object-helper';

const COLLECTION = 'trailers';
const IMAGES_FOLDER = 'images/trailer';

@Injectable({
    providedIn: 'root'
})
export class TrailerService {
    constructor(
        private fileService: FileService,
        private dbService: DbService
    ) {}

    getTrailers(userId: string): Observable<Trailer[]> {
        const filters: Filter[] = [{ field: 'userId', operator: '==', value: userId }];
        const sort: Sort = { field: 'creationDate', direction: 'desc' };
        return this.dbService.getList<Trailer>(COLLECTION, filters, sort).pipe(
            map(trailers => {
                trailers.forEach(trailer => {
                    this.fixDates(trailer);
                });
                return trailers;
            })
        );
    }

    getTrailer(id: string): Observable<Trailer> {
        return this.dbService.getObj<Trailer>(COLLECTION, id).pipe(
            map(trailer => {
                this.fixDates(trailer);
                return trailer;
            })
        );
    }

    saveTrailer(trailer: Trailer): Observable<Trailer> {
        // If they have supplied a photo, upload it
        if (trailer.registrationPhotoFile || trailer.insurancePhotoFile) {
            const rootFileName = `${trailer.nickname ?? trailer.description}`;
            const registrationFileName = `${rootFileName}-registration`;
            const insuranceFileName = `${rootFileName}-insurance`;
            const registrationPhoto$ = trailer.registrationPhotoFile ?
                this.fileService.uploadFile(IMAGES_FOLDER, trailer.registrationPhotoFile, registrationFileName) :
                of(trailer.registrationPhoto);
            const insurancePhoto$ = trailer.insurancePhotoFile ?
                this.fileService.uploadFile(IMAGES_FOLDER, trailer.insurancePhotoFile, insuranceFileName) :
                of(trailer.insurancePhoto);
            const save$ = combineLatest([registrationPhoto$, insurancePhoto$]).pipe(
                switchMap(([registrationPhoto, insurancePhoto]) => this.save({ ...trailer,
                    registrationPhoto: registrationPhoto,
                    registrationPhotoFile: undefined,
                    insurancePhoto: insurancePhoto,
                    insurancePhotoFile: undefined
                }))
            );

            // Delete the previous photo(s) (if any)
            if ((trailer.registrationPhoto && trailer.registrationPhotoFile) ||
                (trailer.insurancePhoto && trailer.insurancePhotoFile)) {
                const registrationName = trailer.registrationPhotoFile ?
                    this.fileService.getFileName(trailer.registrationPhoto!, IMAGES_FOLDER) :
                    null;
                const deleteFrontFile$ = registrationName ? this.fileService.deleteFile(IMAGES_FOLDER, registrationName).pipe(
                    catchError(() => of([])) // Don't worry if the deletion fails
                ) : of('');
                const insuranceName = trailer.insurancePhotoFile ?
                    this.fileService.getFileName(trailer.insurancePhoto!, IMAGES_FOLDER) :
                    null;
                const deleteBackFile$ = insuranceName ? this.fileService.deleteFile(IMAGES_FOLDER, insuranceName).pipe(
                    catchError(() => of([])) // Don't worry if the deletion fails
                ) : of('');
                return combineLatest([save$, deleteFrontFile$, deleteBackFile$]).pipe(
                    map(([save]) => save)
                );
            }

            return save$;
        }

        return this.save(trailer);
    }

    deleteTrailer(trailer: Trailer): Observable<string> {
        const delete$ = this.dbService.deleteObj(COLLECTION, trailer.id);

        // Delete the registration photo (if any)
        if (trailer.registrationPhoto) {
            const name = this.fileService.getFileName(trailer.registrationPhoto, IMAGES_FOLDER);
            const deleteFile$ = this.fileService.deleteFile(IMAGES_FOLDER, name).pipe(
                catchError(() => of([])) // Don't worry if the deletion fails
            );
            return combineLatest([delete$, deleteFile$]).pipe(
                map(([id]) => id)
            );
        }

        return delete$;
    }

    private save(trailer: Trailer): Observable<Trailer> {
        return this.dbService.saveObj(COLLECTION, trailer);
    }

    private fixDates(trailer: Trailer): void {
        ObjectHelper.fixDate(trailer, 'registrationExpDate');
        ObjectHelper.fixDate(trailer, 'insuranceExpDate');
    }
}
