/* eslint-disable dot-notation */
import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { BehaviorSubject, combineLatest, from } from 'rxjs';
import { map, filter, tap, switchMap, delay, take, distinctUntilChanged } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { DisbursementStoreActions, DriverBusyTimeStoreActions, EarningStoreActions, EarningStoreSelectors, PayoutStoreActions,
    PayoutStoreSelectors, SessionStoreActions, SessionStoreSelectors, SupportStoreActions } from '..';
import { RouterNavigationAction, ROUTER_NAVIGATION } from '@ngrx/router-store';
import { DriverStoreActions } from '../driver-store';
import { TrailerStoreActions } from '../trailer-store';
import { VehicleStoreActions } from '../vehicle-store';
import { DataStoreActions } from '../data-store';
import { TowStoreActions } from '../tow-store';
import * as actions from './actions';
import { UserStoreActions } from '../user-store';
import { TowRequestStoreActions } from '../tow-request-store';
import { TOW_IN_PROGRESS_STATUSES } from '../tow-store/selectors';
import { RateCategory } from '@enums/rate-category.enum';
import { RateStoreActions } from '../rate-store';
import { PaymentStoreActions, PaymentStoreSelectors } from '../payment-store';
import { TOW_REQUEST_IN_PROGRESS_STATUSES } from '../tow-request-store/selectors';
import { CreditCardHelper } from '@classes/credit-card-helper';
import { DateHelper } from '@classes/date-helper';
import { TowStatus } from '@enums/tow-status.enum';
import { UserRole } from '@enums/user-role.enum';

@Injectable()
export class RouteStoreEffects {
    private alreadyFetchedTrailers$ = new BehaviorSubject(false);
    private alreadyFetchedDriver$ = new BehaviorSubject(false);
    private alreadyFetchedVehicles$ = new BehaviorSubject(false);
    private alreadyFetchedYears$ = new BehaviorSubject(false);
    private alreadyFetchedPayments$ = new BehaviorSubject(false);
    private alreadyFetchedPayout$ = new BehaviorSubject(false);
    private alreadyFetchedSupports$ = new BehaviorSubject(false);
    private alreadyFetchedEarnings$ = new BehaviorSubject(false);

    constructor(
        private actions$: Actions,
        private store: Store
    ) {}

    splashRoute$ = createEffect(() => {
        const url$ = this.actions$.pipe(
            ofType(ROUTER_NAVIGATION),
            map((x: RouterNavigationAction) => x.payload.routerState.url)
        );
        return url$.pipe(
            filter(url => url === '/'),
            map(() => actions.reset()),
            delay(1000), // Needed for some reason cause otherwise, these are not getting reset
            tap(() => {
                this.alreadyFetchedTrailers$.next(false);
                this.alreadyFetchedDriver$.next(false);
                this.alreadyFetchedVehicles$.next(false);
                this.alreadyFetchedYears$.next(false);
                this.alreadyFetchedPayments$.next(false);
                this.alreadyFetchedPayout$.next(false);
                this.alreadyFetchedSupports$.next(false);
                this.alreadyFetchedEarnings$.next(false);
            }),
        );
    });

    homeRoute$ = createEffect(() => {
        const url$ = this.actions$.pipe(
            ofType(ROUTER_NAVIGATION),
            map((x: RouterNavigationAction) => x.payload.routerState.url)
        );
        return combineLatest([
            url$,
            this.store.select(SessionStoreSelectors.getUserId),
            this.alreadyFetchedTrailers$,
            this.alreadyFetchedVehicles$,
            this.alreadyFetchedDriver$,
            this.alreadyFetchedSupports$,
            this.alreadyFetchedPayout$
        ]).pipe(
            filter(([url, userId]) =>
                url === '/home' && !!userId),
            tap(([_url, userId]) => this.getPayoutRetry(userId)),
            switchMap(([_url, userId, alreadyFetchedTrailers, alreadyFetchedVehicles, alreadyFetchedDriver, alreadyFetchedSupports,
                alreadyFetchedPayout]) => [
                alreadyFetchedTrailers ? actions.empty() : TrailerStoreActions.getTrailersRequest({ userId }),
                alreadyFetchedVehicles ? actions.empty() : VehicleStoreActions.getVehiclesRequest({ userId }),
                alreadyFetchedDriver ? actions.empty() : DriverStoreActions.getDriverRequest({ id: userId }),
                alreadyFetchedSupports ? actions.empty() : SupportStoreActions.getSupportsRequest({ userId, resolved: false }),
                alreadyFetchedPayout ? actions.empty() : PayoutStoreActions.getPayoutRequest({ userId }),
                // Get driver's tow requests in progress
                TowRequestStoreActions.getTowRequestsRequest({ userId, statuses: TOW_REQUEST_IN_PROGRESS_STATUSES }),
                // Get trailer's tows in progress
                TowStoreActions.getTowsRequest({ userId, statuses: TOW_IN_PROGRESS_STATUSES }),
                SessionStoreActions.saveSessionProperty({ property: 'experience', value: undefined })
            ]),
            tap(action => {
                switch (action.type as any) {
                    case TrailerStoreActions.getTrailersRequest.type:
                        this.alreadyFetchedTrailers$.next(true);
                        break;
                    case VehicleStoreActions.getVehiclesRequest.type:
                        this.alreadyFetchedVehicles$.next(true);
                        break;
                    case DriverStoreActions.getDriverRequest.type:
                        this.alreadyFetchedDriver$.next(true);
                        break;
                    case SupportStoreActions.getSupportsRequest.type:
                        this.alreadyFetchedSupports$.next(true);
                        break;
                    case PayoutStoreActions.getPayoutRequest.type:
                        this.alreadyFetchedPayout$.next(true);
                        break;
                }
            })
        );
    });

    profileRoute$ = createEffect(() => {
        const url$ = this.actions$.pipe(
            ofType(ROUTER_NAVIGATION),
            map((x: RouterNavigationAction) => x.payload.routerState.url)
        );
        return combineLatest([
            url$,
            this.store.select(SessionStoreSelectors.getUserId)
        ]).pipe(
            filter(([url, userId]) => url.includes('/profile') && !!userId),
            map(([_url, userId]) => UserStoreActions.getUserRequest({ id: userId }))
        );
    });

    trailersRoute$ = createEffect(() => {
        const url$ = this.actions$.pipe(
            ofType(ROUTER_NAVIGATION),
            map((x: RouterNavigationAction) => x.payload.routerState.url)
        );
        return combineLatest([
            url$,
            this.alreadyFetchedTrailers$,
            this.store.select(SessionStoreSelectors.getUserId)
        ]).pipe(
            filter(([url, alreadyFetchedTrailers, userId]) => url === '/trailer' && !alreadyFetchedTrailers && !!userId),
            map(([_url, _alreadyFetchedTrailers, userId]) => TrailerStoreActions.getTrailersRequest({ userId })),
            tap(() => this.alreadyFetchedTrailers$.next(true))
        );
    });

    driverRoute$ = createEffect(() => {
        const url$ = this.actions$.pipe(
            ofType(ROUTER_NAVIGATION),
            map((x: RouterNavigationAction) => x.payload.routerState.url)
        );
        return combineLatest([
            url$,
            this.alreadyFetchedDriver$,
            this.store.select(SessionStoreSelectors.getUserId)
        ]).pipe(
            filter(([url, alreadyFetchedDriver, userId]) => url === '/driver/profile' && !alreadyFetchedDriver && !!userId),
            switchMap(([_url, _alreadyFetchedDriver, id]) => [
                DriverStoreActions.getDriverRequest({ id }),
                UserStoreActions.getUserRequest({ id })
            ]),
            tap(() => this.alreadyFetchedDriver$.next(true))
        );
    });

    vehiclesRoute$ = createEffect(() => {
        const url$ = this.actions$.pipe(
            ofType(ROUTER_NAVIGATION),
            map((x: RouterNavigationAction) => x.payload.routerState.url)
        );
        return combineLatest([
            url$,
            this.alreadyFetchedVehicles$,
            this.store.select(SessionStoreSelectors.getUserId)
        ]).pipe(
            filter(([url, alreadyFetchedVehicles, userId]) => url === '/driver' && !alreadyFetchedVehicles && !!userId),
            switchMap(([_url, _alreadyFetchedVehicles, userId]) => [
                VehicleStoreActions.getVehiclesRequest({ userId }),
                DriverStoreActions.getDriverRequest({ id: userId })
            ]),
            tap(() => this.alreadyFetchedVehicles$.next(true))
        );
    });

    vehicleRoute$ = createEffect(() => {
        const url$ = this.actions$.pipe(
            ofType(ROUTER_NAVIGATION),
            map((x: RouterNavigationAction) => x.payload.routerState.url)
        );
        return combineLatest([
            url$,
            this.alreadyFetchedYears$
        ]).pipe(
            filter(([url, alreadyFetchedYears]) => url.includes('/driver/vehicle/') && !alreadyFetchedYears),
            map(() => DataStoreActions.getAllowedVehicleYearsRequest()),
            tap(() => this.alreadyFetchedYears$.next(true))
        );
    });

    trailerMapRoute$ = createEffect(() => {
        const url$ = this.actions$.pipe(
            ofType(ROUTER_NAVIGATION),
            map((x: RouterNavigationAction) => x.payload.routerState.url)
        );
        const params$ = this.actions$.pipe(
            ofType(ROUTER_NAVIGATION),
            map((x: RouterNavigationAction) => x.payload.routerState.root.firstChild?.firstChild?.params)
        );
        const trailerId$ = params$.pipe(
            map(params => params?.['trailerId']),
            distinctUntilChanged()
        );
        return combineLatest([
            url$,
            trailerId$,
            this.store.select(SessionStoreSelectors.getUserId)
        ]).pipe(
            filter(([url, _trailerId, userId]) => url.includes('/trailer/map') && !!userId),
            tap(([_url, _trailerId, userId]) => this.getPaymentsRetry(userId)),
            switchMap(([_url, id, userId]) => [
                id ?
                    TrailerStoreActions.getTrailerRequest({ id }) :
                    TrailerStoreActions.getTrailersRequest({ userId }),
                TowStoreActions.getTowsRequest({ userId, statuses: TOW_IN_PROGRESS_STATUSES }),
                UserStoreActions.getUserRequest({ id: userId }),
                TowRequestStoreActions.clearTowRequests({ userId }),
                RateStoreActions.getRatesRequest({ category: RateCategory.Compact }),
                PaymentStoreActions.getPaymentsRequest({ userId })
            ]),
            tap(() => this.alreadyFetchedPayments$.next(true))
        );
    });

    driverMapRoute$ = createEffect(() => {
        const url$ = this.actions$.pipe(
            ofType(ROUTER_NAVIGATION),
            map((x: RouterNavigationAction) => x.payload.routerState.url)
        );
        const params$ = this.actions$.pipe(
            ofType(ROUTER_NAVIGATION),
            map((x: RouterNavigationAction) => x.payload.routerState.root.firstChild?.firstChild?.params)
        );
        const vehicleId$ = params$.pipe(
            map(params => params?.['vehicleId']),
            distinctUntilChanged()
        );
        return combineLatest([
            url$,
            vehicleId$,
            this.store.select(SessionStoreSelectors.getUserId)
        ]).pipe(
            filter(([url, _vehicleId, userId]) => url.includes('/driver/map') && !!userId),
            tap(([_url, _vehicleId, userId]) => {
                this.getPaymentsRetry(userId);
                this.getPayoutRetry(userId);
            }),
            switchMap(([_url, id, userId]) => [
                VehicleStoreActions.getVehiclesRequest({ userId }),
                id ?
                    VehicleStoreActions.getVehicleRequest({ id }) :
                    actions.empty(),
                UserStoreActions.getUserRequest({ id: userId }),
                RateStoreActions.getRatesRequest({ category: RateCategory.Compact }),
                TowRequestStoreActions.getTowRequestsRequest({ userId, statuses: TOW_REQUEST_IN_PROGRESS_STATUSES }),
                PaymentStoreActions.getPaymentsRequest({ userId }),
                PayoutStoreActions.getPayoutRequest({ userId }),
                DriverBusyTimeStoreActions.getDriverBusyTimesRequest({ driverId: userId,
                    start: DateHelper.subtractDays(new Date(), 1) }),
                TowStoreActions.getTowsRequest({ statuses: [TowStatus.Started], driverId: userId })
            ]),
            tap(action => {
                switch (action.type as any) {
                    case PaymentStoreActions.getPaymentsRequest.type:
                        this.alreadyFetchedPayments$.next(true);
                        break;
                    case PayoutStoreActions.getPayoutRequest.type:
                        this.alreadyFetchedPayout$.next(true);
                        break;
                }
            })
        );
    });

    paymentListRoute$ = createEffect(() => {
        const url$ = this.actions$.pipe(
            ofType(ROUTER_NAVIGATION),
            map((x: RouterNavigationAction) => x.payload.routerState.url)
        );
        return url$.pipe(
            filter(url => url.endsWith('/payment')),
            switchMap(() => combineLatest([
                this.store.select(SessionStoreSelectors.getUserId),
                this.alreadyFetchedPayments$
            ])),
            filter(([userId, alreadyFetchedPayments]) => !!userId && !alreadyFetchedPayments),
            map(([userId]) => PaymentStoreActions.getPaymentsRequest({ userId })),
            tap(props => this.getPaymentsRetry(props.userId)),
            tap(() => this.alreadyFetchedPayments$.next(true))
        );
    });

    paymentRoute$ = createEffect(() => {
        const url$ = this.actions$.pipe(
            ofType(ROUTER_NAVIGATION),
            map((x: RouterNavigationAction) => x.payload.routerState.url)
        );
        return url$.pipe(
            filter(url => url.includes('/payment/new')),
            switchMap(() => from(CreditCardHelper.setCreditCardForm())),
            map(() => actions.empty())
        );
    });

    payoutRoute$ = createEffect(() => {
        const url$ = this.actions$.pipe(
            ofType(ROUTER_NAVIGATION),
            map((x: RouterNavigationAction) => x.payload.routerState.url)
        );
        return url$.pipe(
            filter(url => url.includes('/payout')),
            switchMap(() => combineLatest([
                this.store.select(SessionStoreSelectors.getUserId),
                this.alreadyFetchedPayout$,
                this.alreadyFetchedEarnings$
            ])),
            filter(([userId]) => !!userId),
            tap(([userId]) => {
                this.getPayoutRetry(userId);
                this.getEarningsRetry(userId);
            }),
            switchMap(([userId, alreadyFetchedPayout, alreadyFetchedEarnings]) => [
                UserStoreActions.getUserRequest({ id: userId }),
                alreadyFetchedPayout ? actions.empty() : PayoutStoreActions.getPayoutRequest({ userId }),
                alreadyFetchedEarnings ? actions.empty() : EarningStoreActions.getEarningsRequest({ userId }),
                DisbursementStoreActions.getDisbursementsTotalRequest({ userId, paid: false })
            ]),
            tap(action => {
                switch (action.type as any) {
                    case PayoutStoreActions.getPayoutRequest.type:
                        this.alreadyFetchedPayout$.next(true);
                        break;
                    case EarningStoreActions.getEarningsRequest.type:
                        this.alreadyFetchedEarnings$.next(true);
                        break;
                }
            })
        );
    });

    supportRoute$ = createEffect(() => {
        const url$ = this.actions$.pipe(
            ofType(ROUTER_NAVIGATION),
            map((x: RouterNavigationAction) => x.payload.routerState.url)
        );
        return url$.pipe(
            filter(url => url.includes('/support') && !url.includes('/admin/')),
            switchMap(() => combineLatest([
                this.store.select(SessionStoreSelectors.getUserId),
                this.alreadyFetchedSupports$
            ])),
            filter(([userId, alreadyFetchedUnresolvedSupport]) => !!userId && !alreadyFetchedUnresolvedSupport),
            switchMap(([userId]) => [SupportStoreActions.getSupportsRequest({ userId, resolved: false })]),
            tap(() => this.alreadyFetchedSupports$.next(true))
        );
    });

    towingActivityRoute$ = createEffect(() => {
        const url$ = this.actions$.pipe(
            ofType(ROUTER_NAVIGATION),
            map((x: RouterNavigationAction) => x.payload.routerState.url)
        );
        return combineLatest([
            url$,
            this.store.select(SessionStoreSelectors.getExperience),
            this.store.select(SessionStoreSelectors.getUserId)
        ]).pipe(
            filter(([url, _experience, userId]) => url.includes('/activity/towing') && !!userId),
            switchMap(([_url, experience, userId]) => [
                experience === 'trailer' ?
                    TowStoreActions.getTowsRequest({ userId, statuses: [TowStatus.Rated, TowStatus.Archived] }) :
                    TowStoreActions.getTowsRequest({ userId: undefined, statuses: [TowStatus.Rated, TowStatus.Archived],
                        driverId: userId })
            ])
        );
    });

    adminSupportRoute$ = createEffect(() => {
        const url$ = this.actions$.pipe(
            ofType(ROUTER_NAVIGATION),
            map((x: RouterNavigationAction) => x.payload.routerState.url)
        );
        return url$.pipe(
            filter(url => url.includes('/admin/support')),
            switchMap(() => [
                SupportStoreActions.getSupportsRequest({ userId: undefined, resolved: false }),
                UserStoreActions.getUsersByRoleRequest({ role: UserRole.Admin })
            ]),
            tap(() => this.alreadyFetchedSupports$.next(true))
        );
    });

    private getPaymentsRetry(userId: string): void {
        setTimeout(() => {
            // Retry in case the call hangs - for some reason it does when you refresh the page
            this.store.select(PaymentStoreSelectors.getLoading).pipe(
                take(1),
                filter(loading => loading)
            ).subscribe(() => {
                this.store.dispatch(PaymentStoreActions.getPaymentsRequest({ userId }));
                this.getPaymentsFallback();
            });
        }, 2000);
    }

    private getPaymentsFallback(): void {
        setTimeout(() => {
            // Fallback to set loading to false in case the call hangs - for some reason it does when you refresh the page
            this.store.select(PaymentStoreSelectors.getLoading).pipe(
                take(1),
                filter(loading => loading)
            ).subscribe(() => {
                this.store.dispatch(PaymentStoreActions.getPaymentsFailure({ error: '' }));
            });
        }, 2000);
    }

    private getPayoutRetry(userId: string): void {
        setTimeout(() => {
            // Retry in case the call hangs - for some reason it does when you refresh the page
            this.store.select(PayoutStoreSelectors.getLoading).pipe(
                take(1),
                filter(loading => loading)
            ).subscribe(() => {
                this.store.dispatch(PayoutStoreActions.getPayoutRequest({ userId }));
                this.getPayoutFallback();
            });
        }, 2000);
    }

    private getEarningsRetry(userId: string): void {
        setTimeout(() => {
            // Retry in case the call hangs - for some reason it does when you refresh the page
            this.store.select(EarningStoreSelectors.getLoading).pipe(
                take(1),
                filter(loading => loading)
            ).subscribe(() => {
                this.store.dispatch(EarningStoreActions.getEarningsRequest({ userId }));
                this.getEarningsFallback();
            });
        }, 2000);
    }

    private getPayoutFallback(): void {
        setTimeout(() => {
            // Fallback to set loading to false in case the call hangs - for some reason it does when you refresh the page
            this.store.select(PayoutStoreSelectors.getLoading).pipe(
                take(1),
                filter(loading => loading)
            ).subscribe(() => {
                this.store.dispatch(PayoutStoreActions.getPayoutFailure({ error: '' }));
            });
        }, 2000);
    }

    private getEarningsFallback(): void {
        setTimeout(() => {
            // Fallback to set loading to false in case the call hangs - for some reason it does when you refresh the page
            this.store.select(EarningStoreSelectors.getLoading).pipe(
                take(1),
                filter(loading => loading)
            ).subscribe(() => {
                this.store.dispatch(EarningStoreActions.getEarningsFailure({ error: '' }));
            });
        }, 2000);
    }
}
