import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { TowRequest } from '@models/tow-request.model';

export const adapter: EntityAdapter<TowRequest> = createEntityAdapter<TowRequest>({
    selectId: towRequest => towRequest.id
});

export interface State extends EntityState<TowRequest> {
    loading: boolean;
    error?: any;
    total: number;
}

export const initialState: State = adapter.getInitialState({
    loading: false,
    error: null,
    total: 0
});
