import { Vehicle } from '@models/vehicle.model';
import { VINDecoderData } from '@models/vin-decoder-data.model';
import { createAction, props } from '@ngrx/store';

// Get Vehicles
export const getVehiclesRequest = createAction(
    '[Vehicle] Get Vehicles Request',
    props<{ userId: string }>()
);
export const getVehiclesFailure = createAction(
    '[Vehicle] Get Vehicles Failure',
    props<{ error: string }>()
);
export const getVehiclesSuccess = createAction(
    '[Vehicle] Get Vehicles Success',
    props<{ vehicles: Vehicle[] }>()
);

// Get Vehicle
export const getVehicleRequest = createAction(
    '[Vehicle] Get Vehicle Request',
    props<{ id: string }>()
);
export const getVehicleFailure = createAction(
    '[Vehicle] Get Vehicle Failure',
    props<{ error: string }>()
);
export const getVehicleSuccess = createAction(
    '[Vehicle] Get Vehicle Success',
    props<{ vehicle: Vehicle }>()
);

// Get Active Vehicles
export const getActiveVehiclesRequest = createAction(
    '[Vehicle] Get Active Vehicles Request',
    props<{ userIds: string[] }>()
);
export const getActiveVehiclesFailure = createAction(
    '[Vehicle] Get Active Vehicles Failure',
    props<{ error: string }>()
);
export const getActiveVehiclesSuccess = createAction(
    '[Vehicle] Get Active Vehicles Success',
    props<{ vehicles: Vehicle[] }>()
);

// Get Active Vehicle
export const getActiveVehicleRequest = createAction(
    '[Vehicle] Get Active Vehicle Request',
    props<{ userId: string }>()
);
export const getActiveVehicleFailure = createAction(
    '[Vehicle] Get Active Vehicle Failure',
    props<{ error: string }>()
);
export const getActiveVehicleSuccess = createAction(
    '[Vehicle] Get Active Vehicle Success',
    props<{ vehicle: Vehicle }>()
);

// Save Vehicle
export const saveVehicleRequest = createAction(
    '[Vehicle] Save Vehicle Request',
    props<{ vehicle: Vehicle }>()
);
export const saveVehicleFailure = createAction(
    '[Vehicle] Save Vehicle Failure',
    props<{ error: string }>()
);
export const saveVehicleSuccess = createAction(
    '[Vehicle] Save Vehicle Success',
    props<{ vehicle: Vehicle }>()
);

// Delete Vehicle
export const deleteVehicleRequest = createAction(
    '[Vehicle] Delete Vehicle Request',
    props<{ vehicle: Vehicle }>()
);
export const deleteVehicleFailure = createAction(
    '[Vehicle] Delete Vehicle Failure',
    props<{ error: string }>()
);
export const deleteVehicleSuccess = createAction(
    '[Vehicle] Delete Vehicle Success',
    props<{ id: string }>()
);

// Clear Vehicles
export const clearVehicles = createAction(
    '[Vehicle] Clear Vehicles'
);

// Update Vehicles
export const updateVehiclesRequest = createAction(
    '[Vehicle] Update Vehicles Request',
    props<{ vehicles: Vehicle[] }>()
);
export const updateVehiclesFailure = createAction(
    '[Vehicle] Update Vehicles Failure',
    props<{ error: string }>()
);
export const updateVehiclesSuccess = createAction(
    '[Vehicle] Update Vehicles Success',
    props<{ vehicles: Vehicle[] }>()
);

// Decode VIN
export const decodeVINRequest = createAction(
    '[Vehicle] Decode VIN Request',
    props<{ vin: string }>()
);
export const decodeVINFailure = createAction(
    '[Vehicle] Decode VIN Failure',
    props<{ error: string }>()
);
export const decodeVINSuccess = createAction(
    '[Vehicle] Decode VIN Success',
    props<{ data: VINDecoderData }>()
);

// Is VIN Found
export const isVINFoundRequest = createAction(
    '[Vehicle] Is VIN Found Request',
    props<{ vin: string }>()
);
export const isVINFoundFailure = createAction(
    '[Vehicle] Is VIN Found Failure',
    props<{ error: string }>()
);
export const isVINFoundSuccess = createAction(
    '[Vehicle] Is VIN Found Success',
    props<{ found: boolean }>()
);
