import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Vehicle } from '@models/vehicle.model';

export const adapter: EntityAdapter<Vehicle> = createEntityAdapter<Vehicle>({
    selectId: vehicle => vehicle.id
});

export interface State extends EntityState<Vehicle> {
    loading: boolean;
    error?: any;
    total: number;
}

export const initialState: State = adapter.getInitialState({
    loading: false,
    error: null,
    total: 0
});
