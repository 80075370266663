import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as actions from './actions';
import { PaymentService } from '@services/payment.service';

@Injectable()
export class SessionStoreEffects {
    constructor(
        private actions$: Actions,
        private paymentService: PaymentService
    ) {}

    // // Get Bank Account Session Secret
    // getBankAccountSessionSecret$ = createEffect(() =>
    //     this.actions$.pipe(
    //         ofType(actions.getBankAccountSessionSecretRequest),
    //         switchMap(action => this.paymentService.getBankAccountSessionSecret(action.userId).pipe(
    //             map(bankAccountSessionSecret => actions.getBankAccountSessionSecretSuccess({ bankAccountSessionSecret })),
    //             catchError(error => of(actions.getBankAccountSessionSecretFailure({ error })))
    //         ))
    //     )
    // );
}
