import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as actions from './actions';
import { TransactionService } from '@services/transaction.service';

@Injectable()
export class TransactionStoreEffects {
    constructor(
        private actions$: Actions,
        private transactionService: TransactionService
    ) {}

    // Authorize
    authorize$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.authorizeRequest),
            switchMap(action => this.transactionService.authorize(action.amount, action.towId, action.userId, action.description,
                action.paymentType).pipe(
                switchMap(transaction => this.transactionService.saveTransaction(transaction))
            ).pipe(
                map(transaction => actions.authorizeSuccess({ transaction, towRequest: action.towRequest })),
                catchError(error => of(actions.authorizeFailure({ error })))
            ))
        )
    );

    // Cancel
    cancel$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.cancelRequest),
            switchMap(action => this.transactionService.cancel(action.id).pipe(
                switchMap(transaction => this.transactionService.saveTransaction(transaction))
            ).pipe(
                map(transaction => actions.cancelSuccess({ transaction })),
                catchError(error => of(actions.cancelFailure({ error })))
            ))
        )
    );

    // Complete Sale
    completeSale$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.completeSaleRequest),
            switchMap(action => this.transactionService.completeSale(action.id).pipe(
                switchMap(transaction => this.transactionService.saveTransaction(transaction))
            ).pipe(
                map(transaction => actions.completeSaleSuccess({ transaction })),
                catchError(error => of(actions.completeSaleFailure({ error })))
            ))
        )
    );

    // Capture
    capture$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.captureRequest),
            switchMap(action => this.transactionService.capture(action.amount, action.towId, action.userId,
                action.description, action.paymentType, action.driverId).pipe(
                switchMap(transaction => this.transactionService.saveTransaction(transaction))
            ).pipe(
                map(transaction => actions.captureSuccess({ transaction, rating: action.rating, payment: action.payment })),
                catchError(error => of(actions.captureFailure({ error })))
            ))
        )
    );

    // Get Transactions
    getTransactions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getTransactionsRequest),
            switchMap(action => this.transactionService.getTransactions(action.userId, action.towId, action.transactionType,
                action.status).pipe(
                map(transactions => actions.getTransactionsSuccess({ transactions })),
                catchError(error => of(actions.getTransactionsFailure({ error })))
            ))
        )
    );
}
