import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { environment } from '@environments/environment';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { MenuComponent } from './menu/menu.component';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import { AuthInterceptor } from '@interceptors/auth.interceptor';

@NgModule({
    declarations: [
        MenuComponent
    ],
    imports: [
        SharedModule,
    ],
    exports: [
        MenuComponent
    ],
    providers: [
        provideHttpClient(withInterceptorsFromDi()),
        provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
        provideFirestore(() => getFirestore()),
        provideAuth(() => getAuth()),
        provideStorage(() => getStorage()),
        provideMessaging(() => getMessaging()), {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptor,
        multi: true
    }]
})
export class CoreModule {}
