import { Disbursement } from '@models/disbursement.model';
import { createAction, props } from '@ngrx/store';

// Save Disbursement
export const saveDisbursementRequest = createAction(
    '[Disbursement] Save Disbursement Request',
    props<{ disbursement: Disbursement }>()
);
export const saveDisbursementFailure = createAction(
    '[Disbursement] Save Disbursement Failure',
    props<{ error: string }>()
);
export const saveDisbursementSuccess = createAction(
    '[Disbursement] Save Disbursement Success',
    props<{ disbursement: Disbursement }>()
);

// Get Disbursement
export const getDisbursementsTotalRequest = createAction(
    '[Disbursement] Get Disbursements Total Request',
    props<{ userId: string, paid?: boolean }>()
);
export const getDisbursementsTotalFailure = createAction(
    '[Disbursement] Get Disbursements Total Failure',
    props<{ error: string }>()
);
export const getDisbursementsTotalSuccess = createAction(
    '[Disbursement] Get Disbursements Total Success',
    props<{ total: number }>()
);
