import { GasPrice } from '@models/gas-price.model';
import { createAction, props } from '@ngrx/store';

// Get Gas Price
export const getGasPriceRequest = createAction(
    '[GasPrice] Get Gas Price Request',
    props<{ state: string }>()
);
export const getGasPriceFailure = createAction(
    '[GasPrice] Get Gas Price Failure',
    props<{ error: string }>()
);
export const getGasPriceSuccess = createAction(
    '[GasPrice] Get Gas Price Success',
    props<{ gasPrice: GasPrice }>()
);
