import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Payout } from '@models/payout.model';

export const adapter: EntityAdapter<Payout> = createEntityAdapter<Payout>({
    selectId: payout => payout.id
});

export interface State extends EntityState<Payout> {
    loading: boolean;
    error?: any;
    total: number;
}

export const initialState: State = adapter.getInitialState({
    loading: false,
    error: null,
    total: 0
});
