import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as actions from './actions';
import { CommunicationService } from '@services/communication.service';

@Injectable()
export class EmailStoreEffects {
    constructor(
        private actions$: Actions,
        private communicationService: CommunicationService
    ) {}

    // Send Email
    sendEmail$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.sendEmailRequest),
            switchMap(action => this.communicationService.sendEmail(action.email).pipe(
                map(email => actions.sendEmailSuccess({ email })),
                catchError(error => of(actions.sendEmailFailure({ error })))
            ))
        )
    );
}
