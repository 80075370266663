import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { adapter, State } from './state';
import { Driver } from '@models/driver.model';

export const getState: MemoizedSelector<object, State> = createFeatureSelector<State>('driver');

export const getLoading: MemoizedSelector<object, boolean> = createSelector(getState, (state: State) => state.loading);

export const getError: MemoizedSelector<object, string> = createSelector(getState, (state: State) => state.error);

export const getDrivers: MemoizedSelector<object, Driver[]> = createSelector(getState, adapter.getSelectors().selectAll);

export const getOtherDrivers = (id: string) => createSelector(getState, (state: State) => {
    const drivers = Object.values(state.entities) as Driver[];
    return drivers.filter(x => x.id !== id);
});

export const getDriver = (id: string) => createSelector(getState, (state: State) => state.entities[id] as Driver);

export const getDriverActive = (id: string) => createSelector(getState, (state: State) => !!state.entities[id]?.active);
