import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { DateHelper } from '@classes/date-helper';

@Component({
    selector: 'app-driver-map-timer',
    templateUrl: './driver-map-timer.component.html',
    styleUrls: ['./driver-map-timer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DriverMapTimerComponent implements OnInit {
    @Input() datetime: Date;
    @Input() isCargo: boolean | null = false;

    days = 0;
    hours = 0;
    minutes = 0;

    constructor(
        private changeDetectorRef: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.setTimer();
        setInterval(() => this.setTimer(), 60 * 1000);
    }

    private setTimer(): void {
        if (!this.datetime) {
            return;
        }

        const now = new Date();
        const { days, hours, minutes } = DateHelper.getDaysHoursMinutesAndSecondsRemaining(now, this.datetime);
        this.days = days;
        this.hours = hours;
        this.minutes = minutes;
        this.changeDetectorRef.detectChanges();
    }
}
