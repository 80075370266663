import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Subject, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as actions from './actions';
import { DriverService } from '@services/driver.service';
import { Driver } from '@models/driver.model';
import { Unsubscribe } from 'firebase/firestore';

@Injectable()
export class DriverStoreEffects {
    private unsubs: Unsubscribe[] = [];

    constructor(
        private actions$: Actions,
        private driverService: DriverService
    ) {}

    // Get Drivers
    getDrivers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getDriversRequest),
            switchMap(action => this.driverService.getDrivers(action.ids, action.active).pipe(
                map(drivers => actions.getDriversSuccess({ drivers })),
                catchError(error => of(actions.getDriversFailure({ error })))
            ))
        )
    );

    // Get Drivers Changes
    getDriversChanges$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getDriversChangesRequest),
            switchMap(action => {
                const sub$ = new Subject<Driver[]>();
                const unsub = this.driverService.getDriversChanges(sub$, action.ids);
                this.unsubs.push(unsub);
                return sub$.pipe(
                    map(drivers => actions.getDriversChangesSuccess({ drivers })),
                    catchError(error => of(actions.getDriversChangesFailure({ error })))
                );
            })
        )
    );

    // Get Driver
    getDriver$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getDriverRequest),
            switchMap(action => this.driverService.getDriver(action.id).pipe(
                map(driver => actions.getDriverSuccess({ driver })),
                catchError(error => of(actions.getDriverFailure({ error })))
            ))
        )
    );

    // Save Driver
    saveDriver$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.saveDriverRequest),
            switchMap(action => this.driverService.saveDriver(action.driver).pipe(
                map(driver => actions.saveDriverSuccess({ driver })),
                catchError(error => of(actions.saveDriverFailure({ error })))
            ))
        )
    );

    // Unsubscribe Drivers Changes
    unsubscribeDriversChanges$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.unsubscribeDriversChangesRequest),
            map(() => {
                this.unsubs.forEach(unsub => unsub());
                return actions.unsubscribeDriversChangesSuccess();
            })
        )
    );

    // Update Driver Properties
    updateDriverProperties$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.updateDriverPropertiesRequest),
            switchMap(action => this.driverService.updateDriverProperties(action.id, action.properties).pipe(
                map(partialDriver => actions.updateDriverPropertiesSuccess({ partialDriver })),
                catchError(error => of(actions.updateDriverPropertiesFailure({ error })))
            ))
        )
    );
}
