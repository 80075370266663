import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { IonFooter, IonHeader, ModalController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'app-mission',
    templateUrl: './mission.component.html',
    styleUrls: ['./mission.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MissionComponent implements OnInit, AfterViewInit {
    @ViewChild(IonHeader) header: any;
    @ViewChild(IonFooter) footer: any;
    @ViewChild('main') main: ElementRef;
    @Input() fromMenu = false;

    private threshold = 0;

    disabled$ = new BehaviorSubject(true);

    constructor(
        private modalController: ModalController
    ) {}

    ngOnInit(): void {
        this.disabled$.next(!this.fromMenu);
    }

    ngAfterViewInit(): void {
        const timer = setInterval(() => {
            const contentHeight = this.main.nativeElement.clientHeight;
            if (!contentHeight) {
                return;
            }

            const visibleHeight = window.innerHeight - this.header.el.offsetHeight - this.footer.el.offsetHeight;
            const styles = window.getComputedStyle(this.main.nativeElement);
            const marginTop = +styles.marginTop.replace('px', '');
            const marginBottom = +styles.marginBottom.replace('px', '');
            this.threshold = contentHeight - visibleHeight + marginBottom + marginTop;
            if (this.threshold <= 0) {
                this.disabled$.next(false);
            }

            this.footer.el.style.opacity = 1; // Hack to solve issue with iOS

            clearInterval(timer);
        }, 100);
    }

    onScroll(event: any) {
        const y = Math.ceil(event.detail.currentY);
        if (y >= this.threshold) {
            this.disabled$.next(false);
        }
    }

    onClose(): void {
        this.modalController.dismiss();
    }
}
