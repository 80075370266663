// eslint-disable-next-line no-shadow
export enum RateType {
    Service,
    Commute,
    Tow,
    Time,
    TrailerCancellation,
    DriverCancellation,
    Commission,
    Vehicle
}
