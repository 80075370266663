import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { reducer } from './reducer';
import { NotificationStoreEffects } from './effects';
import { StoreModule } from '@ngrx/store';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature('notification', reducer),
        EffectsModule.forFeature([NotificationStoreEffects])
    ],
    providers: []
})
export class NotificationStoreModule {}
