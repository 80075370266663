import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Earning } from '@models/earning.model';

export const adapter: EntityAdapter<Earning> = createEntityAdapter<Earning>({
    selectId: earning => earning.creationDate.toString()
});

export interface State extends EntityState<Earning> {
    loading: boolean;
    error?: any;
    total: number;
}

export const initialState: State = adapter.getInitialState({
    loading: false,
    error: null,
    total: 0
});
