import { requestPermissionFailure, requestPermissionRequest, requestPermissionSuccess } from './actions';
import { adapter, initialState, State } from './state';
import { createReducer, on, Action } from '@ngrx/store';

export const notificationReducer = createReducer(
    initialState,
    on(
        requestPermissionRequest,
        state => ({
            ...state,
            loading: true,
            error: null
        })
    ),
    on(
        requestPermissionFailure,
        (state, { error }) => ({
            ...state,
            loading: false,
            error
        })
    ),

    // Request Permission
    on(
        requestPermissionSuccess,
        (state, { token }) => adapter.setOne({ token }, {
            ...state,
            loading: false,
            error: null
        })
    )
);

export function reducer(state: State, action: Action) {
    return notificationReducer(state, action);
}
