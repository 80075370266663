import { environment } from '@environments/environment';
import { Expiration } from '@models/expiration.modal';
import { ExpirationProperty } from 'src/app/shared/types/expiration-property.type';
import { DateHelper } from './date-helper';
import { UtilityHelper } from './utility-helper';

export class ExpirationDateHelper {
    static checkExpirationDates<T>(obj: T): Expiration[] {
        const expirations: Expiration[] = [];
        const properties: ExpirationProperty[] = ['registrationExpDate', 'insuranceExpDate'];
        properties.forEach(property => {
            const expDate = (obj as any)[property];
            if (!expDate) {
                return;
            }

            const expiration = this.checkExpirationDate(expDate, property);
            if (!expiration) {
                return;
            }

            expirations.push(expiration);
        });
        return expirations;
    }

    static checkExpirationDate(expDate: Date, property: ExpirationProperty): Expiration | null {
        const name = UtilityHelper.capitalize(property.replace('ExpDate', ''));
        const today = DateHelper.getToday();
        if (DateHelper.compareDates(expDate, '<', today)) {
            return {
                property,
                type: 'error',
                message: `${name} has expired.`
            } as Expiration;
        }

        const date = DateHelper.addDays(today, environment.expirationWarningTimeframe);
        if (DateHelper.compareDates(expDate, '<=', date)) {
            const days = DateHelper.getDaysBetweenDates(today, expDate);
            return {
                property: property,
                type: 'warning',
                message: `${name} will expire in ${days} day${ days === 1 ? '' : 's' }.`
            } as Expiration;
        }

        return null;
    }
}
