import { ActionReducerMap } from '@ngrx/store';
import { allowedVehicleReducer } from './allowed-vehicle-store/reducer';
import { dataReducer } from './data-store/reducer';
import { driverReducer } from './driver-store/reducer';
import { notificationReducer } from './notification-store/reducer';
import { paymentReducer } from './payment-store/reducer';
import { payoutReducer } from './payout-store/reducer';
import { ratingReducer } from './rating-store/reducer';
import { rateReducer } from './rate-store/reducer';
import { sessionReducer } from './session-store/reducer';
import { State } from './state';
import { towRequestReducer } from './tow-request-store/reducer';
import { towReducer } from './tow-store/reducer';
import { trailerReducer } from './trailer-store/reducer';
import { transactionReducer } from './transaction-store/reducer';
import { userRatingsReducer } from './user-ratings-store/reducer';
import { userReducer } from './user-store/reducer';
import { vehicleReducer } from './vehicle-store/reducer';
import { supportReducer } from './support-store/reducer';
import { smsReducer } from './sms-store/reducer';
import { disbursementReducer } from './disbursement-store/reducer';
import { emailReducer } from './email-store/reducer';
import { gasPriceReducer } from './gas-price-store/reducer';
import { earningReducer } from './earning-store/reducer';
import { driverBusyTimeReducer } from './driver-busy-time-store/reducer';
import { chatReducer } from './chat-store/reducer';

export const reducers: ActionReducerMap<State> = {
    allowedVehicle: allowedVehicleReducer,
    chat: chatReducer,
    data: dataReducer,
    disbursement: disbursementReducer,
    driver: driverReducer,
    driverBusyTime: driverBusyTimeReducer,
    earning: earningReducer,
    email: emailReducer,
    gasPrice: gasPriceReducer,
    notification: notificationReducer,
    payment: paymentReducer,
    payout: payoutReducer,
    rate: rateReducer,
    rating: ratingReducer,
    session: sessionReducer,
    sms: smsReducer,
    support: supportReducer,
    towRequest: towRequestReducer,
    tow: towReducer,
    trailer: trailerReducer,
    transaction: transactionReducer,
    userRatings: userRatingsReducer,
    user: userReducer,
    vehicle: vehicleReducer
};
