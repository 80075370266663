<ion-header>
    <ion-toolbar color="secondary">
        <ng-container *ngIf="fromMenu; else logo">
            <ion-title>Mission</ion-title>

            <ion-buttons slot="end">
                <ion-button (click)="onClose()">
                    <i class="material-symbols-outlined">close</i>
                </ion-button>
            </ion-buttons>
        </ng-container>
        <ng-template #logo>
            <app-logo></app-logo>
        </ng-template>
    </ion-toolbar>
</ion-header>

<ion-content fullscreen [scrollEvents]="true" (ionScroll)="onScroll($event)">
    <main #main>
        <section>
            <div class="icons">
                <ion-icon slot="start" src="/assets/svg/trailer-5.svg"></ion-icon>
                <ion-icon slot="start" src="/assets/svg/trailer-1.svg"></ion-icon>
                <ion-icon slot="start" src="/assets/svg/trailer-6.svg"></ion-icon>
            </div>
            <p><strong>Are you in need of a reliable and affordable transportation service for your small utility trailer, RV or personal cargo?</strong> Look no further than Drive & Tow, our platform connects trailer or cargo owners with verified pickup truck drivers who can pick-up and drop-off safely and efficiently.</p>
            <p>With our user-friendly platform, finding the right pickup truck driver for your personal trailer or cargo transportation needs has never been easier. Simply schedule a request with your pick-up and drop-off date, time and location, weight and dimensions and our platform will connect you with a network of verified pickup truck drivers in your area that meets your specifications.</p>

            <div class="icons">
                <ion-icon slot="start" src="/assets/svg/vehicle-5.svg"></ion-icon>
                <ion-icon slot="start" src="/assets/svg/vehicle-6.svg"></ion-icon>
            </div>
            <p>And if you are a pickup truck owner looking to make additional income, our Drive & Tow platform connects you with trailer and cargo owners who are in need of transportation services.</p>
            <p>Once you sign up, you can browse available trailer or cargo transportation requests in your area and select the ones that fit your schedule and preferences. Then, simply pick-up and drop-off to its destination.</p>
            Sign up today and start earning extra income on your own schedule by towing and delivering personal trailers and cargo!
        </section>
    </main>
</ion-content>

<ion-footer class="ion-no-border">
    <ion-button type="button" expand="block" [disabled]="disabled$ | async" (click)="onClose()">
        Close
    </ion-button>
</ion-footer>
