import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { Trailer } from '@models/trailer.model';

@Pipe({
    name: 'trailerSummary'
})
export class TrailerSummaryPipe implements PipeTransform {
    constructor(
        private decimalPipe : DecimalPipe
    ) {}

    transform(trailer: Trailer): string {
        // const cargo = `${trailer.dimensions?.length}' x ${trailer.dimensions?.width}' x ${trailer.dimensions?.height}' (LxWxH)`;
        const cargo = `L: ${trailer.dimensions?.length}' W: ${trailer.dimensions?.width}' H: ${trailer.dimensions?.height}'`;
        return `${this.decimalPipe.transform(trailer.weight)} lbs |
            ${trailer.type === 'Cargo' ? cargo : `${trailer.length} ft | ${ trailer.couplerSize }`}`;
    }
}
