import { adapter, initialState, State } from './state';
import { createReducer, on, Action } from '@ngrx/store';
import { clearTrailers, deleteTrailerFailure, deleteTrailerRequest, deleteTrailerSuccess, getTrailerFailure, getTrailerRequest,
    getTrailersFailure, getTrailersRequest, getTrailersSuccess, getTrailerSuccess, saveTrailerFailure, saveTrailerRequest,
    saveTrailerSuccess } from './actions';

export const trailerReducer = createReducer(
    initialState,
    on(
        getTrailersRequest,
        getTrailerRequest,
        saveTrailerRequest,
        deleteTrailerRequest,
        state => ({
            ...state,
            loading: true,
            error: null
        })
    ),
    on(
        getTrailersFailure,
        getTrailerFailure,
        saveTrailerFailure,
        deleteTrailerFailure,
        (state, { error }) => ({
            ...state,
            loading: false,
            error
        })
    ),

    // Get Trailers
    on(
        getTrailersSuccess,
        (state, { trailers }) => adapter.setAll(trailers, {
            ...state,
            loading: false,
            error: null,
            total: trailers.length
        })
    ),

    // Get/Save Trailer
    on(
        getTrailerSuccess,
        saveTrailerSuccess,
        (state, { trailer }) => adapter.upsertOne(trailer, {
            ...state,
            loading: false,
            error: null
        })
    ),

    // Delete Trailer
    on(
        deleteTrailerSuccess,
        (state, { id }) => adapter.removeOne(id, {
            ...state,
            loading: false,
            error: null
        })
    ),

    // Clear Trailers
    on(
        clearTrailers,
        (state) => adapter.removeAll({
            ...state,
            loading: false,
            error: null
        })
    )
);

export function reducer(state: State, action: Action) {
    return trailerReducer(state, action);
}
