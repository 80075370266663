import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UtilityHelper } from '@classes/utility-helper';
import { PaymentType } from '@enums/payment-type.enum';
import { TransactionStatus } from '@enums/transaction-status.enum';
import { TransactionType } from '@enums/transaction-type.enum';
import { environment } from '@environments/environment';
import { Filter } from '@models/filter.model';
import { Sort } from '@models/sort.model';
import { Transaction } from '@models/transaction.model';
import { catchError, map, Observable } from 'rxjs';
import { DbService } from './db.service';
import { ObjectHelper } from '@classes/object-helper';

const COLLECTION = 'transactions';

@Injectable({
    providedIn: 'root'
})
export class TransactionService {
    constructor(
        private http: HttpClient,
        private dbService: DbService
    ) {}

    authorize(amount: number, towId: string, userId: string, description: string, type: PaymentType): Observable<Transaction> {
        const request = { amount, userId, description, metadata: { towId, type } };
        const url = `${environment.apiBaseUrl}/transactions/authorize`;
        return this.http.post<Transaction>(url, request).pipe(
            catchError(response => {
                throw response.error;
            })
        );
    }

    cancel(id: string): Observable<Transaction> {
        const url = `${environment.apiBaseUrl}/transactions/${id}/void`;
        return this.http.patch<Transaction>(url, null).pipe(
            catchError(response => {
                throw response.error;
            })
        );
    }

    completeSale(id: string): Observable<Transaction> {
        const url = `${environment.apiBaseUrl}/transactions/${id}/capture`;
        return this.http.patch<Transaction>(url, null).pipe(
            catchError(response => {
                throw response.error;
            })
        );
    }

    capture(amount: number, towId: string, userId: string, description: string, type: PaymentType, driverId?: string):
        Observable<Transaction> {
        const request = { amount, userId, description, metadata: { towId, type, driverId } };
        const url = `${environment.apiBaseUrl}/transactions/capture`;
        return this.http.post<Transaction>(url, request).pipe(
            catchError(response => {
                throw response.error;
            })
        );
    }

    getRemoteTransactions(userId: string, towId?: string, type?: TransactionType, status?: TransactionStatus):
        Observable<Transaction[]> {
        const request = { userId, towId, type, status } as Record<string, string>;
        const queryString = UtilityHelper.getQueryString(request);
        const url = `${environment.apiBaseUrl}/transactions?${queryString}`;
        return this.http.get<Transaction[]>(url, request).pipe(
            catchError(response => {
                throw response.error;
            })
        );
    }

    getTransactions(userId: string, towId?: string, type?: TransactionType, status?: TransactionStatus):
        Observable<Transaction[]> {
        const filters: Filter[] = [{ field: 'userId', operator: '==', value: userId }];

        if (towId) {
            filters.push({ field: 'towId', operator: '==', value: towId });
        }

        if (type) {
            filters.push({ field: 'type', operator: '==', value: type });
        }

        if (status) {
            filters.push({ field: 'status', operator: '==', value: status });
        }

        const sort: Sort = { field: 'creationDate', direction: 'desc' };
        const otherFixes = (transaction: Transaction) => this.fixDates(transaction);
        return this.dbService.getList(COLLECTION, filters, sort, undefined, undefined, otherFixes);
    }

    getTransaction(id: string): Observable<Transaction> {
        return this.dbService.getObj<Transaction>(COLLECTION, id).pipe(
            map(transaction => {
                this.fixDates(transaction);
                return transaction;
            })
        );
    }

    saveTransaction(transaction: Transaction): Observable<Transaction> {
        // The date comes back from the API in this format: 2024-03-31T02:28:36.000Z
        // So let's fix it to be in JS date format
        const creationDate = new Date(transaction.creationDate);
        const lastUpdateDate = new Date(transaction.lastUpdateDate);
        transaction = { ...transaction, creationDate, lastUpdateDate };
        return this.dbService.saveObj(COLLECTION, transaction, 'set');
    }

    private fixDates(transaction: Transaction): void {
        ['lastUpdateDate'].forEach(property => {
            ObjectHelper.fixDate(transaction, property);
        });
    }
}
