import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of, Subject } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as actions from './actions';
import { TowService } from '@services/tow.service';
import { Unsubscribe } from '@angular/fire/firestore';
import { Tow } from '@models/tow.model';
import { SubSink } from 'subsink';
import { Store } from '@ngrx/store';

@Injectable()
export class TowStoreEffects {
    private unsubs: Unsubscribe[] = [];
    private subs = new SubSink();

    constructor(
        private actions$: Actions,
        private towService: TowService,
        private store: Store
    ) {}

    // Get Tows
    getTows$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getTowsRequest),
            switchMap(action => this.towService.getTows(action.userId, action.statuses, action.driverId).pipe(
                map(tows => actions.getTowsSuccess({ tows })),
                catchError(error => of(actions.getTowsFailure({ error })))
            ))
        )
    );

    // Get Tows Near by
    getTowsNearBy$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getTowsNearByRequest),
            switchMap(action => this.towService.getTowsNearBy(action.center, action.radius, action.excludeUserId,
                action.includeStatuses).pipe(
                map(tows => actions.getTowsNearBySuccess({ tows })),
                catchError(error => of(actions.getTowsNearByFailure({ error })))
            ))
        )
    );

    // Get Tow
    getTow$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getTowRequest),
            switchMap(action => this.towService.getTow(action.id).pipe(
                map(tow => actions.getTowSuccess({ tow })),
                catchError(error => of(actions.getTowFailure({ error })))
            ))
        )
    );

    // Get Tow Changes
    getTowChanges$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getTowChangesRequest),
            map(action => {
                const sub$ = new Subject<Tow>();
                this.subs.add(
                    sub$.subscribe(tow => this.store.dispatch(actions.getTowChangesSuccess({ tow })))
                );
                const unsub = this.towService.getTowChanges(action.id, sub$);
                this.unsubs.push(unsub);
                return actions.getTowChangesLoaded();
            })
        )
    );

    // Save Tow
    saveTow$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.saveTowRequest),
            switchMap(action => this.towService.saveTow(action.tow).pipe(
                map(tow => actions.saveTowSuccess({ tow })),
                catchError(error => of(actions.saveTowFailure({ error })))
            ))
        )
    );

    // Delete Tow
    deleteTow$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.deleteTowRequest),
            switchMap(action => this.towService.deleteTow(action.tow).pipe(
                map(id => actions.deleteTowSuccess({ id })),
                catchError(error => of(actions.deleteTowFailure({ error })))
            ))
        )
    );

    // Unsubscribe Tow Changes
    unsubscribeTowChanges$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.unsubscribeTowChangesRequest),
            map(() => {
                this.unsubs.forEach(unsub => unsub());
                this.subs.unsubscribe();
                return actions.unsubscribeTowChangesSuccess();
            })
        )
    );

    // Update Tow Properties
    updateTowProperties$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.updateTowPropertiesRequest),
            switchMap(action => this.towService.updateTowProperties(action.id, action.properties).pipe(
                map(partialTow => actions.updateTowPropertiesSuccess({ partialTow })),
                catchError(error => of(actions.updateTowPropertiesFailure({ error })))
            ))
        )
    );
}
