import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { adapter, State } from './state';
import { AllowedVehicle } from '@models/allowed-vehicle.model';
import { ListHelper } from '@classes/list-helper';

export const getState: MemoizedSelector<object, State> = createFeatureSelector<State>('allowedVehicle');

export const getLoading: MemoizedSelector<object, boolean> = createSelector(getState, (state: State) => state.loading);

export const getError: MemoizedSelector<object, string> = createSelector(getState, (state: State) => state.error);

export const getTotal: MemoizedSelector<object, number> = createSelector(getState, (state: State) => state.total);

export const getAllowedVehicles: MemoizedSelector<object, AllowedVehicle[]> =
    createSelector(getState, adapter.getSelectors().selectAll);

export const getMakes = (year: number) => createSelector(getState, (state: State) => {
    const allowedVehicles = Object.values(state.entities) as AllowedVehicle[];
    let makes = allowedVehicles.filter(x => x.year === year).map(x => x.make);
    makes = ListHelper.removeDuplicatesFromList(makes);
    makes.sort();
    return makes;
});

export const getModels = (year: number, make: string) => createSelector(getState, (state: State) => {
    const allowedVehicles = Object.values(state.entities) as AllowedVehicle[];
    const models = allowedVehicles.filter(x => x.year === year && x.make === make).map(x => x.model);
    models.sort();
    return models;
});

export const getAllowedVehicle = (year: number, make: string, model: string) => createSelector(getState, (state: State) => {
    const allowedVehicles = Object.values(state.entities) as AllowedVehicle[];
    const allowedVehicle = allowedVehicles.find(x => x.year === year && x.make === make && x.model === model);
    return allowedVehicle;
});
