import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { State } from './state';
import { Chat } from '@models/chat.model';

export const getState: MemoizedSelector<object, State> = createFeatureSelector<State>('chat');

export const getLoading: MemoizedSelector<object, boolean> = createSelector(getState, (state: State) => state.loading);

export const getError: MemoizedSelector<object, string> = createSelector(getState, (state: State) => state.error);

export const getChat = (id: string) => createSelector(getState, (state: State) => state.entities[id]);

export const getChatByTowId = (towId: string) => createSelector(getState, (state: State) => {
    const chats = Object.values(state.entities) as Chat[];
    const chat = chats.find(x => x.towId === towId);
    return chat;
});

export const getUnreadMessagesCount = (id: string, userId: string) => createSelector(getState, (state: State) =>
    state.entities[id]?.messages.filter(x => x.userId !== userId && !x.read).length ?? 0);

export const getLastUnreadMessage = (id: string, userId: string) => createSelector(getState, (state: State) =>
    state.entities[id]?.messages.filter(x => x.userId !== userId && !x.read).at(-1));

export const getChats = (towIds: string[]) => createSelector(getState, (state: State) => {
    const chats = Object.values(state.entities) as Chat[];
    return chats.filter(x => !!x.towId && towIds.includes(x.towId));
});
