import { Injectable } from '@angular/core';
import { DbService } from './db.service';
import { Observable, combineLatest, map, switchMap, of } from 'rxjs';
import { DriverBusyTime } from '@models/driver-busy-time.model';
import { Filter } from '@models/filter.model';
import { ListHelper } from '@classes/list-helper';
import { ObjectHelper } from '@classes/object-helper';

const COLLECTION = 'driverBusyTimes';
type Field = 'start' | 'end';
type Operator = '>=' | '<=';
interface Set {
    field: Field;
    operator: Operator;
}
export const DRIVER_BUSY_TIME_FILTER_SETS: Set[][] = [
    [{ field: 'start', operator: '>=' }, { field: 'start', operator: '<=' }],
    [{ field: 'end', operator: '>=' }, { field: 'end', operator: '<=' }],
    [{ field: 'start', operator: '<=' }, { field: 'end', operator: '>=' }],
    [{ field: 'start', operator: '>=' }, { field: 'end', operator: '<=' }]
];

@Injectable({
    providedIn: 'root'
})
export class DriverBusyTimeService {
    constructor(
        private dbService: DbService
    ) {}

    getDriversBusyTimes(driverIds: string[], start: Date, end: Date): Observable<DriverBusyTime[]> {
        // Given Firebase doesn't allow inequality queries on more than one field, we have to split them and combine the results
        const sets$: Observable<DriverBusyTime[]>[] = [];
        DRIVER_BUSY_TIME_FILTER_SETS.forEach(set => {
            const set$ = combineLatest([
                this.getPartialDriversBusyTimes(driverIds, set[0].field, set[0].operator, start),
                this.getPartialDriversBusyTimes(driverIds, set[1].field, set[1].operator, end)
            ]).pipe(
                map(([startResult, endResult]) => {
                    const list = [...startResult, ...endResult];
                    const startIds = startResult.map(x => x.id);
                    const endIds = endResult.map(x => x.id);
                    const intersectIds = startIds.filter(x => endIds.includes(x));
                    const intersectList = list.filter(x => intersectIds.includes(x.id));
                    return intersectList;
                })
            );
            sets$.push(set$);
        });
        return combineLatest(sets$).pipe(
            map(x => {
                let list: DriverBusyTime[] = [];
                x.forEach(set => {
                    list = [...list, ...set];
                });
                const uniqueList = this.removeDuplicates(list);
                return uniqueList;
            })
        );
    }

    getDriverBusyTimes(driverId: string, start: Date): Observable<DriverBusyTime[]> {
        const filters: Filter[] = [
            { field: 'driverId', operator: '==', value: driverId },
            { field: 'start', operator: '>=', value: start }
        ];
        return this.getList(filters);
    }

    saveDriverBusyTime(driverBusyTime: DriverBusyTime): Observable<DriverBusyTime> {
        return this.dbService.saveObj(COLLECTION, driverBusyTime);
    }

    deleteDriverBusyTime(towRequestId: string): Observable<string> {
        const filters: Filter[] = [
            { field: 'towRequest.id', operator: '==', value: towRequestId }
        ];
        return this.dbService.getList<DriverBusyTime>(COLLECTION, filters).pipe(
            switchMap(list => {
                if (list.length === 0) {
                    return '';
                }

                const id = list[0].id;
                return this.dbService.deleteObj(COLLECTION, id);
            })
        );
    }

    private getPartialDriversBusyTimes(driverIds: string[], field: Field, operator: Operator, value: Date):
        Observable<DriverBusyTime[]> {
        if (driverIds.length === 0) {
            return of([]);
        }

        const filters: Filter[] = [
            { field: 'driverId', operator: 'in', value: driverIds },
            { field, operator, value }
        ];
        return this.getList(filters);
    }

    private getList(filters: Filter[]): Observable<DriverBusyTime[]> {
        const otherFixes = (driverBusyTime: DriverBusyTime) => this.fixDates(driverBusyTime);
        return this.dbService.getList(COLLECTION, filters, undefined, undefined, undefined, otherFixes);
    }

    private removeDuplicates(list: DriverBusyTime[]): DriverBusyTime[] {
        const m = new Map<string, DriverBusyTime>();
        list.forEach(x => m.set(x.id, x));
        return ListHelper.convertMapToList(m);
    }

    private fixDates(driverBusyTime: DriverBusyTime): void {
        ObjectHelper.fixDate(driverBusyTime, 'start');
        ObjectHelper.fixDate(driverBusyTime, 'end');
    }
}
