import { adapter, initialState, State } from './state';
import { createReducer, on, Action } from '@ngrx/store';
import { sendEmailFailure, sendEmailRequest, sendEmailSuccess } from './actions';

export const emailReducer = createReducer(
    initialState,
    on(
        sendEmailRequest,
        state => ({
            ...state,
            loading: true,
            error: null
        })
    ),
    on(
        sendEmailFailure,
        (state, { error }) => ({
            ...state,
            loading: false,
            error
        })
    ),

    // Send Email
    on(
        sendEmailSuccess,
        (state, { email }) => adapter.upsertOne(email, {
            ...state,
            loading: false,
            error: null
        })
    )
);

export function reducer(state: State, action: Action) {
    return emailReducer(state, action);
}
