import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Subject, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as actions from './actions';
import { ChatService } from '@services/chat.service';
import { Unsubscribe } from '@angular/fire/firestore';
import { Chat } from '@models/chat.model';
import { SubSink } from 'subsink';
import { Store } from '@ngrx/store';

@Injectable()
export class ChatStoreEffects {
    private unsubs: Unsubscribe[] = [];
    private subs = new SubSink();

    constructor(
        private actions$: Actions,
        private chatService: ChatService,
        private store: Store
    ) {}

    // Save Chat
    saveChat$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.saveChatRequest),
            switchMap(action => this.chatService.saveChat(action.chat).pipe(
                map(chat => actions.saveChatSuccess({ chat, message: action.message })),
                catchError(error => of(actions.saveChatFailure({ error })))
            ))
        )
    );

    // Get Chat Changes
    getChatChanges$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getChatChangesRequest),
            map(action => {
                const sub$ = new Subject<Chat>();
                this.subs.add(
                    sub$.subscribe(chat => this.store.dispatch(actions.getChatChangesSuccess({ chat })))
                );
                const unsub = this.chatService.getChatChanges(action.id, sub$);
                this.unsubs.push(unsub);
                return actions.getChatChangesLoaded();
            })
        )
    );

    // Get Chats Changes by Tow ID
    getChatsChangesByTowId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getChatsChangesByTowIdRequest),
            map(action => {
                const sub$ = new Subject<Chat[]>();
                this.subs.add(
                    sub$.subscribe(chats => this.store.dispatch(actions.getChatsChangesByTowIdSuccess({ chats })))
                );
                const unsub = this.chatService.getChatsChangesByTowId(action.towId, sub$);
                this.unsubs.push(unsub);
                return actions.getChatsChangesByTowIdLoaded();
            })
        )
    );

    // Unsubscribe Chat Changes
    unsubscribeChatChanges$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.unsubscribeChatChangesRequest),
            map(() => {
                this.unsubs.forEach(unsub => unsub());
                this.subs.unsubscribe();
                return actions.unsubscribeChatChangesSuccess();
            })
        )
    );

    // Get Chats
    getChats$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getChatsRequest),
            switchMap(action => this.chatService.getChats(action.ids).pipe(
                map(chats => actions.getChatsSuccess({ chats })),
                catchError(error => of(actions.getChatsFailure({ error })))
            ))
        )
    );
}
