import { PaymentType } from '@enums/payment-type.enum';
import { TransactionStatus } from '@enums/transaction-status.enum';
import { TransactionType } from '@enums/transaction-type.enum';
import { Rating } from '@models/rating.model';
import { TowPayment } from '@models/tow-payment.model';
import { TowRequest } from '@models/tow-request.model';
import { Transaction } from '@models/transaction.model';
import { createAction, props } from '@ngrx/store';

// Authorize
export const authorizeRequest = createAction(
    '[Transaction] Authorize Request',
    props<{ amount: number, towId: string, userId: string, description: string, paymentType: PaymentType,
        towRequest: TowRequest }>()
);
export const authorizeFailure = createAction(
    '[Transaction] Authorize Failure',
    props<{ error: string }>()
);
export const authorizeSuccess = createAction(
    '[Transaction] Authorize Success',
    props<{ transaction: Transaction, towRequest: TowRequest }>()
);

// Cancel
export const cancelRequest = createAction(
    '[Transaction] Cancel Request',
    props<{ id: string }>()
);
export const cancelFailure = createAction(
    '[Transaction] Cancel Failure',
    props<{ error: string }>()
);
export const cancelSuccess = createAction(
    '[Transaction] Cancel Success',
    props<{ transaction: Transaction }>()
);

// Complete Sale
export const completeSaleRequest = createAction(
    '[Transaction] Complete Sale Request',
    props<{ id: string }>()
);
export const completeSaleFailure = createAction(
    '[Transaction] Complete Sale Failure',
    props<{ error: string }>()
);
export const completeSaleSuccess = createAction(
    '[Transaction] Complete Sale Success',
    props<{ transaction: Transaction }>()
);

// Capture
export const captureRequest = createAction(
    '[Transaction] Capture Request',
    props<{ amount: number, towId: string, userId: string, description: string, paymentType: PaymentType, driverId?: string,
        rating?: Rating, payment?: TowPayment }>()
);
export const captureFailure = createAction(
    '[Transaction] Capture Failure',
    props<{ error: string }>()
);
export const captureSuccess = createAction(
    '[Transaction] Capture Success',
    props<{ transaction: Transaction, rating?: Rating, payment?: TowPayment }>()
);

// Get Transactions
export const getTransactionsRequest = createAction(
    '[Transaction] Get Transactions Request',
    props<{ userId: string, towId: string, transactionType?: TransactionType, status?: TransactionStatus }>()
);
export const getTransactionsFailure = createAction(
    '[Transaction] Get Transactions Failure',
    props<{ error: string }>()
);
export const getTransactionsSuccess = createAction(
    '[Transaction] Get Transactions Success',
    props<{ transactions: Transaction[] }>()
);
