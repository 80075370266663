import { Injectable } from '@angular/core';
import { Disbursement } from '@models/disbursement.model';
import { Observable, map } from 'rxjs';
import { DbService } from './db.service';
import { Filter } from '@models/filter.model';

const COLLECTION = 'disbursements';

@Injectable({
    providedIn: 'root'
})
export class DisbursementService {
    constructor(
        private dbService: DbService
    ) {}

    saveDisbursement(disbursement: Disbursement): Observable<Disbursement> {
        return this.dbService.saveObj(COLLECTION, disbursement);
    }

    getDisbursementsTotal(userId: string, paid?: boolean): Observable<number> {
        const filters: Filter[] = [{ field: 'towRequest.driverDetails.user.id', operator: '==', value: userId }];

        if (paid !== undefined) {
            filters.push({ field: 'paid', operator: '==', value: paid });
        }

        return this.dbService.getSum(COLLECTION, 'amount', filters);
    }
}
