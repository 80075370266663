import { adapter, initialState, State } from './state';
import { createReducer, on, Action } from '@ngrx/store';
import { getChatsChangesByTowIdRequest, getChatsChangesByTowIdSuccess, getChatChangesLoaded, getChatChangesRequest,
    getChatChangesSuccess, getChatsFailure, getChatsRequest, getChatsSuccess, saveChatFailure, saveChatRequest,
    saveChatSuccess, getChatsChangesByTowIdLoaded } from './actions';

export const chatReducer = createReducer(
    initialState,
    on(
        saveChatRequest,
        getChatChangesRequest,
        getChatsChangesByTowIdRequest,
        getChatsRequest,
        state => ({
            ...state,
            loading: true,
            error: null
        })
    ),
    on(
        saveChatFailure,
        getChatsFailure,
        (state, { error }) => ({
            ...state,
            loading: false,
            error
        })
    ),
    on(
        getChatChangesLoaded,
        getChatsChangesByTowIdLoaded,
        (state) => ({
            ...state,
            loading: false
        })
    ),

    // Save Chat/Get Chat Changes
    on(
        saveChatSuccess,
        getChatChangesSuccess,
        (state, { chat }) => adapter.upsertOne(chat, {
            ...state,
            loading: false,
            error: null
        })
    ),

    // Get Chats/Get Chat by ID Changes
    on(
        getChatsSuccess,
        getChatsChangesByTowIdSuccess,
        (state, { chats }) => adapter.upsertMany(chats, {
            ...state,
            loading: false,
            error: null
        })
    )
);

export function reducer(state: State, action: Action) {
    return chatReducer(state, action);
}
