import { Pipe, PipeTransform } from '@angular/core';
import { PhoneHelper } from 'src/app/core/classes/phone-helper';

@Pipe({
    name: 'phone'
})
export class PhonePipe implements PipeTransform {
    transform(value: string): string {
        return PhoneHelper.formatPhone(value);
    }
}
