import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of, Subject } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as actions from './actions';
import { TowRequestService } from '@services/tow-request.service';
import { TowRequest } from '@models/tow-request.model';
import { Unsubscribe } from '@angular/fire/firestore';
import { SubSink } from 'subsink';
import { Store } from '@ngrx/store';

@Injectable()
export class TowRequestStoreEffects {
    private unsubs: Unsubscribe[] = [];
    private subs = new SubSink();

    constructor(
        private actions$: Actions,
        private towRequestService: TowRequestService,
        private store: Store
    ) {}

    // Get Tow Requests
    getTowRequests$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getTowRequestsRequest),
            switchMap(action => this.towRequestService.getTowRequests(action.towId, action.userId, action.statuses,
                action.vehicleId).pipe(
                map(towRequests => actions.getTowRequestsSuccess({ towRequests })),
                catchError(error => of(actions.getTowRequestsFailure({ error })))
            ))
        )
    );

    // Get Tow Requests Changes
    getTowRequestsChanges$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getTowRequestsChangesRequest),
            map(action => {
                const sub$ = new Subject<TowRequest[]>();
                this.subs.add(
                    sub$.subscribe(towRequests => this.store.dispatch(actions.getTowRequestsChangesSuccess({ towRequests })))
                );
                const unsub = this.towRequestService.getTowRequestsChanges(sub$, action.towId, action.userId, action.statuses,
                    action.vehicleId, action.notExpired);
                this.unsubs.push(unsub);
                return actions.getTowRequestsChangesLoaded();
            })
        )
    );

    // Get Tow Request
    getTowRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getTowRequestRequest),
            switchMap(action => this.towRequestService.getTowRequest(action.id).pipe(
                map(towRequest => actions.getTowRequestSuccess({ towRequest })),
                catchError(error => of(actions.getTowRequestFailure({ error })))
            ))
        )
    );

    // Save Tow Request
    saveTowRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.saveTowRequestRequest),
            switchMap(action => this.towRequestService.saveTowRequest(action.towRequest).pipe(
                map(towRequest => actions.saveTowRequestSuccess({ towRequest })),
                catchError(error => of(actions.saveTowRequestFailure({ error })))
            ))
        )
    );

    // Delete Tow Request
    deleteTowRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.deleteTowRequestRequest),
            switchMap(action => this.towRequestService.deleteTowRequest(action.towRequest).pipe(
                map(id => actions.deleteTowRequestSuccess({ id })),
                catchError(error => of(actions.deleteTowRequestFailure({ error })))
            ))
        )
    );

    // Unsubscribe Tow Request Changes
    unsubscribeTowRequestsChanges$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.unsubscribeTowRequestsChangesRequest),
            map(() => {
                this.unsubs.forEach(unsub => unsub());
                this.subs.unsubscribe();
                return actions.unsubscribeTowRequestsChangesSuccess();
            })
        )
    );

    // Update Tow Requests
    updateTowRequests$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.updateTowRequestsRequest),
            switchMap(action => this.towRequestService.updateTowRequests(action.towRequests).pipe(
                map(towRequests => action.clearStore && action.userId ?
                    actions.clearTowRequests({ userId: action.userId }) :
                    actions.updateTowRequestsSuccess({ towRequests })
                ),
                catchError(error => of(actions.updateTowRequestsFailure({ error })))
            ))
        )
    );
}
