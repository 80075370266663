import { adapter, initialState, State } from './state';
import { createReducer, on, Action } from '@ngrx/store';
import { getDisbursementsTotalFailure, getDisbursementsTotalRequest, getDisbursementsTotalSuccess, saveDisbursementFailure,
    saveDisbursementRequest, saveDisbursementSuccess } from './actions';

export const disbursementReducer = createReducer(
    initialState,
    on(
        saveDisbursementRequest,
        getDisbursementsTotalRequest,
        state => ({
            ...state,
            loading: true,
            error: null
        })
    ),
    on(
        saveDisbursementFailure,
        getDisbursementsTotalFailure,
        (state, { error }) => ({
            ...state,
            loading: false,
            error
        })
    ),

    // Save Disbursement
    on(
        saveDisbursementSuccess,
        (state, { disbursement }) => adapter.upsertOne(disbursement, {
            ...state,
            loading: false,
            error: null
        })
    ),

    // Get Disbursements Total
    on(
        getDisbursementsTotalSuccess,
        (state, { total }) => ({
            ...state,
            loading: false,
            total
        })
    )
);

export function reducer(state: State, action: Action) {
    return disbursementReducer(state, action);
}
