import { AllowedVehicle } from '@models/allowed-vehicle.model';
import { createAction, props } from '@ngrx/store';

// Get Allowed Vehicles
export const getAllowedVehiclesRequest = createAction(
    '[AllowedVehicle] Get Allowed Vehicles Request',
    props<{ year: number, make?: string, model?: string }>()
);
export const getAllowedVehiclesFailure = createAction(
    '[AllowedVehicle] Get Allowed Vehicles Failure',
    props<{ error: string }>()
);
export const getAllowedVehiclesSuccess = createAction(
    '[AllowedVehicle] Get Allowed Vehicles Success',
    props<{ allowedVehicles: AllowedVehicle[] }>()
);
