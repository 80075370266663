import { Injectable } from '@angular/core';
import { Notification } from '@models/notification.model';
import { from, map, Observable, take } from 'rxjs';
import { getMessaging, getToken } from '@angular/fire/messaging';
import { HttpClient } from '@angular/common/http';
import { getApp } from '@angular/fire/app';
import { environment } from '@environments/environment';
import { UtilityService } from './utility.service';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    constructor(
        private http: HttpClient,
        private utilityService: UtilityService
    ) {}

    promptToAcceptNotifications(message: string, acceptFn: Function): void {
        if (Notification.permission !== 'default') {
            return;
        }

        const header = 'Accept Notifications';
        const acceptBtn = 'Accept';
        const denyBtn = 'Deny';
        this.utilityService.prompt(header, message, acceptBtn, denyBtn, acceptFn).pipe(
            take(1)
        ).subscribe();
    }

    pushNotification(notification: Notification): Observable<void> {
        const url = `${environment.apiBaseUrl}/communication/push-notification`;
        return this.http.post<void>(url, notification);
    }

    requestPermission(): Observable<string> {
        const app = getApp();
        const messaging = getMessaging(app);
        return from(getToken(messaging));
    }
}
