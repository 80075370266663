import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Experience } from '@custom-types/experience.type';
import { DistancesDurations } from '@models/distances-durations.model';

@Component({
    selector: 'app-tow-distances-durations',
    templateUrl: './tow-distances-durations.component.html',
    styleUrls: ['./tow-distances-durations.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TowDistancesDurationsComponent {
    @Input() distancesDurations!: DistancesDurations;
    @Input() experience: Experience = 'driver';
}
