import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as actions from './actions';
import { PayoutService } from '@services/payout.service';

@Injectable()
export class PayoutStoreEffects {
    constructor(
        private actions$: Actions,
        private payoutService: PayoutService
    ) {}

    // Get Payout
    getPayout$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getPayoutRequest),
            switchMap(action => this.payoutService.getPayout(action.userId, action.route).pipe(
                map(payouts => actions.getPayoutSuccess({ payout: payouts })),
                catchError(error => of(actions.getPayoutFailure({ error })))
            ))
        )
    );

    // Create Payout
    createPayout$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.createPayoutRequest),
            switchMap(action => this.payoutService.createPayout(action.userId, action.route).pipe(
                map(payout => actions.createPayoutSuccess({ payout })),
                catchError(error => of(actions.createPayoutFailure({ error })))
            ))
        )
    );
}
