import { adapter, initialState, State } from './state';
import { createReducer, on, Action } from '@ngrx/store';
import { deleteDriverBusyTimeFailure, deleteDriverBusyTimeRequest, deleteDriverBusyTimeSuccess, getDriverBusyTimesFailure,
    getDriverBusyTimesRequest, getDriverBusyTimesSuccess, getDriversBusyTimesFailure, getDriversBusyTimesRequest,
    getDriversBusyTimesSuccess, saveDriverBusyTimeFailure, saveDriverBusyTimeRequest, saveDriverBusyTimeSuccess } from './actions';

export const driverBusyTimeReducer = createReducer(
    initialState,
    on(
        getDriversBusyTimesRequest,
        getDriverBusyTimesRequest,
        saveDriverBusyTimeRequest,
        deleteDriverBusyTimeRequest,
        state => ({
            ...state,
            loading: true,
            error: null
        })
    ),
    on(
        getDriversBusyTimesFailure,
        getDriverBusyTimesFailure,
        saveDriverBusyTimeFailure,
        deleteDriverBusyTimeFailure,
        (state, { error }) => ({
            ...state,
            loading: false,
            error
        })
    ),

    // Get Drivers Busy Times
    on(
        getDriversBusyTimesSuccess,
        (state, { driversBusyTimes }) => adapter.setAll(driversBusyTimes, {
            ...state,
            loading: false,
            error: null
        })
    ),

    // Get Driver Busy Times
    on(
        getDriverBusyTimesSuccess,
        (state, { driverBusyTimes }) => adapter.setAll(driverBusyTimes, {
            ...state,
            loading: false,
            error: null
        })
    ),

    // Save Driver Busy Time
    on(
        saveDriverBusyTimeSuccess,
        (state, { driverBusyTime }) => adapter.upsertOne(driverBusyTime, {
            ...state,
            loading: false,
            error: null
        })
    ),

    // Delete Driver Busy Time
    on(
        deleteDriverBusyTimeSuccess,
        (state, { id }) => adapter.removeOne(id, {
            ...state,
            loading: false,
            error: null
        })
    )
);

export function reducer(state: State, action: Action) {
    return driverBusyTimeReducer(state, action);
}
