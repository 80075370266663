import { adapter, initialState, State } from './state';
import { createReducer, on, Action } from '@ngrx/store';
import { clearRating, getRatingFailure, getRatingRequest, getRatingsFailure, getRatingsRequest, getRatingsSuccess,
    getRatingSuccess, saveRatingFailure, saveRatingRequest, saveRatingSuccess } from './actions';

export const ratingReducer = createReducer(
    initialState,
    on(
        getRatingsRequest,
        getRatingRequest,
        saveRatingRequest,
        state => ({
            ...state,
            loading: true,
            error: null
        })
    ),
    on(
        getRatingsFailure,
        getRatingFailure,
        saveRatingFailure,
        (state, { error }) => ({
            ...state,
            loading: false,
            error
        })
    ),

    // Get Ratings
    on(
        getRatingsSuccess,
        (state, { ratings }) => adapter.setAll(ratings, {
            ...state,
            loading: false,
            error: null,
            total: ratings.length
        })
    ),

    // Get/Save Rating
    on(
        getRatingSuccess,
        saveRatingSuccess,
        (state, { rating }) => adapter.upsertOne(rating, {
            ...state,
            loading: false,
            error: null
        })
    ),

    // Clear Rating
    on(
        clearRating,
        (state) => adapter.removeAll({
            ...state,
            loading: false,
            error: null
        })
    )
);

export function reducer(state: State, action: Action) {
    return ratingReducer(state, action);
}
