import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UtilityHelper } from '@classes/utility-helper';
import { environment } from '@environments/environment';

@Component({
    selector: 'app-send-reminder',
    templateUrl: './send-reminder.component.html',
    styleUrls: ['./send-reminder.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SendReminderComponent {
    @Input() showTimer = false;
    @Output() sendReminder = new EventEmitter<void>();

    delay = environment.driverSendReminderDelay;
    isIOS = UtilityHelper.isIOS();

    onSendReminder(): void {
        this.showTimer = true;
        this.sendReminder.emit();
    }

    onTimerEnded(): void {
        this.showTimer = false;
    }
}
