import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { State } from './state';
import { Vehicle } from '@models/vehicle.model';

export const getState: MemoizedSelector<object, State> = createFeatureSelector<State>('vehicle');

export const getTotal: MemoizedSelector<object, number> = createSelector(getState, (state: State) => state.total as number);

export const getLoading: MemoizedSelector<object, boolean> = createSelector(getState, (state: State) => state.loading);

export const getError: MemoizedSelector<object, string> = createSelector(getState, (state: State) => state.error);

export const getVehicles = (userId: string) => createSelector(getState, (state: State) => {
    let vehicles = Object.values(state.entities) as Vehicle[];
    vehicles = vehicles.filter(x => x.userId === userId);
    return vehicles;
});

export const getVehicle = (id: string) => createSelector(getState, (state: State) => state.entities[id] as Vehicle);

export const getActiveVehicles = () => createSelector(getState, (state: State) => {
    let vehicles = Object.values(state.entities) as Vehicle[];
    vehicles = vehicles.filter(x => x.active);
    return vehicles;
});

export const getActiveVehicle = (userId: string) => createSelector(getState, (state: State) => {
    const vehicles = Object.values(state.entities) as Vehicle[];
    const vehicle = vehicles.find(x => x.userId === userId && x.active);
    return vehicle!;
});
