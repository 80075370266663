import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Earning } from '@models/earning.model';
import { Payout } from '@models/payout.model';
import { Observable, catchError } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PayoutService {
    constructor(
        private http: HttpClient
    ) {}

    getPayout(userId: string, route?: string): Observable<Payout> {
        const url = `${environment.apiBaseUrl}/payouts/${userId}?route=${route ?? ''}`;
        return this.http.get<Payout>(url).pipe(
            catchError(response => {
                // If the erros is that it didn't find it, then just return - we'll let the user create one when that's the case
                if (response.status === 404) {
                    return [];
                }

                throw response.error;
            })
        );
    }

    createPayout(userId: string, route?: string): Observable<Payout> {
        const url = `${environment.apiBaseUrl}/payouts`;
        return this.http.post<Payout>(url, { userId, route }).pipe(
            catchError(response => {
                throw response.error;
            })
        );
    }

    getEarnings(userId: string): Observable<Earning[]> {
        const url = `${environment.apiBaseUrl}/payouts/${userId}/earnings`;
        return this.http.get<Earning[]>(url);
    }
}
