import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { TowRequestStatus } from '@enums/tow-request-status.enum';
import { environment } from '@environments/environment';
import { AlertController } from '@ionic/angular';
import { DriverDetails } from '@models/driver-details.model';
import { TowRequest } from '@models/tow-request.model';

@Component({
    selector: 'app-trailer-map-driver-details',
    templateUrl: './trailer-map-driver-details.component.html',
    styleUrls: ['./trailer-map-driver-details.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TrailerMapDriverDetailsComponent implements OnChanges {
    @Input() driverDetails!: DriverDetails | null;
    @Input() towRequest!: TowRequest | null | undefined;
    @Input() hide = true;
    @Input() showToggle = true;
    @Output() toggleDriverDetails = new EventEmitter<void>();
    @Output() sendTowRequest = new EventEmitter<DriverDetails>();
    @Output() cancelTowRequest = new EventEmitter<TowRequest>();

    displayDefaultDriverPhoto = false;
    status?: string;
    btnColor = 'medium';
    btnFill = 'solid';
    rating = environment.rating;
    milesAwayColor = '';

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private alertController: AlertController
    ) {}

    ngOnChanges(event: any): void {
        this.handleDuplicateEmptyAvatar();

        const driverDetails = event.driverDetails?.currentValue;
        if (driverDetails) {
            this.displayDefaultDriverPhoto = false;
            this.setMilesAwayColor(driverDetails.milesAway);
        }

        if (!event.towRequest) {
            return;
        }

        this.status = this.towRequest?.status !== undefined ? TowRequestStatus[this.towRequest.status] : undefined;
        this.btnColor = 'medium';
        this.btnFill = 'solid';

        switch (this.towRequest?.status) {
            case TowRequestStatus.Accepted:
            case TowRequestStatus.Approved:
                this.btnColor = 'success';
                this.btnFill = 'outline';
                break;
            case TowRequestStatus.Declined:
            case TowRequestStatus.Rejected:
            case TowRequestStatus.Canceled:
                this.btnColor = 'danger';
                this.btnFill = 'outline';
                break;
        }
    }

    onToggleDriverDetails(): void {
        this.toggleDriverDetails.emit();
    }

    onDriverPhotoError(): void {
        this.displayDefaultDriverPhoto = true;
        this.changeDetectorRef.detectChanges();
    }

    onSendTowRequest(): void {
        this.sendTowRequest.emit(this.driverDetails!);
    }

    async onCancelTowRequest(): Promise<void> {
        const alert = await this.alertController.create({
            header: 'Confirm',
            subHeader: 'Are you sure?',
            message: 'This request will be canceled.',
            buttons: [{
                text: 'No',
                role: 'cancel',
                cssClass: 'secondary'
            }, {
                text: 'Yes',
                handler: () => {
                    const towRequest = { ...this.towRequest!, status: TowRequestStatus.Canceled };
                    this.cancelTowRequest.emit(towRequest);
                }
            }]
        });
        await alert.present();
    }

    // For some strange reason sometimes there is a 2nd ion-avatar element with an empty image. This will take care of it
    private handleDuplicateEmptyAvatar(): void {
        const $img = document.querySelector('ion-avatar img:not([src])');
        $img?.parentElement?.remove();
    }

    private setMilesAwayColor(milesAway: number): void {
        let color = '';
        environment.milesAwayColors.forEach(x => {
            if (milesAway >= x.from && milesAway < x.to) {
                color = x.color;
            }
        });
        this.milesAwayColor = `var(--ion-color-${color}`;
    }
}
