import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Chat } from '@models/chat.model';

export const adapter: EntityAdapter<Chat> = createEntityAdapter<Chat>({
    selectId: chat => chat.id
});

export interface State extends EntityState<Chat> {
    loading: boolean;
    error?: any;
}

export const initialState: State = adapter.getInitialState({
    loading: false,
    error: null
});
