<ion-header>
    <ion-toolbar color="secondary" *ngIf="user$ | async as user">
        <ion-avatar slot="start" *ngIf="user.photo && !displayDefaultPhoto; else defaultPhoto">
            <img [src]="user.photo" (error)="onPhotoError()" referrerpolicy="no-referrer">
        </ion-avatar>
        <ng-template #defaultPhoto>
            <mat-icon slot="start">person</mat-icon>
        </ng-template>

        <div class="name ellipsis">
            <span>{{ user.firstName }} {{ user.lastName }}</span>
        </div>

        <ion-buttons slot="end">
            <ion-menu-toggle>
                <ion-button>
                    <i class="material-symbols-outlined">close</i>
                </ion-button>
            </ion-menu-toggle>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content>
    <div class="wrapper" [class.trailer-menu]="trailerExperience$ | async" [class.driver-menu]="driverExperience$ | async"
        [class.admin]="isAdmin$ | async" [class.ios]="isIOS">
        <ion-list>
            <ion-menu-toggle>
                <ion-item routerDirection="forward" routerLink="/home" *ngIf="!!!(isHome$ | async)">
                    <i slot="start" class="material-symbols-outlined">home</i>
                    <ion-label>
                        Home
                    </ion-label>
                </ion-item>
                <ion-item routerDirection="forward" routerLink="/account">
                    <i slot="start" class="material-symbols-outlined">person</i>
                    <ion-label>
                        Account
                    </ion-label>
                </ion-item>

                <ng-container *ngIf="driverExperience$ | async">
                    <ion-item routerDirection="forward" routerLink="/driver/profile">
                        <i slot="start" class="material-symbols-outlined">account_box</i>
                        <ion-label>
                            Driver Profile
                        </ion-label>
                    </ion-item>
                    <ion-item routerDirection="forward" routerLink="/driver">
                        <ion-icon slot="start" src="/assets/svg/vehicle-outline-rev.svg"></ion-icon>
                        <ion-label>
                            Vehicles
                        </ion-label>
                    </ion-item>
                    <ion-item routerDirection="forward" routerLink="/payment">
                        <i slot="start" class="material-symbols-outlined">credit_card</i>
                        <ion-label>
                            Payment Methods
                        </ion-label>
                    </ion-item>
                    <ion-item routerDirection="forward" routerLink="/payout">
                        <i slot="start" class="material-symbols-outlined">wallet</i>
                        <ion-label>
                            Payout & Earnings
                        </ion-label>
                    </ion-item>
                    <ion-item routerDirection="forward" routerLink="/driver/map">
                        <i slot="start" class="material-symbols-outlined">where_to_vote</i>
                        <ion-label>
                            Towing Map
                        </ion-label>
                    </ion-item>
                    <ion-item routerDirection="forward" routerLink="/activity/towing">
                        <i slot="start" class="material-symbols-outlined">history</i>
                        <ion-label>
                            Towing Activity
                        </ion-label>
                    </ion-item>
                </ng-container>

                <ng-container *ngIf="trailerExperience$ | async">
                    <ion-item routerDirection="forward" routerLink="/payment">
                        <i slot="start" class="material-symbols-outlined">credit_card</i>
                        <ion-label>
                            Payment Methods
                        </ion-label>
                    </ion-item>
                    <ion-item routerDirection="forward" routerLink="/trailer">
                        <ion-icon slot="start" src="/assets/svg/trailer-outline-rev.svg"></ion-icon>
                        <ion-label>
                            Trailers/Cargo
                        </ion-label>
                    </ion-item>
                    <ion-item routerDirection="forward" routerLink="/trailer/map">
                        <i slot="start" class="material-symbols-outlined">location_on</i>
                        <ion-label>
                            Towing Map
                        </ion-label>
                    </ion-item>
                    <ion-item routerDirection="forward" routerLink="/activity/towing">
                        <i slot="start" class="material-symbols-outlined">history</i>
                        <ion-label>
                            Towing Activity
                        </ion-label>
                    </ion-item>
                </ng-container>

                <ion-item routerDirection="forward" routerLink="/support">
                    <i slot="start" class="material-symbols-outlined">contact_support</i>
                    <ion-label>
                        Support
                    </ion-label>
                </ion-item>
                <ion-item button (click)="onOpenMission()">
                    <i slot="start" class="material-symbols-outlined">handshake</i>
                    <ion-label>
                        Mission
                    </ion-label>
                </ion-item>
                <ion-item button (click)="onOpenPrivacyPolicy()">
                    <i slot="start" class="material-symbols-outlined">shield_lock</i>
                    <ion-label>
                        Privacy Policy
                    </ion-label>
                </ion-item>
                <ion-item button (click)="onOpenTermsOfUse()">
                    <i slot="start" class="material-symbols-outlined">description</i>
                    <ion-label>
                        Terms of Use
                    </ion-label>
                </ion-item>
                <ion-item button *ngIf="(driver$ | async) && (driverExperience$ | async)" (click)="doNothing()" [detail]="false">
                    <i slot="start" class="material-symbols-outlined">{{ (active$ | async) ? 'check' : 'block' }}</i>
                    <ion-toggle color="success" [checked]="active$ | async" [disabled]="activeDisabled" (ionChange)="onActiveToggle($event)">
                        {{ (active$ | async) ? 'Active' : 'Inactive' }}
                    </ion-toggle>
                </ion-item>
                <ion-item button (click)="onLogout()">
                    <i slot="start" class="material-symbols-outlined">logout</i>
                    <ion-label>
                        Logout
                    </ion-label>
                </ion-item>

                <ng-container *ngIf="isAdmin$ | async">
                    <ion-list-header>
                        <ion-label>Admin</ion-label>
                    </ion-list-header>
                    <ion-item routerDirection="forward" routerLink="/admin/support">
                        <i slot="start" class="material-symbols-outlined">contact_support</i>
                        <ion-label>
                            Support
                        </ion-label>
                        <ion-chip slot="end">{{ supportCount$ | async }}</ion-chip>
                    </ion-item>
                </ng-container>
            </ion-menu-toggle>
        </ion-list>

        <div class="version">v{{ version }}</div>
    </div>
</ion-content>
