import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { GasPrice } from '@models/gas-price.model';

export const adapter: EntityAdapter<GasPrice> = createEntityAdapter<GasPrice>({
    selectId: gasPrice => gasPrice.name
});

export interface State extends EntityState<GasPrice> {
    loading: boolean;
    error?: any;
}

export const initialState: State = adapter.getInitialState({
    loading: false,
    error: null
});
