import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Email } from '@models/email.model';
import { SMS } from '@models/sms.model';
import { Observable, catchError, map, of } from 'rxjs';
import { DbService } from './db.service';

const COLLECTION = 'emails';

@Injectable({
    providedIn: 'root'
})
export class CommunicationService {
    constructor(
        private http: HttpClient,
        private dbService: DbService
    ) {}

    sendSMS(sms: SMS): Observable<SMS> {
        if (environment.disableSMS) {
            return of(sms);
        }

        const url = environment.twilio.messageApi.replace('{accountSid}', environment.twilio.accountSid);
        const body = new URLSearchParams();
        body.set('Body', sms.message);
        body.set('To', sms.phone);
        body.set('From', environment.twilio.phoneNumber);
        const auth = window.btoa(`${environment.twilio.accountSid}:${environment.twilio.authToken}`);

        const options = {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
                .set('Authorization', `Basic ${auth}`)
        };

        return this.http.post(url, body.toString(), options).pipe(
            map(() => sms),
            catchError(response => {
                console.error(response);
                throw response.error?.message;
            })
        );
    }

    sendEmail(email: Email): Observable<Email> {
        return this.dbService.saveObj(COLLECTION, email);
    }
}
