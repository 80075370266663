/* eslint-disable max-len */
export const environment = {
    name: 'prod',
    production: true,
    baseUrl: 'https://driveandtow.com',
    apiBaseUrl: 'https://us-east1-drive-and-tow-prod.cloudfunctions.net/api',
    twilio: {
        messageApi: 'https://api.twilio.com/2010-04-01/Accounts/{accountSid}/Messages.json',
        accountSid: 'AC01ff85375463e299605a862ef4393caf',
        authToken: 'f5e86c739b21e53f545e03392f86cd7c',
        phoneNumber: '+18883851859'
    },
    firebaseConfig: {
        apiKey: 'AIzaSyBMzGLFXBnBxDdk7BpfL-uIue0LhrXl4hc',
        authDomain: 'drive-and-tow-prod.firebaseapp.com',
        projectId: 'drive-and-tow-prod',
        storageBucket: 'drive-and-tow-prod.appspot.com',
        messagingSenderId: '1037610853082',
        appId: '1:1037610853082:web:36f9c8667f9a6f593a3be3',
        measurementId: 'G-MLLQSY0YC2'
    },
    firebaseInClauseLimit: 10,
    maxFileSize: 5 * 1024 * 1024, // Needs to match with the Firestore rules
    allowedVehicleMinYear: 1992,
    googleApi: {
        mapUrl: 'https://maps.googleapis.com/maps/api/js?loading=async&v=weekly&libraries=places,marker&callback=mapInit&key={key}',
        geocodeUrl: 'https://maps.googleapis.com/maps/api/geocode/json?key={key}&address={address}&latlng={latlng}',
        key: 'AIzaSyDhOcd_9HxDKMrOV0pHvgs_8Dddu29HsUE', // Restricted
        openKey: 'AIzaSyBMzGLFXBnBxDdk7BpfL-uIue0LhrXl4hc', // Un-restricted - Needed for the geocode API
        mapId: 'ebe8679f2c4228c0',
        directionsUrl: 'https://maps.google.com?daddr={address}'
    },
    radius: {
        min: 5,
        max: 50,
        interval: 5,
        default: 20
    },
    rating: {
        activeColor: '#ffc409',
        defaultColor: '#b8bac2'
    },
    stripe: {
        publishableKey: 'pk_live_51NZDhxIHntnNFq9sjK3Pp1ResyUOYBQ8vqXTZOQs4z4EHOQ2gjt7tnAlKQjYy9rkuGDKDJhx5wqr2Yaa3iYCitMF00fL3UNVBU'
    },
    support: {
        phone: '4075047771',
        email: 'emailus@driveandtow.com'
    },
    expirationWarningTimeframe: 30, // In days
    sentryDsn: 'https://77614192ddddda4730d9036237401426@o4506584862425088.ingest.sentry.io/4506584865505280',
    disbursement: {
        delay: 7, // In days - Shuld match the value found in the functions config
        processingTime: 3 // In days - This is the time it takes from the moment the disbursement is sent until it becomes available in the bank account
    },
    gasProfitMultiplier: 2,
    creditCard: {
        percentageFee: 2.9,
        flatFee: 0.3
    },
    milesAwayColors: [{
        color: 'success',
        from: 0,
        to: 10
    }, {
        color: 'warning',
        from: 10,
        to: 20
    }, {
        color: 'danger',
        from: 20,
        to: 999999
    }],
    towStartingSoonTimeframe: 60, // In minutes
    driverBusyTimePadding: 60, // In minutes
    disableSMS: false,
    driverSendReminderDelay: 2, // In minutes
    minTip: 0.5,
    trailerCancellationFeePeriod: 2, // In days
    driverCancellationFeePeriod: 3, // In days
    towInProgressDriverLocationUpdateThreshold: 5, // In minutes
    delayedTrailerOnWayTowsThreshold: 15, // in minutes
    hitchDuration: 15, // in minutes
    rapidApis: {
        key: '16865c81c0msh2d3c8ea3dcdcbaap12b138jsn9a9fa7cd90b1',
        vinDecoder: {
            host: 'vin-decoder-api.p.rapidapi.com',
            route: '/decode?vin='
        }
    },
    showSupportMessage: true
};
