<ion-header>
    <ion-toolbar color="secondary">
        <ion-title>Requests</ion-title>
        <ion-buttons slot="end">
            <ion-button id="tow-requests-sort">
                <i class="material-symbols-outlined">sort</i>
            </ion-button>
            <ion-popover trigger="tow-requests-sort" [dismissOnSelect]="true">
                <ng-template>
                    <ion-content>
                        <ion-list class="tow-requests-sort">
                            <ion-item *ngFor="let sort of towRequestsSortOptions; let last = last" [button]="true"
                                [class.active]="towRequestsSort === sort" [lines]="last ? 'none' : 'full'"
                                (click)="onTowRequestsSort(sort)">
                                <ion-label>{{ sort.name }}</ion-label>
                            </ion-item>
                        </ion-list>
                    </ion-content>
                </ng-template>
            </ion-popover>
            <ion-button (click)="onClose()">
                <i class="material-symbols-outlined">close</i>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content fullscreen>
    <ion-list>
        <ion-item *ngFor="let towRequest of sortedTowRequests$ | async">
            <div class="left" slot="start">
                <ion-avatar *ngIf="!displayDefaultPhoto.get(towRequest.id); else defaultDriverPhoto">
                    <img [src]="towRequest.tow.user.photo" (error)="onPhotoError(towRequest.id)" referrerpolicy="no-referrer">
                </ion-avatar>
                <ng-template #defaultDriverPhoto>
                    <mat-icon>person</mat-icon>
                </ng-template>
            </div>
            <ion-label class="center">
                <div class="name">{{ towRequest.tow.user.firstName }} {{ towRequest.tow.user.lastName }}</div>
                <div class="weight-length">
                    <i *ngIf="+towRequest.tow.trailer.icon === 7; else icon" class="material-symbols-outlined" matPrefix>weight</i>
                    <ng-template #icon>
                        <ion-icon [src]="'/assets/svg/trailer-' + towRequest.tow.trailer.icon + '.svg'"></ion-icon>
                    </ng-template>
                    {{ towRequest.tow.trailer | trailerSummary }}
                </div>
                <div class="distance-duration">
                    {{ towRequest.distance | number }} mi | {{ towRequest.duration | duration }}
                </div>
                {{ towRequest.tow.datetime | date:'M/d/y h:mm a' }}
            </ion-label>
            <div slot="end" class="right">
                <div class="cost success">
                    <span>$</span>{{ towRequest.driverDetails.payment.payment | number:'1.2-2' }}
                </div>
                <div class="btns" [ngSwitch]="towRequest.status">
                    <ion-button *ngSwitchCase="0" color="medium"> <!-- Pending -->
                        Pending
                    </ion-button>
                    <ion-button *ngSwitchCase="1" fill="outline" color="success"> <!-- Accepted -->
                        Accepted
                    </ion-button>
                    <ion-button *ngSwitchCase="2" fill="outline" color="danger"> <!-- Declined -->
                        Declined
                    </ion-button>
                    <ion-button *ngSwitchCase="3" fill="outline" color="success"> <!-- Approved -->
                        Approved
                    </ion-button>
                    <ion-button *ngSwitchCase="4" fill="outline" color="danger"> <!-- Rejected -->
                        Rejected
                    </ion-button>
                    <ion-button *ngSwitchCase="6" fill="outline" color="medium"> <!-- Available -->
                        Available
                    </ion-button>
                    <ion-button color="primary" (click)="onView(towRequest)">
                        View
                    </ion-button>
                    <!-- Show "Cancel" link when status is Accepted, Approved, or Available and the tow status is Pending or Started -->
                    <div *ngIf="[1, 3, 6].includes(towRequest.status) && [0, 2].includes(towRequest.tow.status)" class="link" (click)="onCancelTowRequest(towRequest)">
                        Cancel
                    </div>
                </div>
            </div>
        </ion-item>
    </ion-list>
</ion-content>
