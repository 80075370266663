import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as actions from './actions';
import { PaymentService } from '@services/payment.service';

@Injectable()
export class PaymentStoreEffects {
    constructor(
        private actions$: Actions,
        private paymentService: PaymentService
    ) {}

    // Get Payments
    getPayments$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getPaymentsRequest),
            switchMap(action => this.paymentService.getPayments(action.userId).pipe(
                map(payments => actions.getPaymentsSuccess({ payments })),
                catchError(error => of(actions.getPaymentsFailure({ error })))
            ))
        )
    );

    // Save Payment
    savePayment$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.savePaymentRequest),
            switchMap(action => this.paymentService.savePayment(action.payment).pipe(
                map(payment => actions.savePaymentSuccess({ payment })),
                catchError(error => of(actions.savePaymentFailure({ error })))
            ))
        )
    );

    // Set Default Payment
    setDefaultPayment$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.setDefaultPaymentRequest),
            switchMap(action => this.paymentService.setDefaultPayment(action.payment).pipe(
                map(payment => actions.setDefaultPaymentSuccess({ payment })),
                catchError(error => of(actions.setDefaultPaymentFailure({ error })))
            ))
        )
    );

    // Delete Payment
    deletePayment$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.deletePaymentRequest),
            switchMap(action => this.paymentService.deletePayment(action.payment).pipe(
                map(payment => actions.deletePaymentSuccess({ payment, default: action.default })),
                catchError(error => of(actions.deletePaymentFailure({ error })))
            ))
        )
    );
}
