import { AfterViewInit, ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { environment } from '@environments/environment';
import { IonFooter, ModalController } from '@ionic/angular';

@Component({
    selector: 'app-privacy-policy',
    templateUrl: './privacy-policy.component.html',
    styleUrls: ['./privacy-policy.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrivacyPolicyComponent implements AfterViewInit {
    @ViewChild(IonFooter) footer: any;

    email = environment.support.email;

    constructor(
        private modalController: ModalController
    ) {}

    ngAfterViewInit(): void {
        this.footer.el.style.opacity = 1; // Hack to solve issue with iOS
    }

    onClose(): void {
        this.modalController.dismiss();
    }
}
