<ion-header>
    <ion-toolbar color="secondary">
        <ion-title>Privacy Policy</ion-title>

        <ion-buttons slot="end">
            <ion-button (click)="onClose()">
                <i class="material-symbols-outlined">close</i>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content fullscreen>
    <main #main>
        <section>
            <p>Drive & Tow is committed to protecting the privacy and security of your personal information. This Privacy Policy describes how we collect, use, and disclose information when you use our application.</p>

            <h2>Information We Collect</h2>
            <p><strong>Personal Information:</strong> We collect personal information such as your name, email address, phone number, and address when you register for an account.</p>

            <p><strong>Usage Data:</strong> We collect information about how you use our application, including your IP address, browser type, and operating system.</p>

            <h2>How We Use Your Information</h2>
            <p>We use your personal information to respond to your inquiries and send you updates.</p>

            <h2>Information Sharing</h2>
            <p>We do not sell, trade, or otherwise transfer your personal information to third parties.</p>

            <h2>Security</h2>
            <p>We take reasonable measures to protect your personal information, but no method of transmission over the internet or electronic storage is 100% secure.</p>

            <h2>Your Choices</h2>
            <p>You can update your account information and preferences on your account page.</p>

            <h2>Changes to This Policy</h2>
            <p>We may update this Privacy Policy from time to time.</p>

            <h2>Contact Us</h2>
            <p>If you have any questions or concerns about this Privacy Policy, please contact us at <a [href]="'mailto:' + email">{{ email }}</a>.</p>
        </section>
    </main>
</ion-content>

<ion-footer class="ion-no-border">
    <ion-button type="button" expand="block" (click)="onClose()">
        Close
    </ion-button>
</ion-footer>
