import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { DriverBusyTime } from '@models/driver-busy-time.model';

export const adapter: EntityAdapter<DriverBusyTime> = createEntityAdapter<DriverBusyTime>({
    selectId: userDriverBusyTime => userDriverBusyTime.id
});

export interface State extends EntityState<DriverBusyTime> {
    loading: boolean;
    error?: any;
}

export const initialState: State = adapter.getInitialState({
    loading: false,
    error: null
});
