<ion-button class="notifications" *ngIf="count$ | async as count" (click)="onOpen()">
    <div class="count">{{ count }}</div>
    <ion-icon slot="icon-only" name="notifications-outline"></ion-icon>
</ion-button>

<ion-modal #modal>
    <ng-template>
        <ion-header>
            <ion-toolbar color="secondary">
                <ion-title>Notifications</ion-title>
                <ion-buttons slot="end">
                    <ion-button (click)="onClose()">
                        <i class="material-symbols-outlined">close</i>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>
        <ion-content fullscreen>
            <ion-list>
                <ng-container *ngFor="let kv of notifications">
                    <ng-container *ngIf="kv.value | async as appNotifications">
                        <ng-container *ngIf="appNotifications.length > 0">
                            <ion-item-divider [sticky]="true" mode="md">
                                <ion-label>
                                    {{ kv.key }}
                                </ion-label>
                            </ion-item-divider>

                            <ion-item *ngFor="let notification of kv.value | async" [detail]="!!notification.route" [class]="kv.key.toLowerCase().split(' ').join('-')"
                                (click)="onClick(notification)">
                                <ng-container *ngIf="notification.icon; else photo">
                                    <i slot="start" *ngIf="notification.icon.material; else icon" class="material-symbols-outlined" matPrefix>
                                        {{ notification.icon.material }}
                                    </i>
                                    <ng-template #icon>
                                        <ion-icon slot="start" [src]="'/assets/svg/' + notification.icon.custom + '.svg'"></ion-icon>
                                    </ng-template>
                                </ng-container>
                                <ng-template #photo>
                                    <ion-avatar *ngIf="!displayDefaultPhoto.get(notification.id); else defaultPhoto" slot="start">
                                        <img [src]="notification.photo" (error)="onPhotoError(notification.id)" referrerpolicy="no-referrer">
                                    </ion-avatar>
                                    <ng-template #defaultPhoto>
                                        <mat-icon slot="start">person</mat-icon>
                                    </ng-template>
                                </ng-template>

                                <ion-label>
                                    <div class="title" [innerHTML]="notification.title"></div>
                                    <div *ngFor="let message of notification.messages" [class]="message.class ?? ''">
                                        <ion-icon *ngIf="message.icon" [name]="message.icon" class="icon"></ion-icon>
                                        <div [class]="message.noEllipsis ? '': 'ellipsis'"><span [innerHTML]="message.message"></span></div>
                                    </div>
                                </ion-label>

                                <div *ngIf="notification.cost" class="cost success" slot="end">
                                    <span>$</span>{{ notification.cost | number:'1.2-2' }}
                                </div>
                            </ion-item>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </ion-list>
        </ion-content>
    </ng-template>
</ion-modal>
