import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Driver } from '@models/driver.model';

export const adapter: EntityAdapter<Driver> = createEntityAdapter<Driver>({
    selectId: driver => driver.id
});

export interface State extends EntityState<Driver> {
    loading: boolean;
    error?: any;
}

export const initialState: State = adapter.getInitialState({
    loading: false,
    error: null
});
