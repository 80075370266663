import { adapter, initialState, State } from './state';
import { createReducer, on, Action } from '@ngrx/store';
import { getGasPriceFailure, getGasPriceRequest, getGasPriceSuccess } from './actions';

export const gasPriceReducer = createReducer(
    initialState,
    on(
        getGasPriceRequest,
        state => ({
            ...state,
            loading: true,
            error: null
        })
    ),
    on(
        getGasPriceFailure,
        (state, { error }) => ({
            ...state,
            loading: false,
            error
        })
    ),

    // Get Gas Price
    on(
        getGasPriceSuccess,
        (state, { gasPrice }) => adapter.upsertOne(gasPrice, {
            ...state,
            loading: false,
            error: null
        })
    )
);

export function reducer(state: State, action: Action) {
    return gasPriceReducer(state, action);
}
