<ng-container [formGroup]="form">
    <mat-checkbox formControlName="value" color="primary">
        <p>
            To ensure you receive crucial job status notifications via automated text messages,
            it is necessary for you to actively opt-in to receive these messages.
        </p>
        <p>
            By providing your phone number<ng-container *ngIf="isRegister">
                and selecting <strong [class.uppercase]="!isIOS">Continue</strong>
            </ng-container>, you are consenting to the terms outlined in Drive & Tow's mobile
            <a href="javascript:() => {}" (click)="onOpenTermsOfUse($event)">Terms of Use</a>.
            <ng-container *ngIf="isRegister">Message preferences can be changed on your account page.</ng-container>
        </p>
        <p>
            Standard message and data rates may apply. All data obtained during this process is handled in accordance with our
            <a href="javascript:() => {}" (click)="onOpenPrivacyPolicy($event)">Privacy Policy</a>
        </p>
    </mat-checkbox>
</ng-container>
