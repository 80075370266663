import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { adapter, State } from './state';
import { Earning } from '@models/earning.model';

export const getState: MemoizedSelector<object, State> = createFeatureSelector<State>('earning');

export const getLoading: MemoizedSelector<object, boolean> = createSelector(getState, (state: State) => state.loading);

export const getError: MemoizedSelector<object, string> = createSelector(getState, (state: State) => state.error);

export const getTotal: MemoizedSelector<object, number> = createSelector(getState, (state: State) => state.total);

export const getEarnings: MemoizedSelector<object, Earning[]> = createSelector(getState, adapter.getSelectors().selectAll);

export const getTotalEarnings: MemoizedSelector<object, number> = createSelector(getState, (state: State) => {
    const earnings = Object.values(state.entities) as Earning[];
    const total = earnings.reduce((n, x) => n + x.total, 0);
    return total;
});
