import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { adapter, State } from './state';
import { Rate } from '@models/rate.model';

export const getState: MemoizedSelector<object, State> = createFeatureSelector<State>('rate');

export const getLoading: MemoizedSelector<object, boolean> = createSelector(getState, (state: State) => state.loading);

export const getError: MemoizedSelector<object, string> = createSelector(getState, (state: State) => state.error);

export const getTotal: MemoizedSelector<object, number> = createSelector(getState, (state: State) => state.total);

export const getRates: MemoizedSelector<object, Rate[]> = createSelector(getState, adapter.getSelectors().selectAll);
