import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { State } from './state';
import { TowRequest } from '@models/tow-request.model';
import { TowRequestStatus } from '@enums/tow-request-status.enum';
import { DateHelper } from '@classes/date-helper';

export const ACTIVE_TOW_REQUEST_STATUSES = [TowRequestStatus.Pending, TowRequestStatus.Accepted, TowRequestStatus.Declined,
    TowRequestStatus.Approved, TowRequestStatus.Rejected, TowRequestStatus.Available];
export const TOW_REQUEST_IN_PROGRESS_STATUSES = [TowRequestStatus.Pending, TowRequestStatus.Accepted, TowRequestStatus.Approved];
export const TRAILER_EMITTED_TOW_REQUEST_STATUSES = [TowRequestStatus.Pending, TowRequestStatus.Approved,
    TowRequestStatus.Rejected];

const removeExpiredTowRequests = (towRequests: TowRequest[]): TowRequest[] => {
    // Keep the ones that are in 'Approved' status as those are still in route
    const approved = towRequests.filter(x => x.status === TowRequestStatus.Approved);

    // Remove the rest that are expired
    const nonApproved = towRequests.filter(x => x.status !== TowRequestStatus.Approved);
    const nonExpired = nonApproved.filter(x => DateHelper.compareDates(x.tow.datetime, '>=', new Date()));

    const activeTowRequests = approved.concat(nonExpired);
    return activeTowRequests;
};

export const getState: MemoizedSelector<object, State> = createFeatureSelector<State>('towRequest');

export const getTotal: MemoizedSelector<object, number> = createSelector(getState, (state: State) => state.total as number);

export const getLoading: MemoizedSelector<object, boolean> = createSelector(getState, (state: State) => state.loading);

export const getError: MemoizedSelector<object, string> = createSelector(getState, (state: State) => state.error);

export const getTowRequests = (towId: string) => createSelector(getState, (state: State) => {
    const allTowRequests = Object.values(state.entities) as TowRequest[];
    const towRequests = allTowRequests.filter(x => x.tow.id === towId);
    return towRequests;
});

export const getTowRequest = (id: string) => createSelector(getState, (state: State) => state.entities[id] as TowRequest);

export const getTowRequestByUserId = (userId: string) => createSelector(getState, (state: State) => {
    const towRequests = Object.values(state.entities) as TowRequest[];
    const towRequest = towRequests.find(x => x.driverDetails.user.id === userId);
    return towRequest;
});

export const getApprovedTowRequest = (towId: string) => createSelector(getState, (state: State) => {
    const towRequests = Object.values(state.entities) as TowRequest[];
    const towRequest = towRequests.find(x => x.tow.id === towId && x.status === TowRequestStatus.Approved);
    return towRequest;
});

export const getApprovedTowRequestDriverDetails = (towId: string) => createSelector(getState, (state: State) => {
    const towRequests = Object.values(state.entities) as TowRequest[];
    const towRequest = towRequests.find(x => x.tow.id === towId && x.status === TowRequestStatus.Approved);
    let driverDetails = towRequest?.driverDetails;
    if (driverDetails) {
        driverDetails = { ...driverDetails, approved: true };
    }

    return driverDetails;
});

export const getTowRequestsInProgress = (userId: string) => createSelector(getState, (state: State) => {
    const towRequests = Object.values(state.entities) as TowRequest[];
    const towRequestsInProgress = towRequests.filter(x => x.driverDetails.user.id === userId &&
        TOW_REQUEST_IN_PROGRESS_STATUSES.includes(x.status));
    const activeTowRequests = removeExpiredTowRequests(towRequestsInProgress);
    return activeTowRequests;
});

export const getArchivedTowRequests = (userId: string) => createSelector(getState, (state: State) => {
    const towRequests = Object.values(state.entities) as TowRequest[];
    const archivedTowRequests = towRequests.filter(x => x.driverDetails.user.id === userId &&
        x.status === TowRequestStatus.Archived);
    const activeTowRequests = removeExpiredTowRequests(archivedTowRequests);
    return activeTowRequests;
});

export const getAvailableTowRequests = (userId: string) => createSelector(getState, (state: State) => {
    const towRequests = Object.values(state.entities) as TowRequest[];
    const availableTowRequests = towRequests.filter(x => x.driverDetails.user.id === userId &&
        x.status === TowRequestStatus.Available);
    const activeTowRequests = removeExpiredTowRequests(availableTowRequests);
    return activeTowRequests;
});

export const getCanceledTowRequests = (userId: string) => createSelector(getState, (state: State) => {
    const towRequests = Object.values(state.entities) as TowRequest[];
    const canceledTowRequests = towRequests.filter(x => x.driverDetails.user.id === userId &&
        x.status === TowRequestStatus.Canceled);
    const activeTowRequests = removeExpiredTowRequests(canceledTowRequests);
    return activeTowRequests;
});

export const getApprovedTowRequests = (userId: string) => createSelector(getState, (state: State) => {
    const towRequests = Object.values(state.entities) as TowRequest[];
    const approvedTowRequests = towRequests.filter(x => x.driverDetails.user.id === userId &&
        x.status === TowRequestStatus.Approved);
    const activeTowRequests = removeExpiredTowRequests(approvedTowRequests);
    return activeTowRequests;
});
