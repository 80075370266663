export class Cache {
    private static cache = new Map<string, any>();

    static add<T>(key: string, data: T): void {
        this.cache.set(key, data);
    }

    static get<T>(key: string): T {
        return this.cache.get(key);
    }
}
