<h2>{{ tow.trailer.type === 'Cargo' ? 'Cargo Delivered' : 'Tow Completed' }}</h2>

<ion-icon [name]="'checkmark-circle' + (tow.status < 7 ? '-outline' : '')" color="success"></ion-icon>

<ng-container *ngIf="tow.status < 7; else total">
    Waiting for {{ tow.user.firstName }} to rate and tip...
    <app-send-reminder (sendReminder)="onSendReminder()"></app-send-reminder>
    <div class="link" (click)="onClose()">Close</div>
</ng-container>
<ng-template #total>
    <app-tow-total [subtotal]="tow.payment!.payment" [tip]="tow.payment!.tip - tow.payment!.tipCreditCardFee"></app-tow-total>
    <ion-button expand="block" (click)="onClose()">Close</ion-button>
</ng-template>
