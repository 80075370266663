import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { DataService } from '@services/data.service';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as actions from './actions';

@Injectable()
export class DataStoreEffects {
    constructor(
        private actions$: Actions,
        private dataService: DataService
    ) {}

    // Get Years
    getYears$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getAllowedVehicleYearsRequest),
            switchMap(() => this.dataService.getAllowedVehicleYears().pipe(
                map(years => actions.getAllowedVehicleYearsSuccess({ years })),
                catchError(error => of(actions.getAllowedVehicleYearsFailure({ error })))
            ))
        )
    );
}
