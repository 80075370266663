import { ChangeDetectionStrategy, Component } from '@angular/core';
import { environment } from '@environments/environment';

@Component({
    selector: 'app-no-rating',
    templateUrl: './no-rating.component.html',
    styleUrls: ['./no-rating.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoRatingComponent {
    rating = environment.rating;
}
