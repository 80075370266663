import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Support } from '@models/support.model';

export const adapter: EntityAdapter<Support> = createEntityAdapter<Support>({
    selectId: support => support.id
});

export interface State extends EntityState<Support> {
    loading: boolean;
    error?: any;
    total: number;
}

export const initialState: State = adapter.getInitialState({
    loading: false,
    error: null,
    total: 0
});
