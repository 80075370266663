import { Injectable } from '@angular/core';
import { UserRatings } from '@models/user-ratings.model';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { RatingService } from '@services/rating.service';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as actions from './actions';

@Injectable()
export class UserRatingsStoreEffects {
    constructor(
        private actions$: Actions,
        private ratingService: RatingService
    ) {}

    // Get User Ratings
    getUserRatings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getUserRatingsRequest),
            switchMap(action => this.ratingService.getRatings(action.userId, action.ratingType).pipe(
                map(ratings => {
                    const userRatings: UserRatings = {
                        userId: action.userId,
                        ratings
                    };
                    return actions.getUserRatingsSuccess({ userRatings });
                }),
                catchError(error => of(actions.getUserRatingsFailure({ error })))
            ))
        )
    );
}
