import { InsuranceCoverage } from '@models/insurance-coverage.model';
import { Trailer } from '@models/trailer.model';

export class TrailerHelper {
    static trailerHasTrailerAndCargoCoverage(insuranceCoverages: InsuranceCoverage[], trailer: Trailer): boolean {
        const coversTrailerAndCargo = insuranceCoverages.filter(x => x.coversTrailerAndCargo).map(x => x.id);
        const intersection = trailer.insuranceCoverages?.filter(x => coversTrailerAndCargo.includes(x)) ?? [];
        const hasTrailerAndCargoCoverage = intersection.length === coversTrailerAndCargo.length;
        return hasTrailerAndCargoCoverage;
    }
}
