import { adapter, ID, initialState, State } from './state';
import { createReducer, on, Action } from '@ngrx/store';
import { getAllowedVehicleYearsFailure, getAllowedVehicleYearsRequest, getAllowedVehicleYearsSuccess } from './actions';
import { ObjectHelper } from '@classes/object-helper';
import { Data } from '@models/data.model';

export const dataReducer = createReducer(
    initialState,
    on(
        getAllowedVehicleYearsRequest,
        state => ({
            ...state,
            loading: true,
            error: null
        })
    ),
    on(
        getAllowedVehicleYearsFailure,
        (state, { error }) => ({
            ...state,
            loading: false,
            error
        })
    ),

    // Get Allowed Vehicle Years
    on(
        getAllowedVehicleYearsSuccess,
        (state, { years }) => {
            const oldData = ObjectHelper.cloneObject(state.entities[ID]) as Data;
            const data: Data = {
                ...oldData,
                years
            };

            return adapter.upsertOne(data, {
                ...state,
                loading: false,
                error: null
            });
        }
    )
);

export function reducer(state: State, action: Action) {
    return dataReducer(state, action);
}
