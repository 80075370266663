import { adapter, initialState, State } from './state';
import { createReducer, on, Action } from '@ngrx/store';
import { clearVehicles, decodeVINFailure, decodeVINRequest, decodeVINSuccess, deleteVehicleFailure, deleteVehicleRequest,
    deleteVehicleSuccess, getActiveVehicleFailure, getActiveVehicleRequest, getActiveVehiclesFailure, getActiveVehiclesRequest,
    getActiveVehiclesSuccess, getActiveVehicleSuccess, getVehicleFailure, getVehicleRequest, getVehiclesFailure, getVehiclesRequest,
    getVehiclesSuccess, getVehicleSuccess, isVINFoundFailure, isVINFoundRequest, isVINFoundSuccess, saveVehicleFailure,
    saveVehicleRequest, saveVehicleSuccess, updateVehiclesFailure, updateVehiclesRequest, updateVehiclesSuccess } from './actions';

export const vehicleReducer = createReducer(
    initialState,
    on(
        getVehiclesRequest,
        getVehicleRequest,
        getActiveVehiclesRequest,
        getActiveVehicleRequest,
        saveVehicleRequest,
        deleteVehicleRequest,
        updateVehiclesRequest,
        decodeVINRequest,
        isVINFoundRequest,
        state => ({
            ...state,
            loading: true,
            error: null
        })
    ),
    on(
        getVehiclesFailure,
        getVehicleFailure,
        getActiveVehiclesFailure,
        getActiveVehicleFailure,
        saveVehicleFailure,
        deleteVehicleFailure,
        updateVehiclesFailure,
        decodeVINFailure,
        isVINFoundFailure,
        (state, { error }) => ({
            ...state,
            loading: false,
            error
        })
    ),

    // Get/Get Active Vehicles
    on(
        getVehiclesSuccess,
        getActiveVehiclesSuccess,
        (state, { vehicles }) => adapter.upsertMany(vehicles, {
            ...state,
            loading: false,
            error: null,
            total: vehicles.length
        })
    ),

    // Get/Get Active/Save Vehicle
    on(
        getVehicleSuccess,
        getActiveVehicleSuccess,
        saveVehicleSuccess,
        (state, { vehicle }) => adapter.upsertOne(vehicle, {
            ...state,
            loading: false,
            error: null
        })
    ),

    // Delete Vehicle
    on(
        deleteVehicleSuccess,
        (state, { id }) => adapter.removeOne(id, {
            ...state,
            loading: false,
            error: null
        })
    ),

    // Clear Vehicles
    on(
        clearVehicles,
        (state) => adapter.removeAll({
            ...state,
            loading: false,
            error: null
        })
    ),

    // Update Vehicles
    on(
        updateVehiclesSuccess,
        (state, { vehicles }) => adapter.upsertMany(vehicles, {
            ...state,
            loading: false,
            error: null
        })
    ),

    // Decode VIN
    on(
        decodeVINSuccess,
        isVINFoundSuccess,
        state => ({
            ...state,
            loading: false,
            error: null
        })
    ),
);

export function reducer(state: State, action: Action) {
    return vehicleReducer(state, action);
}
