import { SMS } from '@models/sms.model';
import { createAction, props } from '@ngrx/store';

// Send SMS
export const sendSMSRequest = createAction(
    '[SMS] Send SMS Request',
    props<{ sms: SMS }>()
);
export const sendSMSFailure = createAction(
    '[SMS] Send SMS Failure',
    props<{ error: string }>()
);
export const sendSMSSuccess = createAction(
    '[SMS] Send SMS Success',
    props<{ sms: SMS }>()
);
