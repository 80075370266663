import { UserRole } from '@enums/user-role.enum';
import { GeoData } from '@models/geo-data.model';
import { User } from '@models/user.model';
import { createAction, props } from '@ngrx/store';

// Get Users
export const getUsersRequest = createAction(
    '[User] Get Users Request',
    props<{ center: GeoData, radius: number, excludeId?: string, includeDriverProfile?: boolean }>()
);
export const getUsersFailure = createAction(
    '[User] Get Users Failure',
    props<{ error: string }>()
);
export const getUsersSuccess = createAction(
    '[User] Get Users Success',
    props<{ users: User[] }>()
);

// Get User
export const getUserRequest = createAction(
    '[User] Get User Request',
    props<{ id: string }>()
);
export const getUserFailure = createAction(
    '[User] Get User Failure',
    props<{ error: string }>()
);
export const getUserSuccess = createAction(
    '[User] Get User Success',
    props<{ user: User }>()
);

// Save User
export const saveUserRequest = createAction(
    '[User] Save User Request',
    props<{ user: User }>()
);
export const saveUserFailure = createAction(
    '[User] Save User Failure',
    props<{ error: string }>()
);
export const saveUserSuccess = createAction(
    '[User] Save User Success',
    props<{ user: User }>()
);

// Set User Current Location
export const setUserCurrentLocationRequest = createAction(
    '[User] Set User Current Location Request',
    props<{ user: User }>()
);
export const setUserCurrentLocationFailure = createAction(
    '[User] Set User Current Location Failure',
    props<{ error: string }>()
);
export const setUserCurrentLocationSuccess = createAction(
    '[User] Set User Current Location Success',
    props<{ user: User }>()
);

// Update User Properties
export const updateUserPropertiesRequest = createAction(
    '[User] Update User Properties Request',
    props<{ id: string, properties: Partial<User> }>()
);
export const updateUserPropertiesFailure = createAction(
    '[User] Update User Properties Failure',
    props<{ error: string }>()
);
export const updateUserPropertiesSuccess = createAction(
    '[User] Update User Properties Success',
    props<{ partialUser: Partial<User> }>()
);

// Clear User
export const clearUser = createAction(
    '[User] Clear User'
);

// Get User Changes
export const getUserChangesRequest = createAction(
    '[User] Get User Changes Request',
    props<{ id: string }>()
);
export const getUserChangesLoaded = createAction(
    '[Route] Get User Changes Loaded'
);
export const getUserChangesSuccess = createAction(
    '[User] Get User Changes Success',
    props<{ user: User }>()
);

// Unsubscribe User Changes
export const unsubscribeUserChangesRequest = createAction(
    '[User] Unsubscribe User Changes Request'
);
export const unsubscribeUserChangesSuccess = createAction(
    '[User] Unsubscribe User Changes Success'
);

// Get User by Role
export const getUsersByRoleRequest = createAction(
    '[User] Get Users by Role Request',
    props<{ role: UserRole }>()
);
export const getUsersByRoleFailure = createAction(
    '[User] Get Users by Role Failure',
    props<{ error: string }>()
);
export const getUsersByRoleSuccess = createAction(
    '[User] Get Users by Role Success',
    props<{ users: User[] }>()
);
