import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router, RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { RootStoreModule } from './root-store/root-store.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { createErrorHandler, TraceService } from '@sentry/angular';
import { provideNgxMask } from 'ngx-mask';
import '@stripe/stripe-js'; // This will reference the Stripe JS in the app

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        RootStoreModule,
        BrowserModule,
        IonicModule.forRoot({
            innerHTMLTemplatesEnabled: true
        }),
        AppRoutingModule,
        CoreModule,
        SharedModule,
        BrowserAnimationsModule,
        ServiceWorkerModule.register('combined-sw.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the application is stable or after 30 seconds (whichever comes first)
            registrationStrategy: 'registerWhenStable:30000'
        })
    ],
    providers: [{
        provide: RouteReuseStrategy, useClass: IonicRouteStrategy
    }, {
        provide: ErrorHandler, useValue: createErrorHandler()
    }, {
        provide: TraceService,
        deps: [Router]
    }, {
        provide: APP_INITIALIZER,
        useFactory: () => () => {},
        deps: [TraceService],
        multi: true
    }, provideNgxMask()],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {}
