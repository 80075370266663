import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { adapter, State } from './state';
import { Transaction } from '@models/transaction.model';
import { TransactionType } from '@enums/transaction-type.enum';
import { TransactionStatus } from '@enums/transaction-status.enum';

export const getState: MemoizedSelector<object, State> = createFeatureSelector<State>('transaction');

export const getLoading: MemoizedSelector<object, boolean> = createSelector(getState, (state: State) => state.loading);

export const getError: MemoizedSelector<object, string> = createSelector(getState, (state: State) => state.error);

export const getTotal: MemoizedSelector<object, number> = createSelector(getState, (state: State) => state.total);

export const getTransactions: MemoizedSelector<object, Transaction[]> = createSelector(getState, adapter.getSelectors().selectAll);

export const getTransaction = (userId: string, towId: string, type: TransactionType, status: TransactionStatus) =>
    createSelector(getState, (state: State) => {
        const transactions = Object.values(state.entities) as unknown as Transaction[];
        const transaction = transactions.find(x => x.userId === userId && x.towId === towId && x.type === type &&
            x.status === status);
        return transaction;
    });
