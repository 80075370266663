import { adapter, initialState, State } from './state';
import { createReducer, on, Action } from '@ngrx/store';
import { createPayoutFailure, createPayoutRequest, createPayoutSuccess, getPayoutFailure, getPayoutRequest,
    getPayoutSuccess } from './actions';

export const payoutReducer = createReducer(
    initialState,
    on(
        getPayoutRequest,
        createPayoutRequest,
        state => ({
            ...state,
            loading: true,
            error: null
        })
    ),
    on(
        getPayoutFailure,
        createPayoutFailure,
        (state, { error }) => ({
            ...state,
            loading: false,
            error
        })
    ),

    // Get Payout
    on(
        getPayoutSuccess,
        (state, { payout }) => adapter.upsertOne(payout, {
            ...state,
            loading: false,
            error: null
        })
    ),

    // Create Payout
    on(
        createPayoutSuccess,
        (state, { payout }) => adapter.upsertOne(payout, {
            ...state,
            loading: false,
            error: null
        })
    )
);

export function reducer(state: State, action: Action) {
    return payoutReducer(state, action);
}
