import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { SubSink } from 'subsink';
import { PrivacyPolicyComponent } from '../privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from '../terms-of-use/terms-of-use.component';
import { UtilityHelper } from '@classes/utility-helper';

@Component({
    selector: 'app-sms-opt-in',
    templateUrl: './sms-opt-in.component.html',
    styleUrls: ['./sms-opt-in.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        // eslint-disable-next-line no-use-before-define
        useExisting: forwardRef(() => SmsOptInComponent),
        multi: true
    }]
})
export class SmsOptInComponent implements OnInit, OnDestroy, ControlValueAccessor {
    @Input() isRegister = false;
    @Input() value: boolean | null = false;

    private subs = new SubSink();
    private touched = false;

    form!: FormGroup;
    isIOS = UtilityHelper.isIOS();

    constructor(
        private formBuilder: FormBuilder,
        private modalController: ModalController
    ) {}

    onTouched = () => {};

    onChange = () => {};

    writeValue = () => {};

    registerOnChange(fn: any): void {
        this.subs.add(
            this.form.get('value')?.valueChanges.subscribe(value => {
                this.value = value;
                fn(value);
            })
        );
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    markAsTouched() {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        }
    }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            value: new FormControl<boolean>(!!this.value)
        });
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    async onOpenTermsOfUse(event: any): Promise<void> {
        event.stopPropagation();
        const modal = await this.modalController.create({
            component: TermsOfUseComponent
        });
        return await modal.present();
    }

    async onOpenPrivacyPolicy(event: any): Promise<void> {
        event.stopPropagation();
        const modal = await this.modalController.create({
            component: PrivacyPolicyComponent
        });
        return await modal.present();
    }
}
