import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { InsuranceCoverage } from '@models/insurance-coverage.model';
import { Vehicle } from '@models/vehicle.model';
import { DataService } from '@services/data.service';
import { map, take } from 'rxjs';

@Component({
    selector: 'app-vehicle-policy',
    templateUrl: './vehicle-policy.component.html',
    styleUrls: ['./vehicle-policy.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VehiclePolicyComponent implements OnChanges {
    @Input() vehicle!: Vehicle;

    private message = '';

    constructor(
        private alertController: AlertController,
        private dataService: DataService
    ) {}

    ngOnChanges(): void {
        if (!this.vehicle?.insuranceCoverages) {
            return;
        }

        this.dataService.getVehicleInsuranceCoverages().pipe(
            map(insuranceCoverages => insuranceCoverages.filter(x => this.vehicle.insuranceCoverages.includes(x.id))),
            take(1)
        ).subscribe(insuranceCoverages => this.setMessage(insuranceCoverages));
    }

    async onViewDriverPolicy(): Promise<void> {
        const alert = await this.alertController.create({
            header: 'Driver\'s Policy',
            message: this.message,
            buttons: ['Close']
        });
        await alert.present();
    }

    private setMessage(insuranceCoverages: InsuranceCoverage[]): void {
        insuranceCoverages.forEach(insuranceCoverage => {
            this.message += `<p class="align-left">
                <strong>${insuranceCoverage.name}</strong><br>
                ${insuranceCoverage.description}
            </p>`;
        });
    }
}
