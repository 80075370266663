import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as actions from './actions';
import { DisbursementService } from '@services/disbursement.service';

@Injectable()
export class DisbursementStoreEffects {
    constructor(
        private actions$: Actions,
        private disbursementService: DisbursementService
    ) {}

    // Save Disbursement
    saveDisbursement$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.saveDisbursementRequest),
            switchMap(action => this.disbursementService.saveDisbursement(action.disbursement).pipe(
                map(disbursement => actions.saveDisbursementSuccess({ disbursement })),
                catchError(error => of(actions.saveDisbursementFailure({ error })))
            ))
        )
    );

    // Get Disbursements Total
    getDisbursementsTotal$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getDisbursementsTotalRequest),
            switchMap(action => this.disbursementService.getDisbursementsTotal(action.userId, action.paid).pipe(
                map(total => actions.getDisbursementsTotalSuccess({ total })),
                catchError(error => of(actions.getDisbursementsTotalFailure({ error })))
            ))
        )
    );
}
