/* eslint-disable max-params */
import { RateType } from '@enums/rate-type.enum';
import { TowPayment } from '@models/tow-payment.model';
import { Rate } from '@models/rate.model';
import { UtilityHelper } from './utility-helper';
import { GasPrice } from '@models/gas-price.model';
import { environment } from '@environments/environment';
import { TimeSurgeCost } from '@models/time-surge-cost.model';
import { DateHelper } from './date-helper';
import { TowCost } from '@models/commute-cost.model';
import { Trailer } from '@models/trailer.model';

export class CostHelper {
    static getCostPerMile(rates: Rate[], gasPrice: GasPrice, cost: number): number {
        const towRate = rates.find(x => x.type === RateType.Tow)!;
        const baseCostPerMile = gasPrice.gasoline * towRate.cost;
        const increment = baseCostPerMile * cost;
        const costPerMile = baseCostPerMile + increment;
        return costPerMile;
    }

    static calculateTotal(towPayment: TowPayment, rates: Rate[]): TowPayment {
        const commission = this.getCommision(towPayment.subtotal, rates);
        const tipCreditCardFee = this.getCreditCardFee(towPayment.tip);
        towPayment = { ...towPayment, commission, tip: towPayment.tip, tipCreditCardFee };
        return towPayment;
    }

    static getPayment(commuteDistance: number, towDistance: number, rates: Rate[], gasPrice: GasPrice, duration: number,
        datetime: Date, timeSurgeCosts: TimeSurgeCost[], towCosts: TowCost[], trailer: Trailer): TowPayment {
        const subtotal = this.getSubtotal(commuteDistance, towDistance, rates, gasPrice, duration, datetime, timeSurgeCosts,
            towCosts, trailer);
        const serviceFee = rates.find(x => x.type === RateType.Service)!.cost;
        const cost = subtotal + serviceFee;
        const commission = this.getCommision(subtotal, rates);
        const payment = UtilityHelper.getRoundedDecimal(subtotal - commission);
        const creditCardFee = this.getCreditCardFee(cost);
        const towPayment: TowPayment = {
            subtotal,
            cost,
            payment,
            commission,
            serviceFee,
            creditCardFee,
            tip: 0,
            tipCreditCardFee: 0
        };
        return towPayment;
    }

    private static getSubtotal(commuteDistance: number, towDistance: number, rates: Rate[], gasPrice: GasPrice, duration: number,
        datetime: Date, timeSurgeCosts: TimeSurgeCost[], towCosts: TowCost[], trailer: Trailer): number {
        const commuteCost = this.getCost(commuteDistance, 'commute', rates, gasPrice, towCosts, trailer);
        const towCost = this.getCost(towDistance, 'tow', rates, gasPrice, towCosts, trailer);
        const timeCost = this.getTimeCost(rates, duration, datetime, timeSurgeCosts);
        const sum = commuteCost + towCost + timeCost;
        const subtotal = UtilityHelper.getRoundedDecimal(sum);
        return subtotal;
    }

    private static getCost(distance: number, type: 'commute' | 'tow', rates: Rate[], gasPrice: GasPrice,
        towCosts: TowCost[], trailer: Trailer): number {
        const commuteRate = rates.find(x => x.type === RateType.Commute)!;
        const towRate = rates.find(x => x.type === RateType.Tow)!;
        const distanceCost = type === 'commute' ? commuteRate.cost : towRate.cost;
        const vehicleRate = rates.find(x => x.type === RateType.Vehicle)!;
        const baseCostPerMile = gasPrice.gasoline * distanceCost;
        const costPerMile = type === 'tow' ? this.getTowCostPerMile(baseCostPerMile, towCosts, trailer) : baseCostPerMile;
        const cost = distance * costPerMile * vehicleRate.cost * environment.gasProfitMultiplier;
        const rounded = UtilityHelper.getRoundedDecimal(cost);
        return rounded;
    }

    private static getTowCostPerMile(baseCostPerMile: number, towCosts: TowCost[], trailer: Trailer): number {
        let costPerMile = baseCostPerMile;

        if (!towCosts || !trailer) { // Failsafe just in case
            return costPerMile;
        }

        if (trailer.type === 'Cargo') {
            const towCost = towCosts.find(x => x.minWeight === 0)!;
            costPerMile += baseCostPerMile * towCost.cost;
            return costPerMile;
        }

        for (const towCost of towCosts) {
            if (trailer?.weight >= towCost.minWeight) {
                costPerMile += baseCostPerMile * towCost.cost;
                return costPerMile;
            }
        }

        return costPerMile;
    }

    private static getCommision(cost: number, rates: Rate[]): number {
        const commissionRate = rates.find(x => x.type === RateType.Commission)!;
        const commission = UtilityHelper.getRoundedDecimal(cost * commissionRate.cost);
        return commission;
    }

    private static getCreditCardFee(amount: number): number {
        const creditCardPercentageFee = amount * environment.creditCard.percentageFee / 100;
        const creditCardFee = UtilityHelper.getRoundedDecimal(creditCardPercentageFee + environment.creditCard.flatFee);
        return creditCardFee;
    }

    private static getTimeCost(rates: Rate[], duration: number, datetime: Date, timeSurgeCosts: TimeSurgeCost[]): number {
        const minutes = DateHelper.convertSecondsToMinutes(duration);
        const timeCost = rates.find(x => x.type === RateType.Time)!.cost * minutes;
        const timeSurgeCost = this.getTimeSurgeCost(timeCost, datetime, timeSurgeCosts);
        return timeCost + timeSurgeCost;
    }

    private static getTimeSurgeCost(timeCost: number, datetime: Date, timeSurgeCosts: TimeSurgeCost[]): number {
        if (!datetime) {
            return timeCost;
        }

        let cost = 0;
        for (const timeSurgeCost of timeSurgeCosts) {
            const start = DateHelper.setTime(datetime, timeSurgeCost.from);
            const end = DateHelper.setTime(datetime, timeSurgeCost.to);
            const isAfterStart = DateHelper.compareDates(datetime, '>=', start);
            const isBeforeEnd = DateHelper.compareDates(datetime, '<', end);
            if (isAfterStart && isBeforeEnd) {
                cost = timeSurgeCost.cost;
                break;
            }
        }

        return timeCost * cost;
    }
}
