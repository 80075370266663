import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as actions from './actions';
import { VehicleService } from '@services/vehicle.service';

@Injectable()
export class VehicleStoreEffects {
    constructor(
        private actions$: Actions,
        private vehicleService: VehicleService
    ) {}

    // Get Vehicles
    getVehicles$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getVehiclesRequest),
            switchMap(action => this.vehicleService.getVehicles(action.userId).pipe(
                map(vehicles => actions.getVehiclesSuccess({ vehicles })),
                catchError(error => of(actions.getVehiclesFailure({ error })))
            ))
        )
    );

    // Get Vehicle
    getVehicle$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getVehicleRequest),
            switchMap(action => this.vehicleService.getVehicle(action.id).pipe(
                map(vehicle => actions.getVehicleSuccess({ vehicle })),
                catchError(error => of(actions.getVehicleFailure({ error })))
            ))
        )
    );

    // Get Active Vehicles
    getActiveVehicles$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getActiveVehiclesRequest),
            switchMap(action => this.vehicleService.getActiveVehicles(action.userIds).pipe(
                map(vehicles => actions.getActiveVehiclesSuccess({ vehicles })),
                catchError(error => of(actions.getActiveVehiclesFailure({ error })))
            ))
        )
    );

    // Get Active Vehicle
    getActiveVehicle$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getActiveVehicleRequest),
            switchMap(action => this.vehicleService.getActiveVehicle(action.userId).pipe(
                map(vehicle => actions.getActiveVehicleSuccess({ vehicle })),
                catchError(error => of(actions.getActiveVehicleFailure({ error })))
            ))
        )
    );

    // Save Vehicle
    saveVehicle$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.saveVehicleRequest),
            switchMap(action => this.vehicleService.saveVehicle(action.vehicle).pipe(
                map(vehicle => actions.saveVehicleSuccess({ vehicle })),
                catchError(error => of(actions.saveVehicleFailure({ error })))
            ))
        )
    );

    // Delete Vehicle
    deleteVehicle$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.deleteVehicleRequest),
            switchMap(action => this.vehicleService.deleteVehicle(action.vehicle).pipe(
                map(id => actions.deleteVehicleSuccess({ id })),
                catchError(error => of(actions.deleteVehicleFailure({ error })))
            ))
        )
    );

    // Update Vehicles
    updateVehicles$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.updateVehiclesRequest),
            switchMap(action => this.vehicleService.updateVehicles(action.vehicles).pipe(
                map(vehicles => actions.updateVehiclesSuccess({ vehicles })),
                catchError(error => of(actions.updateVehiclesFailure({ error })))
            ))
        )
    );

    // Decode VIN
    decodeVIN$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.decodeVINRequest),
            switchMap(action => this.vehicleService.decodeVIN(action.vin).pipe(
                map(data => actions.decodeVINSuccess({ data })),
                catchError(ex => of(actions.decodeVINFailure({ error: ex.message })))
            ))
        )
    );

    // Si VIN Found
    isVINFound$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.isVINFoundRequest),
            switchMap(action => this.vehicleService.isVINFound(action.vin).pipe(
                map(found => actions.isVINFoundSuccess({ found })),
                catchError(ex => of(actions.isVINFoundFailure({ error: ex.message })))
            ))
        )
    );
}
