import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { PasswordChecklistItem } from '@models/password-checklist-item.model';
import { DataService } from '@services/data.service';
import { Observable, tap } from 'rxjs';

@Component({
    selector: 'app-password-checklist',
    templateUrl: './password-checklist.component.html',
    styleUrls: ['./password-checklist.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PasswordChecklistComponent implements OnInit, OnChanges {
    @Input() password: string | null;

    private checklist: PasswordChecklistItem[] = [];

    checklist$!: Observable<PasswordChecklistItem[]>;
    checked = new Map<string, boolean>();

    constructor(
        private dataService: DataService
    ) {}

    ngOnInit(): void {
        this.checklist$ = this.dataService.getPasswordChecklist().pipe(
            tap(checklist => this.checklist = checklist)
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        // eslint-disable-next-line dot-notation
        const password = changes['password'].currentValue;
        this.checklist.forEach(item => {
            const regex = new RegExp(item.regex);
            const checked = regex.test(password);
            this.checked.set(item.description, checked);
        });
    }
}
