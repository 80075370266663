import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit } from '@angular/core';
import { UtilityHelper } from '@classes/utility-helper';
import { TowRequestStatus } from '@enums/tow-request-status.enum';
import { AlertController, AlertInput, ModalController } from '@ionic/angular';
import { DriverDetails } from '@models/driver-details.model';
import { TowDetails } from '@models/tow-details.model';
import { TowRequest } from '@models/tow-request.model';
import { User } from '@models/user.model';
import { Vehicle } from '@models/vehicle.model';
import { BehaviorSubject } from 'rxjs';
import { VehicleSummaryPipe } from '@pipes/vehicle-summary.pipe';
import { GeoHelper } from '@classes/geo-helper';

@Component({
    selector: 'app-driver-map-full-tow-details',
    templateUrl: './driver-map-full-tow-details.component.html',
    styleUrls: ['./driver-map-full-tow-details.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DriverMapFullTowDetailsComponent implements OnInit {
    @Input() towDetails!: TowDetails;
    @Input() user!: User;
    @Input() vehicle!: Vehicle;
    @Input() rating!: number;
    @Input() vehicles!: Vehicle[];
    @Input() changeTowRequestStatus!: EventEmitter<TowRequest>;
    @Input() updateVehicle!: EventEmitter<Vehicle>;
    @Input() changeVehicle!: EventEmitter<Vehicle>;
    @Input() cancelTowRequest!: EventEmitter<TowRequest>;

    displayDefaultPhoto = false;
    disableActionButton$ = new BehaviorSubject(true);
    isDriverHomeAddress = false;

    constructor(
        private alertController: AlertController,
        private modalController: ModalController,
        private vehicleSummaryPipe: VehicleSummaryPipe
    ) {}

    async ngOnInit(): Promise<void> {
        this.setIsDriverHomeAddress();

        // If there is a tow request and is not pending, stop
        if (this.towDetails.towRequest && this.towDetails.towRequest.status !== TowRequestStatus.Pending) {
            return;
        }

        const canHandleWeight = await this.canHandleWeight();
        const canHandleWeightAndCouplerSize = canHandleWeight && await this.canHandleCouplerSize();
        if (canHandleWeightAndCouplerSize) {
            this.disableActionButton$.next(false);
        }
    }

    onPhotoError(): void {
        this.displayDefaultPhoto = true;
    }

    onClose(): void {
        this.modalController.dismiss();
    }

    async onChangeTowRequestStatus(status: TowRequestStatus) {
        if (status === TowRequestStatus.Accepted) {
            const towRequest = { ...this.towDetails.towRequest!, status: TowRequestStatus.Accepted };
            this.changeTowRequestStatus.emit(towRequest);
            return;
        }

        const alert = await this.alertController.create({
            header: 'Confirm',
            subHeader: 'Are you sure you want to decline this request?',
            buttons: [{
                text: 'No',
                cssClass: 'secondary'
            }, {
                text: 'Yes',
                handler: () => {
                    const towRequest = { ...this.towDetails.towRequest!, status };
                    this.changeTowRequestStatus.emit(towRequest);
                }
            }]
        });
        await alert.present();
    }

    async onAvailable(): Promise<void> {
        const driverDetails = {
            payment: this.towDetails.payment,
            user: this.user,
            vehicle: this.vehicle
        } as DriverDetails;
        const towRequest = {
            tow: this.towDetails.tow,
            driverDetails,
            commute: this.towDetails.distancesDurations.commute,
            status: TowRequestStatus.Available
        } as TowRequest;
        this.changeTowRequestStatus.emit(towRequest);
    }

    async onShowInstructions(): Promise<void> {
        const alert = await this.alertController.create({
            header: 'Instructions',
            message: this.towDetails.tow.instructions,
            buttons: ['OK']
        });
        await alert.present();
    }

    async onCancelTowRequest(): Promise<void> {
        this.cancelTowRequest.emit(this.towDetails.towRequest);
    }

    private async canHandleWeight() {
        const weight = +this.towDetails?.tow.trailer.weight;
        const towingCapacity = +this.vehicle.towingCapacity;
        if (weight <= towingCapacity) {
            return true;
        }

        const subHeader = 'Towing capacity not met.';
        const areOtherVehiclesQualified = await this.areOtherVehiclesQualified(subHeader);
        if (areOtherVehiclesQualified) {
            return false;
        }

        await this.showWeightAlert(weight, towingCapacity, subHeader);
        return false;
    }

    private async showWeightAlert(weight: number, towingCapacity: number, subHeader: string) {
        const weightFormatted = UtilityHelper.formatNumber(weight);
        const towingCapacityFormatted = UtilityHelper.formatNumber(towingCapacity);
        const alert = await this.alertController.create({
            header: 'Warning',
            subHeader,
            message: `Trailer gross weight (${weightFormatted} lbs) exceeds vehicle maximum towing capacity
                (${towingCapacityFormatted} lbs).`,
            buttons: [{
                text: 'OK'
            }]
        });
        await alert.present();
    }

    private async canHandleCouplerSize() {
        const couplerSize = this.towDetails.tow.trailer.couplerSize;
        if (!couplerSize || this.vehicle.couplerSizes.includes(couplerSize)) {
            return true;
        }

        const subHeader = 'Hitch and coupler not compatible.';
        const areOtherVehiclesQualified = await this.areOtherVehiclesQualified(subHeader);
        if (areOtherVehiclesQualified) {
            return false;
        }

        await this.showCouplerSizeAlert(couplerSize, subHeader);
        return false;
    }

    private async areOtherVehiclesQualified(subHeader: string) {
        const weight = +this.towDetails?.tow.trailer.weight;
        let qualifiedVehicles = this.vehicles.filter(x => +x.towingCapacity >= weight);
        const couplerSize = this.towDetails?.tow.trailer.couplerSize;
        if (couplerSize) {
            qualifiedVehicles = qualifiedVehicles.filter(x => x.couplerSizes.includes(couplerSize));
        }

        switch (qualifiedVehicles.length) {
            case 0:
                return false;
            case 1: {
                const vehicle = qualifiedVehicles[0];
                await this.showSingleVehicleAlert(vehicle, weight, subHeader, couplerSize);
                return true;
            }
            default: {
                await this.showMultipleVehicleAlert(qualifiedVehicles, weight, subHeader, couplerSize);
                return true;
            }
        }
    }

    private async showCouplerSizeAlert(couplerSize: string, subHeader: string) {
        const id = 'coupler-size-alert';
        let confirmBtn: HTMLElement | null = null;
        const alert = await this.alertController.create({
            id: 'coupler-size-alert',
            header: 'Warning',
            subHeader,
            message: `You must have a ${couplerSize} hitch ball in order to accept this drive.`,
            inputs: [{
                type: 'checkbox',
                label: `I acknowledge that my vehicle is equipped with a ${couplerSize} hitch size.`,
                value: true,
                handler: chk => {
                    if ((chk as HTMLIonCheckboxElement).checked) {
                        confirmBtn?.removeAttribute('disabled');
                    } else {
                        confirmBtn?.setAttribute('disabled', '');
                    }
                }
            }],
            buttons: [{
                text: 'Cancel',
                role: 'cancel',
                cssClass: 'secondary',
                handler: async () => await this.confirmCancel(() => {
                    this.canHandleCouplerSize();
                })
            }, {
                text: 'Confirm',
                role: 'confirm',
                handler: () => {
                    this.disableActionButton$.next(false);
                    const couplerSizes = this.vehicle.couplerSizes.concat(couplerSize);
                    const vehicle: Vehicle = { ...this.vehicle, couplerSizes };
                    this.updateVehicle.emit(vehicle);
                }
            }]
        });
        await alert.present();
        confirmBtn = document.querySelector(`#${id} .alert-button-role-confirm`);
        confirmBtn?.setAttribute('disabled', '');
    }

    private async showSingleVehicleAlert(vehicle: Vehicle, weight: number, subHeader: string, couplerSize?: string) {
        const vehicleDescription = this.vehicleSummaryPipe.transform(vehicle);
        const weightFormatted = UtilityHelper.formatNumber(weight);
        const alert = await this.alertController.create({
            header: 'Warning',
            subHeader,
            message: `Your other vehicle, <strong>${vehicleDescription}</strong>,
                has the towing capacity to handle the trailer weight
                (${weightFormatted} lbs)${couplerSize ? ` and coupler size (${couplerSize}).` : '.'}
                Change vehicle?`,
            buttons: [{
                text: 'No',
                role: 'cancel',
                cssClass: 'secondary',
                handler: async () => await this.confirmCancel(() => {
                    this.areOtherVehiclesQualified(subHeader);
                })
            }, {
                text: 'Yes',
                handler: () => this.changeVehicle.emit(vehicle)
            }]
        });
        await alert.present();
    }

    private async showMultipleVehicleAlert(qualifiedVehicles: Vehicle[], weight: number, subHeader: string, couplerSize?: string) {
        let confirmBtn: HTMLElement | null = null;
        const weightFormatted = UtilityHelper.formatNumber(weight);
        const inputs: AlertInput[] = [];
        qualifiedVehicles.forEach(vehicle => {
            const input: AlertInput = {
                type: 'radio',
                label: this.vehicleSummaryPipe.transform(vehicle),
                value: vehicle,
                handler: () => {
                    confirmBtn?.removeAttribute('disabled');
                }
            };
            inputs.push(input);
        });

        const id = 'other-vehicles-alert';
        const alert = await this.alertController.create({
            id,
            header: 'Warning',
            subHeader,
            message: `Your other vehicles have the towing capacity to handle the trailer weight
                (${weightFormatted} lbs)${couplerSize ? ` and coupler size (${couplerSize}).` : '.'}
                Please select which vehicle you want to use:`,
            inputs,
            buttons: [{
                text: 'Cancel',
                role: 'cancel',
                cssClass: 'secondary',
                handler: async () => await this.confirmCancel(() => {
                    this.areOtherVehiclesQualified(subHeader);
                })
            }, {
                text: 'Change Vehicle',
                role: 'confirm',
                handler: (vehicle: Vehicle) => this.changeVehicle.emit(vehicle)
            }]
        });
        await alert.present();
        confirmBtn = document.querySelector(`#${id} .alert-button-role-confirm`);
        confirmBtn?.setAttribute('disabled', '');
    }

    private async confirmCancel(fn: Function) {
        const message = this.towDetails.towRequest ?
            'This will decline this request.' :
            'You won\'t be able to tell the trailer that you are available for pickup.';
        const alert = await this.alertController.create({
            header: 'Confirm',
            subHeader: 'Are you sure?',
            message,
            buttons: [{
                text: 'No',
                role: 'cancel',
                cssClass: 'secondary',
                handler: async () => fn()
            }, {
                text: 'Yes',
                handler: () => {
                    if (!this.towDetails.towRequest) {
                        return;
                    }

                    // Decline this tow request
                    const towRequest = { ...this.towDetails.towRequest!, status: TowRequestStatus.Declined };
                    this.changeTowRequestStatus.emit(towRequest);
                }
            }]
        });
        await alert.present();
    }

    private setIsDriverHomeAddress(): void {
        const formatted = GeoHelper.getFormattedAddress(this.user.address);
        this.isDriverHomeAddress = formatted === this.towDetails.start.address;
    }
}
