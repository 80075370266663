import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Transaction } from '@models/transaction.model';

export const adapter: EntityAdapter<Transaction> = createEntityAdapter<Transaction>({
    selectId: transaction => transaction.id
});

export interface State extends EntityState<Transaction> {
    loading: boolean;
    error?: any;
    total: number;
}

export const initialState: State = adapter.getInitialState({
    loading: false,
    error: null,
    total: 0
});
