import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Payment } from '@models/payment.model';

export const adapter: EntityAdapter<Payment> = createEntityAdapter<Payment>({
    selectId: payment => payment.id
});

export interface State extends EntityState<Payment> {
    loading: boolean;
    error?: any;
    total: number;
}

export const initialState: State = adapter.getInitialState({
    loading: false,
    error: null,
    total: 0
});
