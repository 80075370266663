<div class="history" #history [class.admin-support]="isAdminSupport">
    <ng-container>
        <div class="chat-message" *ngFor="let message of chat?.messages" [class.admin]="adminIds?.includes(message.userId)" [class.me]="message.userId === userId">
            <div class="message">{{ message.message }}</div>
            <div class="date">{{ message.creationDate | date:'MMM d, y h:mm a' }}</div>
        </div>
    </ng-container>
</div>

<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <mat-form-field appearance="outline">
        <mat-label>Message {{ maxLength === 0 ? '' : '(' + maxLength + ' char limit)' }}</mat-label>
        <i class="material-symbols-outlined" matPrefix>question_answer</i>
        <input matInput formControlName="message" [maxlength]="maxLength">
    </mat-form-field>

    <ion-button shape="round" size="large" type="submit" [disabled]="form.invalid">
        <i class="material-symbols-outlined">send</i>
    </ion-button>
</form>
