import { adapter, initialState, State } from './state';
import { createReducer, on, Action } from '@ngrx/store';
import { getRatesFailure, getRatesRequest, getRatesSuccess } from './actions';

export const rateReducer = createReducer(
    initialState,
    on(
        getRatesRequest,
        state => ({
            ...state,
            loading: true,
            error: null
        })
    ),
    on(
        getRatesFailure,
        (state, { error }) => ({
            ...state,
            loading: false,
            error
        })
    ),

    // Get Rates
    on(
        getRatesSuccess,
        (state, { rates }) => adapter.setAll(rates, {
            ...state,
            loading: false,
            error: null,
            total: rates.length
        })
    )
);

export function reducer(state: State, action: Action) {
    return rateReducer(state, action);
}
