import { createAction } from '@ngrx/store';

// Empty
export const empty = createAction(
    '[Route] Empty'
);

// Reset
export const reset = createAction(
    '[Route] Reset'
);
