import { Pipe, PipeTransform } from '@angular/core';
import { Vehicle } from '@models/vehicle.model';

@Pipe({
    name: 'vehicleSummary'
})
export class VehicleSummaryPipe implements PipeTransform {
    transform(vehicle: Vehicle): string {
        return `${vehicle.year.toString().substring(2)}' ${vehicle.make} ${vehicle.model}`;
    }
}
