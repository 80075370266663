import { adapter, initialState, State } from './state';
import { createReducer, on, Action } from '@ngrx/store';
import { clearUser, getUserChangesLoaded, getUserChangesRequest, getUserChangesSuccess, getUserFailure, getUserRequest,
    getUsersByRoleFailure, getUsersByRoleRequest, getUsersByRoleSuccess, getUsersFailure, getUsersRequest, getUsersSuccess,
    getUserSuccess, saveUserFailure, saveUserRequest, saveUserSuccess, setUserCurrentLocationFailure, setUserCurrentLocationRequest,
    setUserCurrentLocationSuccess, updateUserPropertiesFailure, updateUserPropertiesRequest,
    updateUserPropertiesSuccess } from './actions';
import { ObjectHelper } from '@classes/object-helper';
import { User } from '@models/user.model';

export const userReducer = createReducer(
    initialState,
    on(
        getUsersRequest,
        getUserRequest,
        saveUserRequest,
        setUserCurrentLocationRequest,
        updateUserPropertiesRequest,
        getUserChangesRequest,
        getUsersByRoleRequest,
        state => ({
            ...state,
            loading: true,
            error: null
        })
    ),
    on(
        getUsersFailure,
        getUserFailure,
        saveUserFailure,
        setUserCurrentLocationFailure,
        updateUserPropertiesFailure,
        getUsersByRoleFailure,
        (state, { error }) => ({
            ...state,
            loading: false,
            error
        })
    ),
    on(
        getUserChangesLoaded,
        (state) => ({
            ...state,
            loading: false
        })
    ),

    // Get Users
    on(
        getUsersSuccess,
        getUsersByRoleSuccess,
        (state, { users }) => adapter.upsertMany(users, {
            ...state,
            loading: false,
            error: null,
            total: users.length
        })
    ),

    // Get/Save User/Set User Current Location/Changes
    on(
        getUserSuccess,
        saveUserSuccess,
        setUserCurrentLocationSuccess,
        getUserChangesSuccess,
        (state, { user }) => adapter.upsertOne(user, {
            ...state,
            loading: false,
            error: null
        })
    ),

    // Update User Properties
    on(
        updateUserPropertiesSuccess,
        (state, { partialUser }) => {
            const user = state.entities[partialUser.id!]!;
            const updatedUser = ObjectHelper.mergeObject(user, partialUser as User);
            return adapter.upsertOne(updatedUser, {
                ...state,
                loading: false,
                error: null
            });
        }
    ),

    // Clear User
    on(
        clearUser,
        (state) => adapter.removeAll({
            ...state,
            loading: false,
            error: null
        })
    )
);

export function reducer(state: State, action: Action) {
    return userReducer(state, action);
}
