import { Email } from '@models/email.model';
import { createAction, props } from '@ngrx/store';

// Send Email
export const sendEmailRequest = createAction(
    '[Email] Save Email Request',
    props<{ email: Email }>()
);
export const sendEmailFailure = createAction(
    '[Email] Save Email Failure',
    props<{ error: string }>()
);
export const sendEmailSuccess = createAction(
    '[Email] Save Email Success',
    props<{ email: Email }>()
);
