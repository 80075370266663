import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TowStatus } from '@enums/tow-status.enum';
import { ModalController } from '@ionic/angular';
import { TowDetails } from '@models/tow-details.model';
import { DriverMapChecklistComponent } from '../driver-map-checklist/driver-map-checklist.component';
import { SubSink } from 'subsink';

@Component({
    selector: 'app-driver-map-tow-in-progress',
    templateUrl: './driver-map-tow-in-progress.component.html',
    styleUrls: ['./driver-map-tow-in-progress.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DriverMapTowInProgressComponent implements OnInit, OnDestroy {
    @Input() towDetails!: TowDetails | null;
    @Input() cancellationFee!: number | null;
    @Input() chatUnreadMessagesCount: number | null = 0;
    @Output() cancelTow = new EventEmitter<void>();
    @Output() changeStatus = new EventEmitter<TowStatus>();
    @Output() viewTowDetails = new EventEmitter<TowDetails>();
    @Output() sendReminder = new EventEmitter<TowStatus>();
    @Output() openChat = new EventEmitter<void>();

    private subs = new SubSink();
    private checklistComplete$ = new EventEmitter<void>();
    private checklistStatus = TowStatus.PickedUp;

    showTimer = false;

    constructor(
        private modalController: ModalController
    ) {}

    ngOnInit(): void {
        this.subs.add(
            this.checklistComplete$.subscribe(() => this.changeStatus.emit(this.checklistStatus))
        );
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    async onChangeStatus(status: TowStatus): Promise<void> {
        let newStatus!: TowStatus;

        switch (status) {
            case TowStatus.Started:
                newStatus = TowStatus.DriverOnWay;
                break;
            case TowStatus.DriverOnWay:
                newStatus = TowStatus.PickedUp;
                break;
            case TowStatus.PickedUp:
                newStatus = TowStatus.TrailerOnWay;
                break;
            case TowStatus.TrailerOnWay:
                newStatus = TowStatus.Delivered;
                this.showTimer = true;
                break;
        }

        if (this.towDetails?.tow.trailer.type !== 'Cargo' && newStatus === this.checklistStatus) {
            const modal = await this.modalController.create({
                component: DriverMapChecklistComponent,
                componentProps: {
                    checklistComplete: this.checklistComplete$
                }
            });
            await modal.present();
            return;
        }

        this.changeStatus.emit(newStatus);
    }

    onCancel(): void {
        this.cancelTow.emit();
    }

    onViewTowDetails(): void {
        this.viewTowDetails.emit(this.towDetails!);
    }

    onSendReminder(): void {
        this.sendReminder.emit(TowStatus.Delivered);
    }

    onOpenChat(): void {
        this.openChat.emit();
    }
}
