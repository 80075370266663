import { Payment } from '@models/payment.model';
import { createAction, props } from '@ngrx/store';

// Get Payments
export const getPaymentsRequest = createAction(
    '[Payment] Get Payments Request',
    props<{ userId: string }>()
);
export const getPaymentsFailure = createAction(
    '[Payment] Get Payments Failure',
    props<{ error: string }>()
);
export const getPaymentsSuccess = createAction(
    '[Payment] Get Payments Success',
    props<{ payments: Payment[] }>()
);

// Save Payment
export const savePaymentRequest = createAction(
    '[Payment] Save Payment Request',
    props<{ payment: Payment }>()
);
export const savePaymentFailure = createAction(
    '[Payment] Save Payment Failure',
    props<{ error: string }>()
);
export const savePaymentSuccess = createAction(
    '[Payment] Save Payment Success',
    props<{ payment: Payment }>()
);

// Set Default Payment
export const setDefaultPaymentRequest = createAction(
    '[Payment] Set Default Payment Request',
    props<{ payment: Payment }>()
);
export const setDefaultPaymentFailure = createAction(
    '[Payment] Set Default Payment Failure',
    props<{ error: string }>()
);
export const setDefaultPaymentSuccess = createAction(
    '[Payment] Set Default Payment Success',
    props<{ payment: Payment }>()
);

// Delete Payment
export const deletePaymentRequest = createAction(
    '[Payment] Delete Payment Request',
    props<{ payment: Payment, default?: boolean }>()
);
export const deletePaymentFailure = createAction(
    '[Payment] Delete Payment Failure',
    props<{ error: string }>()
);
export const deletePaymentSuccess = createAction(
    '[Payment] Delete Payment Success',
    props<{ payment: Payment, default?: boolean }>()
);
