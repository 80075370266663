import { Injectable } from '@angular/core';
import { Rating } from '@models/rating.model';
import { Observable } from 'rxjs';
import { DbService } from './db.service';
import { Filter } from '@models/filter.model';
import { Sort } from '@models/sort.model';
import { RatingType } from '@enums/rating-type.enum';

const COLLECTION = 'ratings';

@Injectable({
    providedIn: 'root'
})
export class RatingService {
    constructor(
        private dbService: DbService
    ) {}

    getRatings(userId?: string, type?: RatingType): Observable<Rating[]> {
        const filters: Filter[] = [];

        if (userId) {
            filters.push({ field: 'userId', operator: '==', value: userId });
        }

        if (type) {
            filters.push({ field: 'type', operator: '==', value: type });
        }

        const sort: Sort = { field: 'creationDate', direction: 'desc' };
        return this.dbService.getList(COLLECTION, filters, sort);
    }

    getRating(id: string): Observable<Rating> {
        return this.dbService.getObj(COLLECTION, id);
    }

    saveRating(rating: Rating): Observable<Rating> {
        return this.dbService.saveObj(COLLECTION, rating);
    }

    deleteRating(rating: Rating): Observable<string> {
        return this.dbService.deleteObj(COLLECTION, rating.id);
    }
}
