import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as actions from './actions';
import { AllowedVehicleService } from '@services/allowed-vehicle.service';

@Injectable()
export class AllowedVehicleStoreEffects {
    constructor(
        private actions$: Actions,
        private allowedVehicleService: AllowedVehicleService
    ) {}

    // Get Allowed Vehicles
    getAllowedVehicles$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getAllowedVehiclesRequest),
            switchMap(action => this.allowedVehicleService.getAllowedVehicles(action.year, action.make, action.model).pipe(
                map(allowedVehicles => actions.getAllowedVehiclesSuccess({ allowedVehicles })),
                catchError(error => of(actions.getAllowedVehiclesFailure({ error })))
            ))
        )
    );
}
