import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TowStatus } from '@enums/tow-status.enum';
import { Tow } from '@models/tow.model';

@Component({
    selector: 'app-driver-map-tow-completed',
    templateUrl: './driver-map-tow-completed.component.html',
    styleUrls: ['./driver-map-tow-completed.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DriverMapTowCompletedComponent {
    @Input() tow!: Tow;
    @Output() unsetTow = new EventEmitter<void>();
    @Output() sendReminder = new EventEmitter<TowStatus>();

    onSendReminder(): void {
        this.sendReminder.emit(TowStatus.Completed);
    }

    onClose(): void {
        this.unsetTow.emit();
    }
}
