import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as actions from './actions';
import { RatingService } from '@services/rating.service';

@Injectable()
export class RatingStoreEffects {
    constructor(
        private actions$: Actions,
        private ratingService: RatingService
    ) {}

    // Get Ratings
    getRatings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getRatingsRequest),
            switchMap(() => this.ratingService.getRatings().pipe(
                map(ratings => actions.getRatingsSuccess({ ratings })),
                catchError(error => of(actions.getRatingsFailure({ error })))
            ))
        )
    );

    // Get Rating
    getRating$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getRatingRequest),
            switchMap(action => this.ratingService.getRating(action.id).pipe(
                map(rating => actions.getRatingSuccess({ rating })),
                catchError(error => of(actions.getRatingFailure({ error })))
            ))
        )
    );

    // Save Rating
    saveRating$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.saveRatingRequest),
            switchMap(action => this.ratingService.saveRating(action.rating).pipe(
                map(rating => actions.saveRatingSuccess({ rating })),
                catchError(error => of(actions.saveRatingFailure({ error })))
            ))
        )
    );
}
