import { adapter, initialState, State } from './state';
import { createReducer, on, Action } from '@ngrx/store';
import { clearDriver, getDriverFailure, getDriverRequest, getDriversChangesFailure, getDriversChangesRequest,
    getDriversChangesSuccess, getDriversFailure, getDriversRequest, getDriversSuccess,
    getDriverSuccess, saveDriverFailure, saveDriverRequest, saveDriverSuccess, updateDriverPropertiesSuccess } from './actions';
import { ObjectHelper } from '@classes/object-helper';
import { Driver } from '@models/driver.model';

export const driverReducer = createReducer(
    initialState,
    on(
        getDriversRequest,
        getDriverRequest,
        saveDriverRequest,
        getDriversChangesRequest,
        state => ({
            ...state,
            loading: true,
            error: null
        })
    ),
    on(
        getDriversFailure,
        getDriverFailure,
        saveDriverFailure,
        getDriversChangesFailure,
        (state, { error }) => ({
            ...state,
            loading: false,
            error
        })
    ),

    // Get Drivers
    on(
        getDriversSuccess,
        getDriversChangesSuccess,
        (state, { drivers }) => adapter.upsertMany(drivers, {
            ...state,
            loading: false,
            error: null,
            total: drivers.length
        })
    ),

    // Get/Save Driver
    on(
        getDriverSuccess,
        saveDriverSuccess,
        (state, { driver }) => adapter.upsertOne(driver, {
            ...state,
            loading: false,
            error: null
        })
    ),

    // Clear Driver
    on(
        clearDriver,
        (state) => adapter.removeAll({
            ...state,
            loading: false,
            error: null
        })
    ),

    // Update Driver Properties
    on(
        updateDriverPropertiesSuccess,
        (state, { partialDriver }) => {
            const user = state.entities[partialDriver.id!]!;
            const updatedDriver = ObjectHelper.mergeObject(user, partialDriver as Driver);
            return adapter.upsertOne(updatedDriver, {
                ...state,
                loading: false,
                error: null
            });
        }
    )
);

export function reducer(state: State, action: Action) {
    return driverReducer(state, action);
}
