import { GeoData } from '@models/geo-data.model';
import { distanceBetween, geohashForLocation, geohashQueryBounds, Geopoint } from 'geofire-common';
import { from, map, Observable, of, switchMap } from 'rxjs';
import { Geolocation } from '@capacitor/geolocation';
import { UtilityHelper } from './utility-helper';
import { RouteData } from '@models/route-data.model';
import { Cache } from './cache';
import { PermissionState } from '@capacitor/core';
import { environment } from '@environments/environment';
import { GEO_LOCATION_GRANTED, LocalStorage } from './local-storage';
import { Address } from '@models/address.model';

export class GeoHelper {
    static getGeohash(latitude: number, longitude: number): string {
        const geohash = geohashForLocation([latitude, longitude]);
        return geohash;
    }

    static getMiles(kilometers: number): number {
        const miles = kilometers * 0.621371;
        return miles;
    }

    static getMeters(miles: number): number {
        const meters = miles / 0.621371 * 1000;
        return meters;
    }

    static getLocationPromptStatus(): Observable<PermissionState> {
        return from(Geolocation.checkPermissions()).pipe(
            map(status => status.location)
        );
    }

    static canPromptForLocation(): Observable<boolean> {
        const granted$ = LocalStorage.get<boolean>(GEO_LOCATION_GRANTED, 'boolean');
        return granted$.pipe(
            switchMap(granted => granted && UtilityHelper.isIOS() ? of(false) :
                this.getLocationPromptStatus().pipe(
                    map(status => status === 'prompt' || status === 'prompt-with-rationale')
                )
            )
        );
    }

    static getCurrentPositionGeoData(): Observable<GeoData> {
        const position$ = from(Geolocation.getCurrentPosition());
        return position$.pipe(
            map(position => {
                LocalStorage.set(GEO_LOCATION_GRANTED, true);
                const latitude = position.coords.latitude;
                const longitude = position.coords.longitude;
                const geohash = this.getGeohash(latitude, longitude);
                const geoData: GeoData = {
                    latitude,
                    longitude,
                    geohash
                };
                return geoData;
            })
        );
    }

    static getSubGeohash(geohash: string, size: number): string {
        return geohash.substring(0, size);
    }

    static getNearGeohashes(latitude: number, longitude: number, radius: number): string[][] {
        const center: Geopoint = [latitude, longitude];
        const meters = this.getMeters(radius);
        const bounds = geohashQueryBounds(center, meters);
        return bounds;
    }

    static isLocationInRadius(center: GeoData, location: GeoData, radius: number): boolean {
        const c: Geopoint = [center.latitude, center.longitude];
        const distanceInKm = distanceBetween([location.latitude, location.longitude], c);
        const distanceInM = distanceInKm * 1000;
        const radiusInM = this.getMeters(radius);
        return distanceInM <= radiusInM;
    }

    static loadGoogleMaps(mapInit?: Function): void {
        const isLoaded = document.querySelector('script#googleMaps');
        if (isLoaded) {
            return;
        }

        (window as any).mapInit = () => mapInit?.();
        const script = document.createElement('script');
        script.async = true;
        script.id = 'googleMaps';
        script.src = environment.googleApi.mapUrl.replace('{key}', environment.googleApi.key);
        document.body.appendChild(script);
    }

    static getRouteData(start: GeoData, end: GeoData): Observable<RouteData> {
        const key = `getRouteData:${start.latitude}|${start.longitude}|${end.latitude}|${end.longitude}`;
        const cache = Cache.get<RouteData>(key);
        if (cache) {
            return of(cache);
        }

        const origin = new google.maps.LatLng(start.latitude, start.longitude);
        const destination = new google.maps.LatLng(end.latitude, end.longitude);
        const matrix = new google.maps.DistanceMatrixService();
        const promise = new Promise<RouteData>((resolve, reject) => {
            matrix.getDistanceMatrix({
                origins: [origin],
                destinations: [destination],
                travelMode: google.maps.TravelMode.DRIVING,
                unitSystem: google.maps.UnitSystem.IMPERIAL
            }, (response, status) => {
                if (status !== 'OK') {
                    console.error(`Directions request failed due to ${status}`);
                    reject();
                    return;
                }

                if (!response) {
                    console.error('Directions request failed due response being null');
                    reject();
                    return;
                }

                const distanceText = response.rows[0].elements[0].distance.text;
                let distance = UtilityHelper.removeNonDecimals(distanceText);

                if (distanceText.includes('ft')) {
                    const miles = distance / 5280;
                    distance = UtilityHelper.getRoundedDecimal(miles);
                }

                const duration = response.rows[0].elements[0].duration.value;
                const routeData: RouteData = {
                    distance,
                    duration,
                    start: { ...start, address: response.originAddresses[0] },
                    destination: { ...end, address: response.destinationAddresses[0] }
                };
                Cache.add(key, routeData);
                resolve(routeData);
            });
        });
        return from(promise);
    }

    static getFormattedAddress(address: Address): string {
        return `${address.address}, ${address.city}, ${address.state} ${address.zipCode}, USA`;
    }
}

/*
Geohash Sizes
=============
Length  Grid Area (width x height)
1       5,009.4 km x 4,992.6 km / 3112.7 mi x 3102.3 mi
2       1,252.3 km x 624.1 km / 778.1 mi x 387.9 mi
3       156.5 km x 156 km / 97.2 mi x 96.9 mi
4       39.1 km x 19.5 km / 24.3 mi x 12.1 mi
5       4.9 km x 4.9 km / 3 mi x 3 mi
6       1.2 km x 609.4 m
7       152.9 m x 152.4 m
8       38.2 m x 19 m
9       4.8 m x 4.8 m
10      1.2 m x 59.5 m
11      14.9 cm x 14.9 cm
12      3.7 cm x 1.9 cm
*/
