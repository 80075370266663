import { merge, clone, cloneDeep, isEqual, isEmpty } from 'lodash';
import { DateHelper } from './date-helper';

export class ObjectHelper {
    static cloneObject<T>(obj: T, deep = false): T {
        if (deep) {
            return cloneDeep(obj);
        }

        return clone(obj);
    }

    static mergeObject<T>(target: T, source: T): T {
        const targetClone = this.cloneObject(target, true);
        return merge(targetClone, source);
    }

    static areObjectsEqual<T>(obj1: T, obj2: T): boolean {
        return isEqual(obj1, obj2);
    }

    static removeEmptyProperties<T>(obj: T): void {
        Object.keys(obj as Object).forEach(k => {
            const value = (obj as any)[k];
            if (value === null || value === undefined) {
                delete (obj as any)[k];
            }
        });
    }

    static fixDate<T>(obj: T, property: string): void {
        const date = (obj as any)[property];
        if (!date) {
            return;
        }

        let fixedDate: Date;
        if (typeof date === 'string') {
            fixedDate = new Date(date);
        } else {
            fixedDate = DateHelper.isTimestamp(date) ? DateHelper.convertTimestampToDate(date) : date.toDate();
        }

        (obj as any)[property] = fixedDate;
    }

    static isObjectEmpty<T>(obj: T): boolean {
        return isEmpty(obj);
    }

    // static sortObjectProperties<T>(obj: T): T {
    //     const map = new Map<string, any>();
    //     const temp = obj as any;
    //     const keys = Object.keys(temp);
    //     keys.forEach(key => {
    //         let value = temp[key];
    //         if (!DateHelper.isDate(value) && typeof value === 'object') {
    //             value = this.sortObjectProperties(value);
    //         }

    //         map.set(key, value);
    //     });
    //     const sortedMap = new Map([...map.entries()].sort());
    //     const sortedObj = Object.fromEntries(sortedMap);
    //     return sortedObj as T;
    // }
}
