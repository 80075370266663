<ion-header>
    <ion-toolbar color="secondary">
        <ion-title>Requests</ion-title>
        <ion-buttons slot="end">
            <ion-button id="tow-requests-sort">
                <i class="material-symbols-outlined">sort</i>
            </ion-button>
            <ion-popover trigger="tow-requests-sort" [dismissOnSelect]="true">
                <ng-template>
                    <ion-content>
                        <ion-list class="tow-requests-sort">
                            <ion-item *ngFor="let sort of towRequestsSortOptions; let last = last" [button]="true"
                                [class.active]="towRequestsSort === sort" [lines]="last ? 'none' : 'full'"
                                (click)="onTowRequestsSort(sort)">
                                <ion-label>{{ sort.name }}</ion-label>
                            </ion-item>
                        </ion-list>
                    </ion-content>
                </ng-template>
            </ion-popover>
            <ion-button (click)="onClose()">
                <i class="material-symbols-outlined">close</i>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content fullscreen>
    <ion-list>
        <app-trailer-map-tow-request *ngFor="let towRequest of activeTowRequests$ | async as towRequests"
            [towRequest]="towRequest" [totalTowRequests]="towRequests.length" [approveTowRequest]="approveTowRequest"
            [cancelTowRequest]="cancelTowRequest"></app-trailer-map-tow-request>

        <ng-container *ngIf="availableTowRequests$ | async as towRequests">
            <ion-item-divider mode="md" *ngIf="towRequests.length > 0">
                <ion-label>
                    Available Drivers
                </ion-label>
            </ion-item-divider>

            <app-trailer-map-tow-request *ngFor="let towRequest of towRequests" [towRequest]="towRequest" [totalTowRequests]="towRequests.length"
                [approveTowRequest]="approveTowRequest" [cancelTowRequest]="cancelTowRequest"></app-trailer-map-tow-request>
        </ng-container>
    </ion-list>
</ion-content>
