import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { State } from './state';
import { DriverBusyTime } from '@models/driver-busy-time.model';

export const getState: MemoizedSelector<object, State> = createFeatureSelector<State>('driverBusyTime');

export const getLoading: MemoizedSelector<object, boolean> = createSelector(getState, (state: State) => state.loading);

export const getError: MemoizedSelector<object, string> = createSelector(getState, (state: State) => state.error);

export const getDriversBusyTimes = (driverIds: string[]) => createSelector(getState, (state: State) => {
    const driversBusyTimes = Object.values(state.entities) as DriverBusyTime[];
    const driverBusyTimes = driversBusyTimes.filter(x => driverIds.includes(x.driverId));
    return driverBusyTimes;
});
