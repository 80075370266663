import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UtilityHelper } from '@classes/utility-helper';
import { environment } from '@environments/environment';
import { AlertController, ModalController } from '@ionic/angular';
import { DistancesDurations } from '@models/distances-durations.model';
import { Tow } from '@models/tow.model';

@Component({
    selector: 'app-trailer-map-full-tow-details',
    templateUrl: './trailer-map-full-tow-details.component.html',
    styleUrls: ['./trailer-map-full-tow-details.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TrailerMapFullTowDetailsComponent implements OnInit {
    @Input() tow!: Tow;

    displayDefaultPhoto = false;
    rating = environment.rating;
    distancesDurations!: DistancesDurations;

    constructor(
        private alertController: AlertController,
        private modalController: ModalController
    ) {}

    ngOnInit(): void {
        this.distancesDurations = UtilityHelper.getDistancesDurations(this.tow.approvedRequest!.commute, this.tow.routeData);
    }

    onPhotoError(): void {
        this.displayDefaultPhoto = true;
    }

    onClose(): void {
        this.modalController.dismiss();
    }

    async onShowInstructions(): Promise<void> {
        const alert = await this.alertController.create({
            header: 'Instructions',
            message: this.tow.instructions,
            buttons: ['OK']
        });
        await alert.present();
    }
}
