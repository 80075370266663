import { adapter, initialState, State } from './state';
import { createReducer, on, Action } from '@ngrx/store';
import { authorizeFailure, authorizeRequest, authorizeSuccess, cancelFailure, cancelRequest, cancelSuccess, captureFailure,
    captureRequest, captureSuccess, completeSaleFailure, completeSaleRequest, completeSaleSuccess, getTransactionsFailure,
    getTransactionsRequest, getTransactionsSuccess } from './actions';

export const transactionReducer = createReducer(
    initialState,
    on(
        authorizeRequest,
        cancelRequest,
        completeSaleRequest,
        captureRequest,
        getTransactionsRequest,
        state => ({
            ...state,
            loading: true,
            error: null
        })
    ),
    on(
        authorizeFailure,
        cancelFailure,
        completeSaleFailure,
        captureFailure,
        getTransactionsFailure,
        (state, { error }) => ({
            ...state,
            loading: false,
            error
        })
    ),

    // Authorize/Cancel/Complete Sale
    on(
        authorizeSuccess,
        cancelSuccess,
        completeSaleSuccess,
        captureSuccess,
        (state, { transaction }) => adapter.upsertOne(transaction, {
            ...state,
            loading: false,
            error: null
        })
    ),

    // Get Transactions
    on(
        getTransactionsSuccess,
        (state, { transactions }) => adapter.upsertMany(transactions, {
            ...state,
            loading: false,
            error: null
        })
    )
);

export function reducer(state: State, action: Action) {
    return transactionReducer(state, action);
}
