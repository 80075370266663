import { RateCategory } from '@enums/rate-category.enum';
import { RateType } from '@enums/rate-type.enum';
import { Rate } from '@models/rate.model';
import { createAction, props } from '@ngrx/store';

// Get Rates
export const getRatesRequest = createAction(
    '[Rate] Get Rates Request',
    props<{ category: RateCategory, rateType?: RateType }>()
);
export const getRatesFailure = createAction(
    '[Rate] Get Rates Failure',
    props<{ error: string }>()
);
export const getRatesSuccess = createAction(
    '[Rate] Get Rates Success',
    props<{ rates: Rate[] }>()
);
