import { Injectable } from '@angular/core';
import { Cache } from '@classes/cache';
import { Filter } from '@models/filter.model';
import { AllowedVehicle } from '@models/allowed-vehicle.model';
import { Observable, of, tap } from 'rxjs';
import { DbService } from './db.service';
import { ListHelper } from '@classes/list-helper';

const COLLECTION = 'allowedVehicles';

@Injectable({
    providedIn: 'root'
})
export class AllowedVehicleService {
    constructor(
        private dbService: DbService
    ) {}

    getAllowedVehicles(year: number, make?: string, model?: string): Observable<AllowedVehicle[]> {
        const filters: Filter[] = [
            { field: 'year', operator: '==', value: year }
        ];

        if (make) {
            filters.push({ field: 'make', operator: '==', value: make });
        }

        if (model) {
            filters.push({ field: 'model', operator: '==', value: model });
        }

        const key = JSON.stringify(filters);
        const cache = this.getFromCache(filters, key);
        if (cache) {
            return of(cache);
        }

        return this.dbService.getList<AllowedVehicle>(COLLECTION, filters).pipe(
            tap(allowedVehicles => Cache.add(key, allowedVehicles))
        );
    }

    private getFromCache(filters: Filter[], key: string): AllowedVehicle[] | null {
        // See if found in 'allowedVehicles' cache
        const allowedVehicles = Cache.get<AllowedVehicle[]>('allowedVehicles');
        if (allowedVehicles) {
            return ListHelper.filterList(allowedVehicles, filters);
        }

        // See if found in filtered cache
        const cache = Cache.get<AllowedVehicle[]>(key);
        if (cache) {
            return cache;
        }

        return null;
    }
}
