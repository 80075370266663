import { adapter, initialState, State } from './state';
import { createReducer, on, Action } from '@ngrx/store';
import { getAllowedVehiclesFailure, getAllowedVehiclesRequest, getAllowedVehiclesSuccess } from './actions';

export const allowedVehicleReducer = createReducer(
    initialState,
    on(
        getAllowedVehiclesRequest,
        state => ({
            ...state,
            loading: true,
            error: null
        })
    ),
    on(
        getAllowedVehiclesFailure,
        (state, { error }) => ({
            ...state,
            loading: false,
            error
        })
    ),

    // Get Allowed Vehicles
    on(
        getAllowedVehiclesSuccess,
        (state, { allowedVehicles }) => adapter.setAll(allowedVehicles, {
            ...state,
            loading: false,
            error: null,
            total: allowedVehicles.length
        })
    )
);

export function reducer(state: State, action: Action) {
    return allowedVehicleReducer(state, action);
}
