<ion-header>
    <ion-toolbar color="secondary">
        <ion-title>Chat</ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="onClose()">
                <i class="material-symbols-outlined">close</i>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content fullscreen>
    <app-chat [chat]="chat$ | async" [userId]="userId" (updateChat)="onUpdateChat($event)"></app-chat>
</ion-content>
