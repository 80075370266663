import { KeyValue } from '@angular/common';
import { Session } from '@models/session.model';
import { createAction, props } from '@ngrx/store';

// Get Session
export const getSession = createAction(
    '[Session] Get Session'
);

// Save Session
export const saveSession = createAction(
    '[Session] Save Session',
    props<{ session: Session }>()
);

// Save Session Property
export const saveSessionProperty = createAction(
    '[Session] Save Session Property',
    props<{ property: string, value: any }>()
);

// Save Session Properties
export const saveSessionProperties = createAction(
    '[Session] Save Session Properties',
    props<{ properties: KeyValue<string, any>[] }>()
);

// Save Session Child Property
export const saveSessionChildProperty = createAction(
    '[Session] Save Session Child Property',
    props<{ parentProperty: string, childProperty: string, value: any }>()
);

// // Get Bank Account Session Secret
// export const getBankAccountSessionSecretRequest = createAction(
//     '[Session] Get Bank Account Session Secret Request',
//     props<{ userId: string }>()
// );
// export const getBankAccountSessionSecretFailure = createAction(
//     '[Session] Get Bank Account Session Secret Failure',
//     props<{ error: string }>()
// );
// export const getBankAccountSessionSecretSuccess = createAction(
//     '[Session] Get Bank Account Session Secret Success',
//     props<{ bankAccountSessionSecret: string }>()
// );

// Clear Session
export const clearSession = createAction(
    '[Session] Clear Session'
);
