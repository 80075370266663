import { Injectable } from '@angular/core';
import { TowRequest } from '@models/tow-request.model';
import { Observable, Subject } from 'rxjs';
import { DbService } from './db.service';
import { Filter } from '@models/filter.model';
import { Sort } from '@models/sort.model';
import { Unsubscribe } from '@angular/fire/firestore';
import { TowRequestStatus } from '@enums/tow-request-status.enum';
import { ObjectHelper } from '@classes/object-helper';
import { UtilityHelper } from '@classes/utility-helper';

const COLLECTION = 'towRequests';

@Injectable({
    providedIn: 'root'
})
export class TowRequestService {
    constructor(
        private dbService: DbService
    ) {}

    getTowRequests(towId?: string, userId?: string, statuses?: TowRequestStatus[], vehicleId?: string): Observable<TowRequest[]> {
        const { filters, sort } = this.getFiltersAndSort(towId, userId, statuses, vehicleId);
        const otherFixes = (towRequest: TowRequest) => this.adjustTowRequest(towRequest);
        return this.dbService.getList(COLLECTION, filters, sort, undefined, undefined, otherFixes);
    }

    getTowRequestsChanges(subs$: Subject<TowRequest[]>, towId?: string, userId?: string, statuses?: TowRequestStatus[],
        vehicleId?: string, notExpired = false): Unsubscribe {
        const { filters, sort } = this.getFiltersAndSort(towId, userId, statuses, vehicleId, notExpired);
        const otherFixes = (towRequest: TowRequest) => this.adjustTowRequest(towRequest);
        return this.dbService.getListChanges(COLLECTION, subs$, filters, sort, undefined, undefined, otherFixes);
    }

    getTowRequest(id: string): Observable<TowRequest> {
        return this.dbService.getObj(COLLECTION, id);
    }

    saveTowRequest(towRequest: TowRequest): Observable<TowRequest> {
        towRequest = { ...towRequest, statusLastUpdate: new Date() };
        return this.dbService.saveObj(COLLECTION, towRequest);
    }

    deleteTowRequest(towRequest: TowRequest): Observable<string> {
        return this.dbService.deleteObj(COLLECTION, towRequest.id);
    }

    updateTowRequests(towRequests: TowRequest[]): Observable<TowRequest[]> {
        return this.dbService.updateList(COLLECTION, towRequests);
    }

    private getFiltersAndSort(towId?: string, userId?: string, statuses?: TowRequestStatus[],
        vehicleId?: string, notExpired = false): { filters: Filter[], sort: Sort } {
        const filters: Filter[] = [];

        if (towId) {
            filters.push({ field: 'tow.id', operator: '==', value: towId });
        }

        if (userId) {
            filters.push({ field: 'driverDetails.user.id', operator: '==', value: userId });
        }

        if (statuses && statuses.length > 0) {
            filters.push({ field: 'status', operator: 'in', value: statuses });
        }

        if (vehicleId) {
            filters.push({ field: 'driverDetails.vehicle.id', operator: '==', value: vehicleId });
        }

        if (notExpired) {
            filters.push({ field: 'tow.datetime', operator: '>=', value: new Date() });
        }

        const field = notExpired ? 'tow.datetime' : 'creationDate';
        const direction = notExpired ? 'asc' : 'desc';
        const sort: Sort = { field, direction };
        return { filters, sort };
    }

    private adjustTowRequest(towRequest: TowRequest): void {
        this.fixDates(towRequest);
        this.setDistanceDuration(towRequest);
    }

    private fixDates(towRequest: TowRequest): void {
        ObjectHelper.fixDate(towRequest, 'statusLastUpdate');
        ['datetime', 'cancellationDate', 'creationDate'].forEach(property => {
            ObjectHelper.fixDate(towRequest.tow, property);
        });
    }

    private setDistanceDuration(towRequest: TowRequest): void {
        const distancesDurations = UtilityHelper.getDistancesDurations(towRequest.commute, towRequest.tow.routeData);
        const totalDistanceDuration = UtilityHelper.getTotalDistanceDuration(distancesDurations);
        towRequest.distance = totalDistanceDuration.distance;
        towRequest.duration = totalDistanceDuration.duration;
    }
}
