import { Driver } from '@models/driver.model';
import { createAction, props } from '@ngrx/store';

// Get Drivers
export const getDriversRequest = createAction(
    '[Driver] Get Drivers Request',
    props<{ ids?: string[], active?: boolean }>()
);
export const getDriversFailure = createAction(
    '[Driver] Get Drivers Failure',
    props<{ error: string }>()
);
export const getDriversSuccess = createAction(
    '[Driver] Get Drivers Success',
    props<{ drivers: Driver[] }>()
);

// Get Drivers Changes
export const getDriversChangesRequest = createAction(
    '[Driver] Get Drivers Changes Request',
    props<{ ids?: string[] }>()
);
export const getDriversChangesFailure = createAction(
    '[Driver] Get Drivers Changes Failure',
    props<{ error: string }>()
);
export const getDriversChangesSuccess = createAction(
    '[Driver] Get Drivers Changes Success',
    props<{ drivers: Driver[] }>()
);

// Get Driver
export const getDriverRequest = createAction(
    '[Driver] Get Driver Request',
    props<{ id: string }>()
);
export const getDriverFailure = createAction(
    '[Driver] Get Driver Failure',
    props<{ error: string }>()
);
export const getDriverSuccess = createAction(
    '[Driver] Get Driver Success',
    props<{ driver: Driver }>()
);

// Save Driver
export const saveDriverRequest = createAction(
    '[Driver] Save Driver Request',
    props<{ driver: Driver }>()
);
export const saveDriverFailure = createAction(
    '[Driver] Save Driver Failure',
    props<{ error: string }>()
);
export const saveDriverSuccess = createAction(
    '[Driver] Save Driver Success',
    props<{ driver: Driver }>()
);

// Clear Driver
export const clearDriver = createAction(
    '[Driver] Clear Driver'
);

// Unsubscribe Drivers Changes
export const unsubscribeDriversChangesRequest = createAction(
    '[Driver] Unsubscribe Drivers Changes Request'
);
export const unsubscribeDriversChangesSuccess = createAction(
    '[Driver] Unsubscribe Drivers Changes Success'
);

// Update Driver Properties
export const updateDriverPropertiesRequest = createAction(
    '[Driver] Update Driver Properties Request',
    props<{ id: string, properties: Partial<Driver> }>()
);
export const updateDriverPropertiesFailure = createAction(
    '[Driver] Update Driver Properties Failure',
    props<{ error: string }>()
);
export const updateDriverPropertiesSuccess = createAction(
    '[Driver] Update Driver Properties Success',
    props<{ partialDriver: Partial<Driver> }>()
);
