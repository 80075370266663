import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { State, ID } from './state';

export const getState: MemoizedSelector<object, State> = createFeatureSelector<State>('data');

export const getLoading: MemoizedSelector<object, boolean> = createSelector(getState, (state: State) => state.loading);

export const getError: MemoizedSelector<object, string> = createSelector(getState, (state: State) => state.error);

export const getAllowedVehicleYears: MemoizedSelector<object, number[]> = createSelector(getState, (state: State) =>
    state.entities[ID]?.years as number[]);
