import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { ExpirationDateHelper } from '@classes/expiration-date-helper';
import { IonModal } from '@ionic/angular';
import { AppNotification } from '@models/app-notification.model';
import { Store } from '@ngrx/store';
import { combineLatest, distinctUntilChanged, map, Observable, of, shareReplay, switchMap, tap } from 'rxjs';
import { ChatStoreSelectors, PayoutStoreSelectors, SessionStoreActions, SessionStoreSelectors, SupportStoreSelectors,
    TowRequestStoreSelectors, TowStoreSelectors, TrailerStoreSelectors, UserStoreSelectors,
    VehicleStoreSelectors } from 'src/app/root-store';
import { VehicleSummaryPipe } from '../pipes/vehicle-summary.pipe';
import { AppNotificationMessage } from '@models/app-notification-message.model';
import { DatePipe, DecimalPipe, KeyValue } from '@angular/common';
import { TowRequest } from '@models/tow-request.model';
import { TrailerSummaryPipe } from '../pipes/trailer-summary.pipe';
import { DurationPipe } from '../pipes/duration.pipe';
import { DomSanitizer } from '@angular/platform-browser';
import { Tow } from '@models/tow.model';
import { Support } from '@models/support.model';
import { Trailer } from '@models/trailer.model';
import { TrailerIcon } from '@enums/trailer-icon.enum';
import { Payout } from '@models/payout.model';
import { Experience } from '@custom-types/experience.type';
import { ObjectHelper } from '@classes/object-helper';
import { UtilityHelper } from '@classes/utility-helper';
import { UtilityService } from '@services/utility.service';

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationsComponent implements OnInit {
    @ViewChild('modal') modal!: IonModal;

    private userId$!: Observable<string>;
    private towsInProgress$!: Observable<Tow[]>;
    private towRequestsInProgress$!: Observable<TowRequest[]>;

    notifications: KeyValue<string, Observable<AppNotification[]>>[] = [];
    count$!: Observable<number>;
    displayDefaultPhoto = new Map<string, boolean>();

    constructor(
        private store: Store,
        private vehicleSummaryPipe: VehicleSummaryPipe,
        private trailerSummaryPipe: TrailerSummaryPipe,
        private decimalPipe: DecimalPipe,
        private durationPipe: DurationPipe,
        private datePipe: DatePipe,
        private sanitizer: DomSanitizer,
        private utilityService: UtilityService
    ) {}

    ngOnInit(): void {
        this.userId$ = this.store.select(SessionStoreSelectors.getUserId).pipe(
            shareReplay()
        );
        this.towsInProgress$ = this.userId$.pipe(
            switchMap(userId => this.store.select(TowStoreSelectors.getTowsInProgress(undefined, undefined, userId))),
            distinctUntilChanged((prev, curr) => ObjectHelper.areObjectsEqual(prev, curr)),
            tap(x => console.log('towsInProgress', x))
        );
        this.towRequestsInProgress$ = this.userId$.pipe(
            switchMap(userId => this.store.select(TowRequestStoreSelectors.getTowRequestsInProgress(userId))),
            distinctUntilChanged((prev, curr) => ObjectHelper.areObjectsEqual(prev, curr)),
            tap(x => console.log('towRequestsInProgress', x))
        );
        const towRequestNotifications$ = this.towRequestsInProgress$.pipe(
            map(towRequests => this.getTowRequestNotifications(towRequests)),
            distinctUntilChanged((prev, curr) => ObjectHelper.areObjectsEqual(prev, curr)),
            shareReplay(),
            tap(x => this.preloadPhotos(x)),
            tap(x => console.log('towRequestNotifications', x))
        );
        const towNotifications$ = this.towsInProgress$.pipe(
            map(tows => this.getTowNotifications(tows)),
            distinctUntilChanged((prev, curr) => ObjectHelper.areObjectsEqual(prev, curr)),
            shareReplay(),
            tap(x => console.log('towNotifications', x))
        );
        const trailerNotifications$ = this.store.select(TrailerStoreSelectors.getTrailers).pipe(
            map(trailers => this.getExpiredNotifications(trailers, 'trailer')),
            distinctUntilChanged((prev, curr) => ObjectHelper.areObjectsEqual(prev, curr)),
            shareReplay(),
            tap(x => console.log('trailerNotifications', x))
        );
        const vehicleNotifications$ = this.userId$.pipe(
            switchMap(userId => this.store.select(VehicleStoreSelectors.getVehicles(userId))),
            map(vehicles => this.getExpiredNotifications(vehicles, 'vehicle')),
            distinctUntilChanged((prev, curr) => ObjectHelper.areObjectsEqual(prev, curr)),
            shareReplay(),
            tap(x => console.log('vehicleNotifications', x))
        );
        const supportNotifications$ = this.store.select(SupportStoreSelectors.getUnresolvedSupport()).pipe(
            map(support => this.getSupportNotifications(support)),
            distinctUntilChanged((prev, curr) => ObjectHelper.areObjectsEqual(prev, curr)),
            shareReplay(),
            tap(x => console.log('supportNotifications', x))
        );
        const payoutNotifications$ = this.userId$.pipe(
            switchMap(userId => this.store.select(UserStoreSelectors.getUser(userId))),
            switchMap(user => {
                if (!user?.payoutId) {
                    return of(undefined);
                }

                return this.store.select(PayoutStoreSelectors.getPayout(user.payoutId));
            }),
            map(payout => this.getPayoutNotifications(payout)),
            distinctUntilChanged((prev, curr) => ObjectHelper.areObjectsEqual(prev, curr)),
            shareReplay(),
            tap(x => console.log('payoutNotifications', x))
        );
        const chatNotifications$ = this.getChatNotifications$();

        this.addNotifications('Support', supportNotifications$);
        this.addNotifications('Requests', towRequestNotifications$);
        this.addNotifications('Tows in Progress', towNotifications$);
        this.addNotifications('Chats', chatNotifications$);
        this.addNotifications('Trailers', trailerNotifications$);
        this.addNotifications('Vehicles', vehicleNotifications$);
        this.addNotifications('Payout', payoutNotifications$);

        this.count$ = combineLatest([
            supportNotifications$,
            towRequestNotifications$,
            towNotifications$,
            chatNotifications$,
            trailerNotifications$,
            vehicleNotifications$,
            payoutNotifications$
        ]).pipe(
            map(([supportNotifications, towRequestNotifications, towNotifications, chatNotifications,
                trailerNotifications, vehicleNotifications, payoutNotifications]) =>
                supportNotifications.length + towRequestNotifications.length + towNotifications.length + chatNotifications.length +
                trailerNotifications.length + vehicleNotifications.length + payoutNotifications.length
            )
        );
    }

    onOpen(): void {
        this.modal.present();
    }

    onClose(): void {
        this.modal.dismiss();
    }

    onPhotoError(id: string): void {
        this.displayDefaultPhoto.set(id, true);
    }

    onClick(notification: AppNotification): void {
        this.modal.dismiss();

        if (notification.route) {
            const route = notification.route[0];
            this.setExperience(route);
            this.utilityService.navigate(route);
        }
    }

    private getExpiredNotifications<T>(list: T[], type: 'trailer' | 'vehicle'): AppNotification[] {
        const notifications: AppNotification[] = [];

        list.forEach(temp => {
            const obj = temp as any;
            const title = type === 'trailer' ? (obj.nickname ?? obj.description) : this.vehicleSummaryPipe.transform(obj);
            const messages: AppNotificationMessage[] = [];
            const expirations = ExpirationDateHelper.checkExpirationDates(obj);
            if (expirations.length === 0) {
                return;
            }

            expirations.forEach(expiration => {
                const message: AppNotificationMessage = {
                    message: expiration.message,
                    icon: 'warning',
                    class: `list-${expiration.type}`
                };
                messages.push(message);
            });
            const isCargo = type === 'trailer' && (obj as Trailer).icon === TrailerIcon.Cargo;
            const notification: AppNotification = {
                id: obj.id,
                icon: {
                    material: isCargo ? 'weight' : undefined,
                    custom: isCargo ? undefined : `${type}-${obj.icon}`
                },
                title,
                messages,
                route: [`/${type === 'vehicle' ? 'driver/vehicle' : 'trailer/payload'}/${obj.id}`]
            };
            notifications.push(notification);
        });

        return notifications;
    }

    private getTowRequestNotifications(towRequests: TowRequest[]): AppNotification[] {
        const notifications: AppNotification[] = [];

        towRequests.forEach(towRequest => {
            const icon = towRequest.tow.trailer.icon === TrailerIcon.Cargo ?
                '<i class="material-symbols-outlined" matPrefix>weight</i>' :
                `<ion-icon src="/assets/svg/trailer-${towRequest.tow.trailer.icon}.svg"></ion-icon>`;
            const title = this.sanitizer.bypassSecurityTrustHtml(`
                <div class="name">${towRequest.tow.user.firstName} ${towRequest.tow.user.lastName}</div>
                <div class="weight-length">
                    ${icon}
                    <div class="ellipsis"><span>
                        ${this.trailerSummaryPipe.transform(towRequest.tow.trailer)}
                    </span></div>
                </div>
            `);
            const messages: AppNotificationMessage[] = [{
                message: `
                    ${this.decimalPipe.transform(towRequest.distance)} mi |
                    ${this.durationPipe.transform(towRequest.duration)}
                `
            }, {
                message: `${this.datePipe.transform(towRequest.tow.datetime, 'M/d/y h:mm a')}`
            }];
            const notification: AppNotification = {
                id: towRequest.id,
                photo: towRequest.tow.user.photo,
                title,
                messages,
                route: [`/driver/map/${towRequest.driverDetails.vehicle.id}/${towRequest.id}`],
                cost: towRequest.driverDetails.payment.payment
            };
            notifications.push(notification);
        });

        return notifications;
    }

    private getTowNotifications(tows: Tow[]): AppNotification[] {
        const notifications: AppNotification[] = [];

        tows.forEach(tow => {
            const title = this.sanitizer.bypassSecurityTrustHtml(`
                ${tow.trailer.nickname ?? tow.trailer.description}<br>
                ${this.trailerSummaryPipe.transform(tow.trailer)}
            `);
            const messages: AppNotificationMessage[] = [{
                message: `${this.datePipe.transform(tow.datetime, 'M/d/y h:mm a')}`
            }];
            const isCargo = tow.trailer.icon === TrailerIcon.Cargo;
            const notification: AppNotification = {
                id: tow.id,
                icon: {
                    material: isCargo ? 'weight' : undefined,
                    custom: isCargo ? undefined : `trailer-${tow.trailer.icon}`
                },
                title,
                messages,
                route: [`/trailer/map/${tow.trailer.id}/status`]
            };
            notifications.push(notification);
        });

        return notifications;
    }

    private getSupportNotifications(support?: Support): AppNotification[] {
        if (!support) {
            return [];
        }

        const count = support.messages.reduce((sum, x) => !x.fromUser && !x.read ? sum + 1 : 0, 0);
        if (count === 0) {
            return [];
        }

        const title = 'Support Update';
        const messages: AppNotificationMessage[] = [{
            message: `${count} unread message${count === 1 ? '' : 's'}`
        }];
        const notification: AppNotification = {
            id: support.id,
            icon: {
                material: 'contact_support'
            },
            title,
            messages,
            route: ['/support']
        };
        return [notification];
    }

    private getPayoutNotifications(payout?: Payout): AppNotification[] {
        if (!payout) {
            return [];
        }

        if (payout.onboardingComplete) {
            return [];
        }

        const title = 'Payout Creation Incomplete';
        const messages: AppNotificationMessage[] = [{
            message: 'Payout creation must be finalized'
        }];
        const notification: AppNotification = {
            id: payout.id,
            icon: {
                material: 'wallet'
            },
            title,
            messages,
            route: ['/payout/refresh']
        };
        return [notification];
    }

    private addNotifications(key: string, value: Observable<AppNotification[]>): void {
        const kv: KeyValue<string, Observable<AppNotification[]>> = { key, value };
        this.notifications.push(kv);
    }

    private setExperience(route: string): void {
        const value: Experience = route.includes('/driver/') || route.includes('/payout/') ? 'driver' : 'trailer';
        this.store.dispatch(SessionStoreActions.saveSessionProperty({ property: 'experience', value }));
    }

    private preloadPhotos(notifications: AppNotification[]): void {
        const imgs = notifications.filter(x => !!x.photo).map(x => x.photo!);
        UtilityHelper.preloadImages(imgs);
    }

    private getChatNotifications$(): Observable<AppNotification[]> {
        const trailerChatNotifications$ = this.towsInProgress$.pipe(
            switchMap(tows => combineLatest([
                of(tows),
                this.store.select(ChatStoreSelectors.getChats(tows.map(x => x.id))),
                this.userId$
            ])),
            map(([tows, chats, userId]) => {
                const towRequestsWithUnreadChatMessages: TowRequest[] = [];
                chats.forEach(chat => {
                    if (chat.messages.filter(x => !x.read && x.userId !== userId).length > 0) {
                        const tow = tows.find(x => x.id === chat.towId);
                        if (tow?.approvedRequest) {
                            towRequestsWithUnreadChatMessages.push(tow.approvedRequest);
                        }
                    };
                });
                const notifications = this.getChatsNotifications(towRequestsWithUnreadChatMessages, 'trailer');
                return notifications;
            }),
            tap(x => console.log('trailerChatNotifications', x))
        );
        const driverChatNotifications$ = this.towRequestsInProgress$.pipe(
            switchMap(towRequests => combineLatest([
                of(towRequests),
                this.store.select(ChatStoreSelectors.getChats(towRequests.map(x => x.tow.id))),
                this.userId$
            ])),
            map(([towRequests, chats, userId]) => {
                const towRequestsWithUnreadChatMessages: TowRequest[] = [];
                chats.forEach(chat => {
                    if (chat.messages.filter(x => !x.read && x.userId !== userId).length > 0) {
                        const towRequest = towRequests.find(x => x.tow.id === chat.towId);
                        if (towRequest) {
                            towRequestsWithUnreadChatMessages.push(towRequest);
                        }
                    };
                });
                const notifications = this.getChatsNotifications(towRequestsWithUnreadChatMessages, 'driver');
                return notifications;
            }),
            tap(x => console.log('driverChatNotifications', x))
        );
        return combineLatest([
            trailerChatNotifications$,
            driverChatNotifications$
        ]).pipe(
            map(([trailerChatNotifications, driverChatNotifications]) => [...trailerChatNotifications, ...driverChatNotifications]),
            distinctUntilChanged((prev, curr) => ObjectHelper.areObjectsEqual(prev, curr)),
            shareReplay(),
            tap(x => console.log('chatNotifications', x))
        );
    }

    private getChatsNotifications(towRequests: TowRequest[], experience: Experience): AppNotification[] {
        const notifications: AppNotification[] = [];

        towRequests.forEach(towRequest => {
            notifications.push(this.getChatNotification(towRequest, experience));
        });

        return notifications;
    }

    private getChatNotification(towRequest: TowRequest, experience: Experience): AppNotification {
        const path = experience === 'trailer' ?
            `${towRequest.tow.trailer.id}` :
            `${towRequest.driverDetails.vehicle.id}/${towRequest.id}`;
        const url = `/${experience}/map/${path}/chat`;
        let from = '';

        if (experience === 'trailer') {
            const driverDetails = towRequest.driverDetails;
            from = driverDetails ? ` from ${driverDetails.user.firstName} ${driverDetails.user.lastName} ` +
                `(${this.vehicleSummaryPipe.transform(driverDetails.vehicle)})` : '';
        } else {
            const user = towRequest.tow.user;
            from = ` from ${user.firstName} ${user.lastName}`;
        }

        const title = 'New Message';
        const message = `You have a new message${from}.`;
        const messages: AppNotificationMessage[] = [{
            message,
            noEllipsis: true
        }];
        const notification: AppNotification = {
            id: `chat${towRequest.id}`,
            icon: {
                material: 'chat'
            },
            title,
            messages,
            route: [url]
        };
        return notification;
    }
}
