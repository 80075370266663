import { NgModule } from '@angular/core';
import { components } from './components';
import { modules } from './modules';
import { pipes } from './pipes';
import { CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';

@NgModule({
    declarations: [
        ...components,
        ...pipes
    ],
    imports: [
        ...modules
    ],
    exports: [
        ...modules,
        ...components,
        ...pipes
    ],
    providers: [
        ...pipes,
        DatePipe,
        CurrencyPipe,
        DecimalPipe
    ]
})
export class SharedModule {}
