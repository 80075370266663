import { Injectable } from '@angular/core';
import { DbService } from './db.service';
import { Support } from '@models/support.model';
import { Observable, Subject } from 'rxjs';
import { Filter } from '@models/filter.model';
import { ObjectHelper } from '@classes/object-helper';
import { Unsubscribe } from '@angular/fire/firestore';
import { Sort } from '@models/sort.model';

const COLLECTION = 'support';

@Injectable({
    providedIn: 'root'
})
export class SupportService {
    constructor(
        private dbService: DbService
    ) {}

    getSupports(userId?: string, resolved = false): Observable<Support[]> {
        const filters: Filter[] = [{ field: 'resolved', operator: '==', value: resolved }];

        if (userId) {
            filters.push({ field: 'userId', operator: '==', value: userId });
        }

        const sort: Sort = { field: 'creationDate', direction: 'desc' };
        const otherFixes = (support: Support) => this.fixDates(support);
        return this.dbService.getList(COLLECTION, filters, sort, undefined, undefined, otherFixes);
    }

    saveSupport(support: Support): Observable<Support> {
        return this.dbService.saveObj(COLLECTION, support);
    }

    getSupportChanges(id: string, subs$: Subject<Support>): Unsubscribe {
        const otherFixes = (support: Support) => this.fixDates(support);
        return this.dbService.getObjChanges(COLLECTION, id, subs$, otherFixes);
    }

    getSupportsChanges(subs$: Subject<Support[]>, excludeUserId: string, resolved = false): Unsubscribe {
        const filters: Filter[] = [
            { field: 'user.id', operator: '!=', value: excludeUserId },
            { field: 'resolved', operator: '==', value: resolved }
        ];
        const sort: Sort = { field: 'user.id', direction: 'asc' };
        const otherFixes = (support: Support) => this.fixDates(support);
        return this.dbService.getListChanges(COLLECTION, subs$, filters, sort, undefined, undefined, otherFixes);
    }

    private fixDates(support: Support): void {
        support.messages.forEach(message => {
            ObjectHelper.fixDate(message, 'creationDate');
        });
    }
}
