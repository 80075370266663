/* eslint-disable dot-notation */
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output,
    SimpleChanges } from '@angular/core';
import { ObjectHelper } from '@classes/object-helper';
import { UtilityHelper } from '@classes/utility-helper';
import { environment } from '@environments/environment';
import { AlertController } from '@ionic/angular';
import { GeoData } from '@models/geo-data.model';
import { Marker } from '@models/marker.model';
import { RouteData } from '@models/route-data.model';
import { Route } from '@models/route.model';
import { BehaviorSubject, combineLatest, distinctUntilChanged, filter, take } from 'rxjs';
import { SubSink } from 'subsink';

const INITIAL_ZOOM = 12;
const ROUTE_MARKER_KEYS = ['start', 'destination', 'stop'];
const DROP_ANIMATION_CLASS = 'drop';
const BOUNCE_ANIMATION_CLASS = 'bounce';
const STOP_BOUNCE_ANIMATION_CLASS = 'stop-bounce';

@Component({
    selector: 'app-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapComponent implements OnInit, OnChanges {
    @Input() center!: GeoData | null;
    @Input() markers!: Marker[] | null;
    @Input() centerMarker!: string | null;
    @Output() mapLoaded = new EventEmitter<boolean>();
    @Output() routeSet = new EventEmitter<RouteData>();
    @Output() selectedMarker = new EventEmitter<Marker>();

    private map!: google.maps.Map;
    private addedMarkers = new Map<string, google.maps.marker.AdvancedMarkerElement>();
    private directionsService!: google.maps.DirectionsService;
    private directionsRenderer!: google.maps.DirectionsRenderer;
    private lastRoute?: { a: GeoData, b: GeoData };
    private activeMarker?: google.maps.marker.AdvancedMarkerElement;
    private map$ = new BehaviorSubject<google.maps.Map | null>(null);
    private center$ = new BehaviorSubject<GeoData | null>(null);
    private centerMarker$ = new BehaviorSubject<string | null>(null);
    private subs = new SubSink();
    private reCenterMap$ = new BehaviorSubject(false);

    constructor(
        private element: ElementRef,
        private alertController: AlertController
    ) {}

    ngOnInit(): void {
        // Sometimes this executes before google has been loaded so let's retry if it fails
        let done = false;
        const timer = setInterval(() => {
            try {
                if (done) {
                    clearInterval(timer);
                    return;
                }

                this.init();
                done = true;
            } catch(e) {
                console.warn(e);
            }
        }, 500);

        this.subs.add(
            combineLatest([this.map$, this.center$, this.centerMarker$]).pipe(
                filter(([map, center, centerMarker]) => !!map && !!center && !!centerMarker),
                take(1)
            ).subscribe(([_, center, centerMarker]) => {
                this.addCenterMarker(center!, centerMarker!);
            }),

            combineLatest([this.reCenterMap$, this.center$, this.map$]).pipe(
                filter(([reCenterMap, center, map]) => reCenterMap && !!center && !!map),
            ).subscribe(([_, center, map]) => {
                const origin = new google.maps.LatLng(center!.latitude, center!.longitude);
                map!.panTo(origin);
            }),

            this.centerMarker$.pipe(
                filter(centerMarker => !!centerMarker),
                distinctUntilChanged()
            ).subscribe(centerMarker => {
                const url = `/assets/svg/${centerMarker}.svg`;
                this.updateMarkerIcon('center', url);
            })
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.removeMarkers();
        this.addMarkers();
        this.handleChanges(changes);
    }

    setRoute(route: Route, stops?: GeoData[], clearLastRoute = false): void {
        // If we have stops (we are shoing the complete route), reset the last route
        const complete = !!stops && stops.length > 0;
        if (complete || clearLastRoute) {
            this.lastRoute = undefined;
        }

        // Get the start (pickup location) from the stops (if passed). Otherwise, from start
        const { start, destination } = route;
        const a = stops?.[0] ? stops?.[0] : start;
        const data = { a, b: destination };

        // If this is the the same route we last set, stop
        if (ObjectHelper.areObjectsEqual(data, this.lastRoute)) {
            return;
        }

        this.lastRoute = data;

        // Sometimes this executes before google has been loaded so let's retry if it fails
        let done = false;
        const timer = setInterval(() => {
            try {
                if (done) {
                    clearInterval(timer);
                    return;
                }

                if (!this.directionsRenderer) {
                    return;
                }

                this.clearRoute();

                this.directionsRenderer.setMap(this.map);
                const origin = new google.maps.LatLng(start.latitude, start.longitude);
                const waypoints = this.getWaypoints(stops);
                const request = {
                    origin,
                    destination: new google.maps.LatLng(destination.latitude, destination.longitude),
                    travelMode: 'DRIVING',
                    waypoints
                } as google.maps.DirectionsRequest;

                this.directionsService.route(request, async (result, status) => {
                    if (status !== 'OK') {
                        console.error(`Directions request failed due to ${status}`);
                        await this.presentAlert();
                        return;
                    }

                    this.directionsRenderer.setDirections(result); // Add route to the map
                    const routeData = this.getRouteData(result, route);
                    routeData.complete = complete;
                    this.routeSet.emit(routeData);

                    this.map.setCenter(origin);
                    this.setZoom(routeData.distance);
                    this.addRouteMarkers(route, stops?.[0]);
                });

                done = true;
            } catch(e) {
                console.warn(e);
            }
        }, 500);
    }

    clearRoute(): void {
        this.lastRoute = undefined;

        if (!this.directionsRenderer) {
            return;
        }

        this.directionsRenderer.setMap(null);
        this.removeRouteMarkers();
        this.removeDriverMarker();
    }

    setZoom(distance: number): void {
        let zoom = 8;

        if (distance <= 5) {
            zoom = 11;
        } else if (distance <= 15) {
            zoom = 10;
        } else if (distance <= 30) {
            zoom = 9;
        }

        this.map?.setZoom(zoom);
    }

    stopActiveMarkerAnimation(): void {
        if (!this.activeMarker) {
            return;
        }

        this.stopMarkerAnimation(this.activeMarker);
    }

    startActiveMarkerAnimation(): void {
        if (!this.activeMarker) {
            return;
        }

        this.startMarkerAnimation(this.activeMarker);
    }

    updateCenterMarker(geoData: GeoData): void {
        this.center$.next(geoData);

        const marker = this.addedMarkers.get('center');
        if (!marker) {
            return;
        }

        const latlng = new google.maps.LatLng(geoData.latitude, geoData.longitude);
        marker.position = latlng;
    }

    removeCenterMarker(): void {
        this.removeMarker('center');
    }

    updateMarkersIcon(markers: Map<string, string>): void {
        markers.forEach((url, key) => this.updateMarkerIcon(key, url));
    }

    reCenterMap(): void {
        this.reCenterMap$.next(true);
    }

    addDriverMarker(geoData: GeoData): void {
        const marker = this.addedMarkers.get('driver');
        if (marker) {
            return;
        }

        this.addMarker({
            id: 'driver',
            geoData,
            icon: '/assets/svg/vehicle-pin-active.svg',
            zIndex: 2,
            unclickable: true
        });
    }

    updateDriverMarker(geoData: GeoData): void {
        const marker = this.addedMarkers.get('driver');
        if (!marker) {
            return;
        }

        const latlng = new google.maps.LatLng(geoData.latitude, geoData.longitude);
        marker.position = latlng;
    }

    removeDriverMarker(): void {
        this.removeMarker('driver');
    }

    unsubscribe(): void {
        this.subs.unsubscribe();
    }

    // private circle!: any;
    // private drawCircle(distance: number): void {
    //     if (this.circle) {
    //         this.circle.setMap(null);
    //     }

    //     const latitude = this.center!.latitude;
    //     const longitude = this.center!.longitude;
    //     const latLng = new google.maps.LatLng(latitude, longitude);
    //     this.circle = new google.maps.Circle({
    //         strokeColor: '#FF0000',
    //         strokeOpacity: 0.8,
    //         strokeWeight: 2,
    //         fillColor: '#FF0000',
    //         fillOpacity: 0.35,
    //         map: this.map,
    //         center: latLng,
    //         radius: GeoHelper.getMeters(distance)
    //     });
    // }

    private init(): void {
        this.mapLoaded.emit();

        if (!this.center) {
            return;
        }

        const latitude = this.center.latitude;
        const longitude = this.center.longitude;
        const latLng = new google.maps.LatLng(latitude, longitude);
        const mapOptions: google.maps.MapOptions = {
            center: latLng,
            zoom: INITIAL_ZOOM,
            mapTypeControl: false,
            fullscreenControl: false,
            rotateControl: false,
            streetViewControl: false,
            zoomControl: false,
            mapTypeId: google.maps.MapTypeId.ROADMAP
        };
        (mapOptions as any).mapId = environment.googleApi.mapId;
        this.map = new google.maps.Map(this.element.nativeElement, mapOptions);
        this.directionsService = new google.maps.DirectionsService();
        this.directionsRenderer = new google.maps.DirectionsRenderer({
            preserveViewport: true,
            suppressMarkers: true
        });
        this.map$.next(this.map);
    }

    private addMarkers(): void {
        this.markers?.forEach(x => this.addMarker(x));
    }

    private addMarker(marker: Marker): void {
        const latLng = new google.maps.LatLng(marker.geoData.latitude, marker.geoData.longitude);
        const key = marker.id;
        const keys = this.getAddedMarkersKeys();
        if (keys.includes(key)) {
            return;
        }

        const icon = marker.icon ? this.getIcon(marker.icon) : undefined;
        icon?.classList.add(DROP_ANIMATION_CLASS);
        const m = new google.maps.marker.AdvancedMarkerElement({
            map: this.map,
            position: latLng,
            content: icon,
            zIndex: marker.zIndex ?? 0,
            gmpClickable: true
        });

        // Handle the removal of the bounce class when animation is flagged to stop
        m.content?.addEventListener('animationiteration', e => {
            const event = e as AnimationEvent;
            if (event.animationName !== BOUNCE_ANIMATION_CLASS) {
                return;
            }

            const content = event.target as HTMLImageElement;
            if (content.classList.contains(STOP_BOUNCE_ANIMATION_CLASS)) {
                content.classList.remove(BOUNCE_ANIMATION_CLASS, STOP_BOUNCE_ANIMATION_CLASS);
            }
        }, false);

        if (!marker.unclickable) {
            m.addListener('click', () => {
                this.selectedMarker.emit(marker);

                // If the selected marker is different than the active one, stop the animation of the active one
                if (this.activeMarker !== m) {
                    this.stopActiveMarkerAnimation();
                }

                // If the selected marker is already animated, stop. Othwerwise, make it bounce
                const content = m.content as HTMLImageElement;
                if (content.classList.contains(BOUNCE_ANIMATION_CLASS)) {
                    this.stopMarkerAnimation(m);
                } else {
                    this.startMarkerAnimation(m);
                }

                this.activeMarker = m;
            });
        }

        this.addedMarkers.set(key, m);
    }

    private startMarkerAnimation(marker: google.maps.marker.AdvancedMarkerElement): void {
        const content = marker.content as HTMLImageElement;
        content.classList.remove(DROP_ANIMATION_CLASS, STOP_BOUNCE_ANIMATION_CLASS);
        content.classList.add(BOUNCE_ANIMATION_CLASS);
    }

    private stopMarkerAnimation(marker: google.maps.marker.AdvancedMarkerElement): void {
        const content = marker.content as HTMLImageElement;
        content.classList.add(STOP_BOUNCE_ANIMATION_CLASS);
    }

    private getAddedMarkersKeys(): string[] {
        return Array.from(this.addedMarkers.keys());
    }

    private removeMarkers(): void {
        const addedKeys = this.getAddedMarkersKeys();
        const keys = this.markers?.map(x => x.id) ?? [];
        const staticKeys = ['center', ...ROUTE_MARKER_KEYS];
        const removeKeys = addedKeys.filter(x => !keys.includes(x) && !staticKeys.includes(x));
        removeKeys.forEach(key => {
            this.removeMarker(key);
        });
    }

    private removeMarker(key: string): void {
        const marker = this.addedMarkers.get(key);
        if (!marker) {
            return;
        }

        marker.map = null;
        this.addedMarkers.delete(key);
    }

    private getWaypoints(stops?: GeoData[]): google.maps.DirectionsWaypoint[] {
        const waypoints: google.maps.DirectionsWaypoint[] = [];
        stops?.forEach(stop => {
            const location = new google.maps.LatLng(stop.latitude, stop.longitude);
            const waypoint = { location, stopover: true } as google.maps.DirectionsWaypoint;
            waypoints.push(waypoint);
        });
        return waypoints;
    }

    private getRouteData(result: google.maps.DirectionsResult | null, route: Route): RouteData {
        let distance = 0;
        let duration = 0;
        let startAddress!: string;
        let destinationAddress!: string;
        result?.routes.forEach(directionsRoute => {
            directionsRoute.legs.forEach((leg, i) => {
                distance += leg.distance && leg.distance.text.includes('mi') ?
                    UtilityHelper.removeNonDecimals(leg.distance.text) : 0;
                duration += leg.duration?.value ?? 0;

                if (i === 0) {
                    startAddress = leg.start_address;
                }

                if (i === directionsRoute.legs.length - 1) {
                    destinationAddress = leg.end_address;
                }
            });
        });
        const { start, destination } = route;
        const routeData: RouteData = {
            distance,
            duration,
            start: { ...start, address: startAddress },
            destination: { ...destination, address: destinationAddress },
        };
        return routeData;
    }

    private async presentAlert(): Promise<void> {
        const alert = await this.alertController.create({
            header: 'Something Went Wrong',
            message: 'We could not calculate your route at this time. Please close the app and try again.',
            buttons: ['OK']
        });
        await alert.present();
    }

    private handleChanges(changes: SimpleChanges): void {
        const center = changes['center']?.currentValue;
        if (center) {
            this.center$.next(center);
        }

        const centerMarker = changes['centerMarker']?.currentValue;
        if (centerMarker) {
            this.centerMarker$.next(centerMarker);
        }
    }

    private addCenterMarker(geoData: GeoData, centerMarker: string): void {
        this.addMarker({
            id: 'center',
            geoData,
            icon: `/assets/svg/${centerMarker}.svg`,
            zIndex: 1,
            unclickable: true
        });
    }

    private getIcon(url: string): HTMLImageElement {
        const icon = document.createElement('img');
        icon.src = url;
        icon.classList.add('map-icon');
        return icon;
    }

    private addRouteMarkers(route: Route, stop?: GeoData): void {
        this.removeRouteMarkers();

        this.addMarker({
            id: 'start',
            geoData: route.start,
            icon: '/assets/svg/pin-a.svg',
            zIndex: 1,
            unclickable: true
        });
        this.addMarker({
            id: 'destination',
            geoData: route.destination,
            icon: `/assets/svg/pin-${stop ? 'c' : 'b'}.svg`,
            zIndex: 1,
            unclickable: true
        });

        if (stop) {
            this.addMarker({
                id: 'stop',
                geoData: stop,
                icon: '/assets/svg/pin-b.svg',
                zIndex: 1,
                unclickable: true
            });
        }
    }

    private removeRouteMarkers(): void {
        ROUTE_MARKER_KEYS.forEach(key => {
            this.removeMarker(key);
        });
    }

    private updateMarkerIcon(key: string, url: string): void {
        const marker = this.addedMarkers.get(key);
        if (!marker) {
            return;
        }

        const icon = this.getIcon(url);
        marker.content = icon;
    }
}
