import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as actions from './actions';
import { CommunicationService } from '@services/communication.service';

@Injectable()
export class SMSStoreEffects {
    constructor(
        private actions$: Actions,
        private communicationService: CommunicationService
    ) {}

    // Send SMS
    sendSMS$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.sendSMSRequest),
            switchMap(action => this.communicationService.sendSMS(action.sms).pipe(
                map(sms => actions.sendSMSSuccess({ sms })),
                catchError(error => of(actions.sendSMSFailure({ error })))
            ))
        )
    );
}
