import { Preferences } from '@capacitor/preferences';
import { Observable, from, map } from 'rxjs';

export const READ_MISSION = 'readMission';
export const GEO_LOCATION_GRANTED = 'geoLocationGranted';
export const LAST_ACCESS_DATE = 'lastAccessDate';
export const EMAILS = 'emails';
export const DONT_SHOW_MAP_INSTRUCTIONS = 'dontShowMapInstrutions';
export const DRIVER_ACTIVE = 'driverActive';
export const DONT_SHOW_SUPPORT_MESSAGE = 'dontShowSupportMessage';

export class LocalStorage {
    static set<T>(key: string, value: T, stringify = false): Observable<void> {
        return from(Preferences.set({ key, value: stringify ? JSON.stringify(value) : `${value}` }));
    };

    static get<T>(key: string, type: 'string' | 'number' | 'boolean' | 'Date' | 'object' | 'list' | null):
        Observable<string | number | boolean | Date | T | T[] | null> {
        return from(Preferences.get({ key })).pipe(
            map(({ value }) => {
                if (!value) {
                    return null;
                }

                switch (type) {
                    case 'number':
                        return +value;
                    case 'boolean':
                        return value === 'true';
                    case 'Date':
                        return new Date(value);
                    case 'object':
                        return JSON.parse(value) as T;
                    case 'list':
                        return JSON.parse(value) as T[];
                    case 'string':
                    default:
                        return value;
                }
            })
        );
    };
}
