import { PhonePipe } from './pipes/phone.pipe';
import { DurationPipe } from './pipes/duration.pipe';
import { VehicleSummaryPipe } from './pipes/vehicle-summary.pipe';
import { TrailerSummaryPipe } from './pipes/trailer-summary.pipe';
import { TwoLineAddressPipe } from './pipes/two-line-address.pipe';

export const pipes = [
    PhonePipe,
    DurationPipe,
    VehicleSummaryPipe,
    TrailerSummaryPipe,
    TwoLineAddressPipe
];
