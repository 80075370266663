import { adapter, initialState, State } from './state';
import { createReducer, on, Action } from '@ngrx/store';
import { clearTowRequests, deleteTowRequestFailure, deleteTowRequestRequest, deleteTowRequestSuccess, getTowRequestFailure,
    getTowRequestRequest, getTowRequestsChangesRequest, getTowRequestsChangesSuccess, getTowRequestsFailure, getTowRequestsRequest,
    getTowRequestsSuccess, getTowRequestSuccess, saveTowRequestFailure, saveTowRequestRequest, saveTowRequestSuccess,
    updateTowRequestsFailure, updateTowRequestsRequest, updateTowRequestsSuccess } from './actions';
import { TowRequest } from '@models/tow-request.model';
import { getTowChangesLoaded } from '../tow-store/actions';

export const towRequestReducer = createReducer(
    initialState,
    on(
        getTowRequestsRequest,
        getTowRequestsChangesRequest,
        getTowRequestRequest,
        saveTowRequestRequest,
        deleteTowRequestRequest,
        updateTowRequestsRequest,
        state => ({
            ...state,
            loading: true,
            error: null
        })
    ),
    on(
        getTowRequestsFailure,
        getTowRequestFailure,
        saveTowRequestFailure,
        deleteTowRequestFailure,
        updateTowRequestsFailure,
        (state, { error }) => ({
            ...state,
            loading: false,
            error
        })
    ),
    on(
        getTowChangesLoaded,
        (state) => ({
            ...state,
            loading: false
        })
    ),

    // Get Tow Requests/Changes
    on(
        getTowRequestsSuccess,
        getTowRequestsChangesSuccess,
        (state, { towRequests }) => adapter.upsertMany(towRequests, {
            ...state,
            loading: false,
            error: null,
            total: towRequests.length
        })
    ),

    // Get/Save Tow Request
    on(
        getTowRequestSuccess,
        saveTowRequestSuccess,
        (state, { towRequest }) => adapter.upsertOne(towRequest, {
            ...state,
            loading: false,
            error: null
        })
    ),

    // Delete Tow Request
    on(
        deleteTowRequestSuccess,
        (state, { id }) => adapter.removeOne(id, {
            ...state,
            loading: false,
            error: null
        })
    ),

    // Clear Tow Requests
    on(
        clearTowRequests,
        (state, { userId }) => {
            const towRequests = Object.values(state.entities) as TowRequest[];
            const ids = towRequests.filter(x => x.driverDetails.user.id !== userId).map(x => x.id);
            return adapter.removeMany(ids, {
                ...state,
                loading: false,
                error: null
            });
        }
    ),

    // Update Tow Requests
    on(
        updateTowRequestsSuccess,
        (state, { towRequests }) => adapter.upsertMany(towRequests, {
            ...state,
            loading: false,
            error: null
        })
    )
);

export function reducer(state: State, action: Action) {
    return towRequestReducer(state, action);
}
