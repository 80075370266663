import { adapter, initialState, State } from './state';
import { createReducer, on, Action } from '@ngrx/store';
import { sendSMSFailure, sendSMSRequest, sendSMSSuccess } from './actions';

export const smsReducer = createReducer(
    initialState,
    on(
        sendSMSRequest,
        state => ({
            ...state,
            loading: true,
            error: null
        })
    ),
    on(
        sendSMSFailure,
        (state, { error }) => ({
            ...state,
            loading: false,
            error
        })
    ),

    // Send SMS
    on(
        sendSMSSuccess,
        (state, { sms }) => adapter.upsertOne(sms, {
            ...state,
            loading: false,
            error: null
        })
    )
);

export function reducer(state: State, action: Action) {
    return smsReducer(state, action);
}
