import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';

const phoneUtil = PhoneNumberUtil.getInstance();

export class PhoneHelper {
    static formatPhone(str: string): string {
        const phone = this.cleanPhone(str);
        if (!phone || phone.length < 10) {
            return str;
        }

        if (phone.length > 10) {
            const lastIndex = phone.length;
            const part2 = phone.substring(lastIndex - 4, lastIndex);
            const part1 = phone.substring(lastIndex - 8, lastIndex - 4);
            let areaCode = phone.substring(0, lastIndex - 8);
            let countryCode = '';
            if (areaCode.length > 3) {
                const temp = areaCode;
                areaCode = temp.substring(temp.length - 2, temp.length);
                countryCode = temp.substring(0, temp.length - 2);
            }
            return `${countryCode} ${areaCode}-${part1}-${part2}`;
        }

        const usPhone = phoneUtil.parseAndKeepRawInput(str, 'US');
        return phoneUtil.format(usPhone, PhoneNumberFormat.NATIONAL);
    }

    static cleanPhone(phone: string): string {
        return phone?.replace(/[^0-9]/g, '');
    }

    static isValidPhone(str: string): boolean {
        const phone = this.cleanPhone(str);
        if (!phone) {
            return false;
        }

        if (str.length < 10) {
            return false;
        }

        if (str.length > 10) {
            return true;
        }

        const usPhone = phoneUtil.parseAndKeepRawInput(str, 'US');
        return phoneUtil.isPossibleNumber(usPhone);
    }
}
