import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges,
    ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { ChatMessage } from '@models/chat-message.model';
import { ChatUpdateRequest } from '@models/chat-update-request.model';
import { Chat } from '@models/chat.model';

const MAX_LENGTH = 300;

@Component({
    selector: 'app-chat',
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatComponent implements OnInit, OnChanges {
    @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;
    @ViewChild('history') history: ElementRef;
    @Input() chat!: Chat | null;
    @Input() userId!: string | null;
    @Input() isAdminSupport = false;
    @Input() adminIds: string[] | null = [];
    @Output() updateChat = new EventEmitter<ChatUpdateRequest>();

    form!: FormGroup;
    maxLength = MAX_LENGTH;

    constructor(
        private formBuilder: FormBuilder
    ) {}

    ngOnInit(): void {
        const validators = [Validators.required];

        if (!this.isAdminSupport) {
            validators.push(Validators.maxLength(this.maxLength));
        }

        this.form = this.formBuilder.group({
            message: new FormControl<string | null>(null, validators)
        });

        this.maxLength = this.isAdminSupport ? 524_288 : MAX_LENGTH;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.scroll();

        // eslint-disable-next-line dot-notation
        const chat = changes['chat'].currentValue;
        if (chat) {
            this.markMessagesAsRead(chat);
        }
    }

    onSubmit(): void {
        const message = this.form.value.message as string;
        const chatMessage: ChatMessage = {
            message,
            userId: this.userId!,
            creationDate: new Date(),
            read: false
        };
        const messages = [...this.chat?.messages ?? [], chatMessage];
        const chat = { ...this.chat ?? { messages }, messages } as Chat;
        this.updateChat.emit({ chat, message: chatMessage });
        this.formGroupDirective.resetForm();
    }

    private scroll(): void {
        // Timer needed cause on initial load "this.history" may be undefined
        const timer = setInterval(() => {
            if (!this.history) {
                return;
            }

            this.history.nativeElement.scrollTop = this.history.nativeElement.scrollHeight;
            clearInterval(timer);
        }, 100);
    }

    private markMessagesAsRead(chat: Chat): void {
        const unreadMessages = chat.messages.filter(x => x.userId !== this.userId && !x.read);
        if (unreadMessages.length === 0) {
            return;
        }

        const messages: ChatMessage[] = [];
        chat.messages.forEach(x => {
            const message = x.userId !== this.userId ? { ...x, read: true } : x;
            messages.push(message);
        });
        chat = { ...chat, messages };
        console.log(chat);
        this.updateChat.emit({ chat });
    }
}
