// eslint-disable-next-line no-shadow
export enum TrailerIcon {
    EnclosedLong = 1,
    EnclosedShort = 2,
    OpenBedLong = 3,
    OpenBedShort = 4,
    Boat = 5,
    RV = 6,
    Cargo = 7
}
