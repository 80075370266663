import { adapter, initialState, State } from './state';
import { createReducer, on, Action } from '@ngrx/store';
import { clearSupports, getSupportChangesLoaded, getSupportChangesRequest, getSupportChangesSuccess, getSupportsChangesLoaded,
    getSupportsChangesRequest, getSupportsChangesSuccess, getSupportsFailure, getSupportsRequest, getSupportsSuccess,
    saveSupportFailure, saveSupportRequest, saveSupportSuccess } from './actions';

export const supportReducer = createReducer(
    initialState,
    on(
        getSupportsRequest,
        saveSupportRequest,
        getSupportChangesRequest,
        getSupportsChangesRequest,
        state => ({
            ...state,
            loading: true,
            error: null
        })
    ),
    on(
        getSupportsFailure,
        saveSupportFailure,
        (state, { error }) => ({
            ...state,
            loading: false,
            error
        })
    ),
    on(
        getSupportChangesLoaded,
        getSupportsChangesLoaded,
        (state) => ({
            ...state,
            loading: false
        })
    ),

    // Get Supports/Get Supports Changes
    on(
        getSupportsSuccess,
        getSupportsChangesSuccess,
        (state, { supports }) => adapter.upsertMany(supports, {
            ...state,
            loading: false,
            error: null,
            total: supports.length
        })
    ),

    // Save Support/Get Support Changes
    on(
        saveSupportSuccess,
        getSupportChangesSuccess,
        (state, { support }) => adapter.upsertOne(support, {
            ...state,
            loading: false,
            error: null
        })
    ),

    // Clear Supports
    on(
        clearSupports,
        (state) => adapter.removeAll({
            ...state,
            loading: false,
            error: null,
            total: 0
        })
    )
);

export function reducer(state: State, action: Action) {
    return supportReducer(state, action);
}
