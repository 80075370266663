import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { AllowedVehicle } from '@models/allowed-vehicle.model';

export const adapter: EntityAdapter<AllowedVehicle> = createEntityAdapter<AllowedVehicle>({
    selectId: allowedVehicle => allowedVehicle.id
});

export interface State extends EntityState<AllowedVehicle> {
    loading: boolean;
    error?: any;
    total: number;
}

export const initialState: State = adapter.getInitialState({
    loading: false,
    error: null,
    total: 0
});
