import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as actions from './actions';
import { PayoutService } from '@services/payout.service';

@Injectable()
export class EarningStoreEffects {
    constructor(
        private actions$: Actions,
        private payoutService: PayoutService
    ) {}

    // Get Earnings
    getEarnings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getEarningsRequest),
            switchMap(action => this.payoutService.getEarnings(action.userId).pipe(
                map(earnings => actions.getEarningsSuccess({ earnings })),
                catchError(error => of(actions.getEarningsFailure({ error })))
            ))
        )
    );
}
