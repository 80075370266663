import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Subject, combineLatest, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as actions from './actions';
import { UserService } from '@services/user.service';
import { DriverService } from '@services/driver.service';
import { User } from '@models/user.model';
import { Unsubscribe } from 'firebase/firestore';
import { SubSink } from 'subsink';
import { Store } from '@ngrx/store';

@Injectable()
export class UserStoreEffects {
    private unsubs: Unsubscribe[] = [];
    private subs = new SubSink();

    constructor(
        private actions$: Actions,
        private userService: UserService,
        private driverService: DriverService,
        private store: Store
    ) {}

    // Get Users
    getUsers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getUsersRequest),
            switchMap(action => {
                let users$ = this.userService.getUsers(action.center, action.radius, action.excludeId);

                if (action.includeDriverProfile) {
                    const drivers$ = users$.pipe(
                        switchMap(users => this.driverService.getDrivers(users.map(x => x.id)))
                    );
                    users$ = combineLatest([users$, drivers$]).pipe(
                        map(([users, drivers]) => {
                            const list: User[] = [];
                            drivers.forEach(driver => {
                                const user = users.find(x => x.id === driver.id);
                                if (!user) {
                                    return;
                                }

                                list.push({ ...user, driver });
                            });
                            return list;
                        })
                    );
                }

                return users$.pipe(
                    map(users => actions.getUsersSuccess({ users })),
                    catchError(error => of(actions.getUsersFailure({ error })))
                );
            })
        )
    );

    // Get User
    getUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getUserRequest),
            switchMap(action => this.userService.getUser(action.id).pipe(
                map(user => actions.getUserSuccess({ user })),
                catchError(error => of(actions.getUserFailure({ error })))
            ))
        )
    );

    // Save User
    saveUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.saveUserRequest),
            switchMap(action => this.userService.saveUser(action.user).pipe(
                map(user => actions.saveUserSuccess({ user })),
                catchError(error => of(actions.saveUserFailure({ error })))
            ))
        )
    );

    // Set User Current Location
    setUserCurrentLocation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.setUserCurrentLocationRequest),
            switchMap(action => this.userService.setUserCurrentLocation(action.user).pipe(
                map(user => actions.setUserCurrentLocationSuccess({ user })),
                catchError(error => of(actions.setUserCurrentLocationFailure({ error })))
            ))
        )
    );

    // Update User Properties
    updateUserProperties$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.updateUserPropertiesRequest),
            switchMap(action => this.userService.updateUserProperties(action.id, action.properties).pipe(
                map(partialUser => actions.updateUserPropertiesSuccess({ partialUser })),
                catchError(error => of(actions.updateUserPropertiesFailure({ error })))
            ))
        )
    );

    // Get User Changes
    getUserChanges$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getUserChangesRequest),
            map(action => {
                const sub$ = new Subject<User>();
                this.subs.add(
                    sub$.subscribe(user => this.store.dispatch(actions.getUserChangesSuccess({ user })))
                );
                const unsub = this.userService.getUserChanges(action.id, sub$);
                this.unsubs.push(unsub);
                return actions.getUserChangesLoaded();
            })
        )
    );

    // Unsubscribe User Changes
    unsubscribeUserChanges$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.unsubscribeUserChangesRequest),
            map(() => {
                this.unsubs.forEach(unsub => unsub());
                this.subs.unsubscribe();
                return actions.unsubscribeUserChangesSuccess();
            })
        )
    );

    // Get Users by Role
    getUsersByRole$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getUsersByRoleRequest),
            switchMap(action => this.userService.getUsersByRole(action.role).pipe(
                map(users => actions.getUsersByRoleSuccess({ users })),
                catchError(error => of(actions.getUsersByRoleFailure({ error })))
            ))
        )
    );
}
