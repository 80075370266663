<ng-container [formGroup]="form">
    <mat-form-field appearance="outline" class="autocomplete">
        <mat-label>Address</mat-label>
        <i class="material-symbols-outlined" matPrefix>home_pin</i>
        <input #address matInput formControlName="address">
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>City</mat-label>
        <i class="material-symbols-outlined" matPrefix>home_pin</i>
        <input matInput formControlName="city">
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>State</mat-label>
        <i class="material-symbols-outlined" matPrefix>home_pin</i>
        <mat-select formControlName="state">
            <mat-option *ngFor="let state of states$ | async" [value]="state.abbr">{{ state.name }}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>Zip Code</mat-label>
        <i class="material-symbols-outlined" matPrefix>home_pin </i>
        <input matInput formControlName="zipCode" type="tel" mask="00000">
    </mat-form-field>
</ng-container>
