import { Earning } from '@models/earning.model';
import { createAction, props } from '@ngrx/store';

// Get Earnings
export const getEarningsRequest = createAction(
    '[Earning] Get Earnings Request',
    props<{ userId: string }>()
);
export const getEarningsFailure = createAction(
    '[Earning] Get Earnings Failure',
    props<{ error: string }>()
);
export const getEarningsSuccess = createAction(
    '[Earning] Get Earnings Success',
    props<{ earnings: Earning[] }>()
);
