import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { State, ID } from './state';
import { Session } from '@models/session.model';
import { Experience } from 'src/app/shared/types/experience.type';
import { UserRole } from '@enums/user-role.enum';

export const getState: MemoizedSelector<object, State> = createFeatureSelector<State>('session');

export const getLoading: MemoizedSelector<object, boolean> = createSelector(getState, (state: State) => state.loading);

export const getError: MemoizedSelector<object, string> = createSelector(getState, (state: State) => state.error);

export const getSession: MemoizedSelector<object, Session> = createSelector(getState, (state: State) => state.entities[ID]!);

export const getUserId: MemoizedSelector<object, string> = createSelector(getState, (state: State) => state.entities[ID]?.userId!);

export const getExperience: MemoizedSelector<object, Experience> = createSelector(getState, (state: State) =>
    state.entities[ID]?.experience!);

export const isAdmin: MemoizedSelector<object, boolean> = createSelector(getState, (state: State) =>
    state.entities[ID]?.userRole === UserRole.Admin);

// export const getBankAccountSessionSecret: MemoizedSelector<object, string> =
//     createSelector(getState, (state: State) => state.entities[ID]?.bankAccountSessionSecret!);
