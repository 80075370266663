import { ChangeContext, Options } from 'ngx-slider-v2';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-slider',
    templateUrl: './slider.component.html',
    styleUrls: ['./slider.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SliderComponent implements OnInit {
    @Input() min!: number;
    @Input() max!: number;
    @Input() step = 1;
    @Input() value = 0;
    @Output() valueChangeEnd = new EventEmitter<number>();

    sliderOptions!: Options;

    ngOnInit(): void {
        this.sliderOptions = {
            floor: this.min,
            ceil: this.max,
            step: this.step,
            vertical: true,
            translate: (value: number): string => `${value}<div>mi</div>`
        };
    }

    onValueChangeEnd(context: ChangeContext): void {
        this.valueChangeEnd.emit(context.value);
    }
}
