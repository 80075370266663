import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { from, Observable, switchMap } from 'rxjs';
import { driverMapViewTowDetails$ } from 'src/app/driver/driver-map/driver-map.component';
import { trailerMapShowNotifications$ } from 'src/app/trailer/trailer-map/trailer-map.component';

@Injectable({
    providedIn: 'root'
})
export class UtilityService {
    constructor(
        private alertController: AlertController,
        private router: Router
    ) {}

    prompt(header: string, message: string, acceptBtn: string, denyBtn: string, acceptFn: Function, denyFn?: Function):
        Observable<void> {
        const alert$ = from(this.alertController.create({
            header,
            message,
            buttons: [{
                text: denyBtn,
                role: 'cancel',
                cssClass: 'secondary',
                handler: () => denyFn?.()
            }, {
                text: acceptBtn,
                handler: () => acceptFn()
            }]
        }));
        return alert$.pipe(
            switchMap(alert => alert.present())
        );
    }

    navigate(route: string): void {
        // If the current route is the same as the one we want to navigate to, trigger subjects in the corresponding components
        const currentRoute = this.router.url;
        if (route === currentRoute) {
            if (route.startsWith('/trailer/map/')) {
                trailerMapShowNotifications$.next(true);
            } else if (route.startsWith('/driver/map/')) {
                driverMapViewTowDetails$.next(true);
            }
            return;
        }

        this.router.navigate([route]);
    }
}
