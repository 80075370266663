import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { RouteStoreEffects } from './effects';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        EffectsModule.forFeature([RouteStoreEffects])
    ],
    providers: []
})
export class RouteStoreModule {}
