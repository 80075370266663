import { Disbursement } from '@models/disbursement.model';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export const adapter: EntityAdapter<Disbursement> = createEntityAdapter<Disbursement>({
    selectId: disbursement => disbursement.id
});

export interface State extends EntityState<Disbursement> {
    loading: boolean;
    error?: any;
    total: number;
}

export const initialState: State = adapter.getInitialState({
    loading: false,
    error: null,
    total: 0
});
