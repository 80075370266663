import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Rating } from '@models/rating.model';

export const adapter: EntityAdapter<Rating> = createEntityAdapter<Rating>({
    selectId: rating => rating.id
});

export interface State extends EntityState<Rating> {
    loading: boolean;
    error?: any;
    total: number;
}

export const initialState: State = adapter.getInitialState({
    loading: false,
    error: null,
    total: 0
});
