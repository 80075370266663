import { Support } from '@models/support.model';
import { createAction, props } from '@ngrx/store';

// Get Supports
export const getSupportsRequest = createAction(
    '[Support] Get Supports Request',
    props<{ userId?: string, resolved: boolean }>()
);
export const getSupportsFailure = createAction(
    '[Support] Get Supports Failure',
    props<{ error: string }>()
);
export const getSupportsSuccess = createAction(
    '[Support] Get Supports Success',
    props<{ supports: Support[] }>()
);

// Save Support
export const saveSupportRequest = createAction(
    '[Support] Save Support Request',
    props<{ support: Support }>()
);
export const saveSupportFailure = createAction(
    '[Support] Save Support Failure',
    props<{ error: string }>()
);
export const saveSupportSuccess = createAction(
    '[Support] Save Support Success',
    props<{ support: Support }>()
);

// Get Support Changes
export const getSupportChangesRequest = createAction(
    '[Support] Get Support Changes Request',
    props<{ id: string }>()
);
export const getSupportChangesLoaded = createAction(
    '[Support] Get Support Changes Loaded'
);
export const getSupportChangesSuccess = createAction(
    '[Support] Get Support Changes Success',
    props<{ support: Support }>()
);

// Unsubscribe Support Changes
export const unsubscribeSupportChangesRequest = createAction(
    '[Support] Unsubscribe Support Changes Request'
);
export const unsubscribeSupportChangesSuccess = createAction(
    '[Support] Unsubscribe Support Changes Success'
);

// Get Supports Changes
export const getSupportsChangesRequest = createAction(
    '[Support] Get Supports Changes Request',
    props<{ excludeUserId: string, resolved: boolean }>()
);
export const getSupportsChangesLoaded = createAction(
    '[Support] Get Supports Changes Loaded'
);
export const getSupportsChangesSuccess = createAction(
    '[Support] Get Supports Changes Success',
    props<{ supports: Support[] }>()
);

// Unsubscribe Supports Changes
export const unsubscribeSupportsChangesRequest = createAction(
    '[Support] Unsubscribe Supports Changes Request'
);
export const unsubscribeSupportsChangesSuccess = createAction(
    '[Support] Unsubscribe Supports Changes Success'
);

// Clear Supports
export const clearSupports = createAction(
    '[Support] Clear Supports'
);
