import { adapter, initialState, State } from './state';
import { createReducer, on, Action } from '@ngrx/store';
import { savePaymentFailure, savePaymentRequest, getPaymentsFailure, getPaymentsRequest, getPaymentsSuccess, savePaymentSuccess,
    setDefaultPaymentRequest, setDefaultPaymentFailure, setDefaultPaymentSuccess, deletePaymentSuccess, deletePaymentRequest,
    deletePaymentFailure } from './actions';
import { Payment } from '@models/payment.model';

const getPayments = (state: State) => {
    const payments = Object.entries(state.entities).map(x => x[1]) as Payment[];
    return payments;
};

export const paymentReducer = createReducer(
    initialState,
    on(
        getPaymentsRequest,
        savePaymentRequest,
        setDefaultPaymentRequest,
        deletePaymentRequest,
        state => ({
            ...state,
            loading: true,
            error: null
        })
    ),
    on(
        getPaymentsFailure,
        savePaymentFailure,
        setDefaultPaymentFailure,
        deletePaymentFailure,
        (state, { error }) => ({
            ...state,
            loading: false,
            error
        })
    ),

    // Get Payments
    on(
        getPaymentsSuccess,
        (state, { payments }) => adapter.upsertMany(payments, {
            ...state,
            loading: false,
            error: null
        })
    ),

    // Save Payment
    on(
        savePaymentSuccess,
        (state, { payment }) => {
            // If the payment is set as default, then set default to false the existing payments
            if (payment.default) {
                const updatedPayments: Payment[] = [payment];
                const payments = getPayments(state);
                payments.forEach(x => {
                    updatedPayments.push({ ...x, default: false });
                });
                return adapter.setAll(updatedPayments, {
                    ...state,
                    loading: false,
                    error: null
                });
            }

            return adapter.upsertOne(payment, {
                ...state,
                loading: false,
                error: null
            });
        }
    ),

    // Set Default Payment
    on(
        setDefaultPaymentSuccess,
        (state, { payment }) => {
            const updatedPayments: Payment[] = [];
            const payments = getPayments(state);
            payments.forEach(x => {
                updatedPayments.push({ ...x, default: x.id === payment.id });
            });
            return adapter.upsertMany(updatedPayments, {
                ...state,
                loading: false,
                error: null
            });
        }
    ),

    // Delete Payment
    on(
        deletePaymentSuccess,
        (state, { payment }) => {
            return adapter.removeOne(payment.id, {
                ...state,
                loading: false,
                error: null
            });
        }
    )
);

export function reducer(state: State, action: Action) {
    return paymentReducer(state, action);
}
