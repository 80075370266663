import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { environment } from '@environments/environment';
import { TowDetails } from '@models/tow-details.model';
import { TowRequest } from '@models/tow-request.model';

@Component({
    selector: 'app-driver-map-tow-details',
    templateUrl: './driver-map-tow-details.component.html',
    styleUrls: ['./driver-map-tow-details.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DriverMapTowDetailsComponent implements OnChanges {
    @Input() towDetails!: TowDetails | null;
    @Input() hide = true;
    @Output() toggleTowDetails = new EventEmitter<void>();
    @Output() viewTowDetails = new EventEmitter<TowDetails>();
    @Output() cancelTowRequest = new EventEmitter<TowRequest>();

    displayDefaultPhoto = false;
    status?: string;
    btnColor = 'primary';
    rating = environment.rating;

    constructor(
        private changeDetectorRef: ChangeDetectorRef
    ) {}

    ngOnChanges(event: any): void {
        this.handleDuplicateEmptyAvatar();

        if (event.towDetails) {
            this.displayDefaultPhoto = false;
        }
    }

    onToggleTowDetails(): void {
        this.toggleTowDetails.emit();
    }

    onPhotoError(): void {
        this.displayDefaultPhoto = true;
        this.changeDetectorRef.detectChanges();
    }

    onViewTowDetails(): void {
        this.viewTowDetails.emit(this.towDetails!);
    }

    async onCancelTowRequest(): Promise<void> {
        this.cancelTowRequest.emit(this.towDetails?.towRequest);
    }

    // For some strange reason sometimes there is a 2nd ion-avatar element with an empty image. This will take care of it
    private handleDuplicateEmptyAvatar(): void {
        const $img = document.querySelector('ion-avatar img:not([src])');
        $img?.parentElement?.remove();
    }
}
