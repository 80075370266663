import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'app-bouncing-arrow',
    templateUrl: './bouncing-arrow.component.html',
    styleUrls: ['./bouncing-arrow.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BouncingArrowComponent {
    @Input() direction: 'up' | 'down' = 'up';
}
