import { Payout } from '@models/payout.model';
import { createAction, props } from '@ngrx/store';

// Get Payout
export const getPayoutRequest = createAction(
    '[Payout] Get Payout Request',
    props<{ userId: string, route?: string }>()
);
export const getPayoutFailure = createAction(
    '[Payout] Get Payout Failure',
    props<{ error: string }>()
);
export const getPayoutSuccess = createAction(
    '[Payout] Get Payout Success',
    props<{ payout: Payout }>()
);

// Create Payout
export const createPayoutRequest = createAction(
    '[Payout] Create Payout Request',
    props<{ userId: string, route?: string }>()
);
export const createPayoutFailure = createAction(
    '[Payout] Create Payout Failure',
    props<{ error: string }>()
);
export const createPayoutSuccess = createAction(
    '[Payout] Create Payout Success',
    props<{ payout: Payout }>()
);
