import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as actions from './actions';
import { GasPriceService } from '@services/gas-price.service';

@Injectable()
export class GasPriceStoreEffects {
    constructor(
        private actions$: Actions,
        private gasPriceService: GasPriceService
    ) {}

    // Get Gas Price
    getGasPrice$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getGasPriceRequest),
            switchMap(action => this.gasPriceService.getGasPrice(action.state).pipe(
                map(gasPrice => actions.getGasPriceSuccess({ gasPrice })),
                catchError(error => of(actions.getGasPriceFailure({ error })))
            ))
        )
    );
}
