import { Injectable } from '@angular/core';
import { Cache } from '@classes/cache';
import { RateCategory } from '@enums/rate-category.enum';
import { RateType } from '@enums/rate-type.enum';
import { Filter } from '@models/filter.model';
import { Rate } from '@models/rate.model';
import { Observable, of, tap } from 'rxjs';
import { DbService } from './db.service';

const COLLECTION = 'rates';

@Injectable({
    providedIn: 'root'
})
export class RateService {
    constructor(
        private dbService: DbService
    ) {}

    getRates(category: RateCategory, type?: RateType): Observable<Rate[]> {
        const filters: Filter[] = [
            { field: 'category', operator: '==', value: category }
        ];

        if (type) {
            filters.push({ field: 'type', operator: '==', value: type });
        }

        const key = JSON.stringify(filters);
        const cache = Cache.get<Rate[]>(key);
        if (cache) {
            return of(type ? cache.filter(x => x.type === type) : cache);
        }

        return this.dbService.getList<Rate>(COLLECTION, filters).pipe(
            tap(rates => Cache.add(key, rates))
        );
    }
}
