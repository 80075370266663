<ion-header>
    <ion-toolbar color="secondary">
        <div slot="start" class="info">
            <div class="vehicle">
                <ng-container *ngIf="vehicle$ | async as vehicle">
                    <ion-icon slot="start" [src]="'/assets/svg/vehicle-' + vehicle.icon + '.svg'"></ion-icon>
                    <div class="summary">
                        <div class="name ellipsis"><span>{{ vehicle | vehicleSummary }}</span></div>
                        <div class="ellipsis"><span>TWA {{ vehicle.towingCapacity | number }} lbs</span></div>
                    </div>
                </ng-container>
            </div>
        </div>

        <ion-buttons slot="end">
            <ion-button *ngIf="hasTowRequests" class="notifications" (click)="onShowNotifications()">
                <div class="count">{{ towRequestCount }}</div>
                <i class="material-symbols-outlined" matPrefix>map</i>
            </ion-button>

            <app-header-buttons></app-header-buttons>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">
    <main>
        <section>
            <div class="radius">
                <app-slider [min]="minRadius" [max]="maxRadius" [step]="radiusInterval" [value]="radius" (valueChangeEnd)="onRadiusChange($event)"></app-slider>
                <label>Tows within</label>
            </div>

            <div *ngIf="showVehicleSelection && !!!(vehicle$ | async)" class="overlay"></div>

            <div *ngIf="showVehicleSelection && !!!(vehicle$ | async)" class="vehicles">
                <h3>Select a Vehicle</h3>
                <ng-container *ngIf="vehicles$ | async as vehicles">
                    <ion-list *ngIf="vehicles.length > 0; else noVehicles">
                        <ion-item *ngFor="let vehicle of vehicles; let last = last" button detail
                            [class.disabled]="vehicleExpiredErrors.get(vehicle.id + '|registrationExpDate') || vehicleExpiredErrors.get(vehicle.id + '|insuranceExpDate')"
                            [lines]="last ? 'none' : 'inset'" (click)="onSelectVehicle(vehicle)">
                            <ion-icon slot="start" [src]="'/assets/svg/vehicle-' + vehicle.icon + '.svg'"></ion-icon>
                            <ion-label>
                                <div>
                                    <h2 class="ellipsis"><span>{{ vehicle | vehicleSummary }}</span></h2>
                                    <div class="summary">
                                        {{ vehicle.licensePlate }}<br>
                                        <strong>TWA {{ vehicle.towingCapacity | number }} lbs</strong>
                                    </div>
                                    <div class="messages">
                                        <div class="list-error" *ngIf="vehicleExpiredErrors.get(vehicle.id + '|registrationExpDate')">
                                            <ion-icon name="warning" class="icon"></ion-icon> Registration expired.
                                        </div>
                                        <div class="list-error" *ngIf="vehicleExpiredErrors.get(vehicle.id + '|insuranceExpDate')">
                                            <ion-icon name="warning" class="icon"></ion-icon> Insurance expired.
                                        </div>
                                    </div>
                                </div>

                                <ion-icon *ngIf="vehiclesWithTowRequestsInProgress.get(vehicle.id)" slot="icon-only" name="notifications" color="success"></ion-icon>
                            </ion-label>
                        </ion-item>
                    </ion-list>
                </ng-container>
                <ng-template #noVehicles>
                    <div class="no-vehicles">
                        <p>You don't have any vehicles yet!</p>
                        <ion-button routerLink="/driver/vehicle/new">Add Vehicle</ion-button>
                    </div>
                </ng-template>
            </div>

            <div *ngIf="tow$ | async as tow; else timer" class="tow" [class.under-header]="!!!(vehicle$ | async) || closeTow" [class.hidden]="hideTow" [class.rated]="tow.status === 7 && !closeTow">
                <div class="tow-in-progress">
                    <ng-container [ngSwitch]="tow.status">
                        <ng-container *ngSwitchDefault> <!-- Started/DriverOnWay/PickedUp/TrailerOnWay/Delivered -->
                            <app-driver-map-tow-in-progress [towDetails]="activeTowDetails$ | async" [cancellationFee]="cancellationFee$ | async" [chatUnreadMessagesCount]="chatUnreadMessagesCount$ | async"
                                (cancelTow)="onCancelTow()" (changeStatus)="onChangeStatus($event)"  (viewTowDetails)="onViewTowDetails($event)"
                                (sendReminder)="onSendReminder($event)" (openChat)="onOpenChat()"></app-driver-map-tow-in-progress>
                        </ng-container>
                        <ng-container *ngSwitchCase="[6, 7, 8].includes(tow.status) ? tow.status : -1"> <!-- Completed/Rated/Archived -->
                            <app-driver-map-tow-completed [tow]="tow" (unsetTow)="onUnsetTow()" (sendReminder)="onSendReminder($event)"></app-driver-map-tow-completed>
                        </ng-container>
                    </ng-container>
                </div>

                <div class="distance-duration" [class.show]="showDistanceDuration">
                    <ng-container *ngIf="routeData$ | async as routeData">
                        Trip: {{ routeData.distance | number }} mi | {{ routeData.duration | duration }}
                        <button type="button" (click)="onToggleTow()">
                            <img class="up" src="/assets/svg/up-arrow.svg">
                            <img class="down" src="/assets/svg/down-arrow.svg">
                        </button>
                    </ng-container>
                </div>
            </div>

            <ng-template #timer>
                <app-driver-map-timer *ngIf="upcomingTowEstimatedStartDatetime$ | async as upcomingTowEstimatedStartDatetime" class="timer"
                    [isCargo]="upcomingTowIsCargo$ | async" [datetime]="upcomingTowEstimatedStartDatetime"></app-driver-map-timer>
            </ng-template>

            <ion-button class="re-center-map-btn" shape="round" type="button" fill="clear" (click)="onReCenterMap()">
                <i class="material-symbols-outlined">my_location</i>
            </ion-button>

            <div class="tow-details" [class.hidden]="hideTowDetails" [class.no-tow-details]="noTowDetails">
                <app-driver-map-tow-details [towDetails]="towDetails$ | async" [hide]="hideTowDetails"
                    (toggleTowDetails)="onToggleTowDetails()" (viewTowDetails)="onViewTowDetails($event)" (cancelTowRequest)="onCancelTowRequest($event)"></app-driver-map-tow-details>
            </div>

            <app-map #map *ngIf="center$ | async as center" [center]="center" [markers]="markers$ | async" [centerMarker]="pin$ | async"
                (mapLoaded)="onMapLoaded()" (routeSet)="onRouteSet($event)" (selectedMarker)="onSelectedMarker($event)"></app-map>
        </section>
    </main>
</ion-content>
