import { adapter, initialState, State } from './state';
import { createReducer, on, Action } from '@ngrx/store';
import { clearUserRatings, getUserRatingsFailure, getUserRatingsRequest, getUserRatingsSuccess } from './actions';

export const userRatingsReducer = createReducer(
    initialState,
    on(
        getUserRatingsRequest,
        state => ({
            ...state,
            loading: true,
            error: null
        })
    ),
    on(
        getUserRatingsFailure,
        (state, { error }) => ({
            ...state,
            loading: false,
            error
        })
    ),

    // Get User Ratings
    on(
        getUserRatingsSuccess,
        (state, { userRatings }) => adapter.upsertOne(userRatings, {
            ...state,
            loading: false,
            error: null
        })
    ),

    // Clear User Ratings
    on(
        clearUserRatings,
        (state) => adapter.removeAll({
            ...state,
            loading: false,
            error: null
        })
    )
);

export function reducer(state: State, action: Action) {
    return userRatingsReducer(state, action);
}
