import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Rate } from '@models/rate.model';

export const adapter: EntityAdapter<Rate> = createEntityAdapter<Rate>({
    selectId: rate => rate.id
});

export interface State extends EntityState<Rate> {
    loading: boolean;
    error?: any;
    total: number;
}

export const initialState: State = adapter.getInitialState({
    loading: false,
    error: null,
    total: 0
});
