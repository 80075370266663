<ion-app>
    <ion-split-pane contentId="main" when="(min-width: 999999px)">
        <ion-menu contentId="main">
            <app-menu></app-menu>
        </ion-menu>

        <ion-router-outlet id="main"></ion-router-outlet>
    </ion-split-pane>

    <app-loading *ngIf="loading$ | async" @fade></app-loading>

    <app-bouncing-arrow *ngIf="showBouncingArrow" [direction]="bouncingArrowDirection"></app-bouncing-arrow>
</ion-app>
