import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ListHelper } from '@classes/list-helper';
import { CheckboxChangeEventDetail, ModalController } from '@ionic/angular';
import { IonCheckboxCustomEvent } from '@ionic/core';
import { DataService } from '@services/data.service';
import { BehaviorSubject, Observable, tap } from 'rxjs';

@Component({
    selector: 'app-driver-map-checklist',
    templateUrl: './driver-map-checklist.component.html',
    styleUrls: ['./driver-map-checklist.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DriverMapChecklistComponent implements OnInit {
    @Output() checklistComplete: EventEmitter<void>;

    checklist$: Observable<string[]>;
    checklist = new Map<string, boolean>();
    disabled$ = new BehaviorSubject<boolean>(true);

    constructor(
        private dataService: DataService,
        private modalController: ModalController
    ) {}

    ngOnInit(): void {
        this.checklist$ = this.dataService.getTrailerSafetyChecklist().pipe(
            tap(checklist => {
                checklist.forEach(item => {
                    this.checklist.set(item, false);
                });
            })
        );
    }

    async onClose(): Promise<void> {
        await this.modalController.dismiss();
    }

    onCheckboxChange(event: IonCheckboxCustomEvent<CheckboxChangeEventDetail<string>>): void {
        this.checklist.set(event.target.innerText, event.detail.checked);
        const list = ListHelper.convertMapToList(this.checklist);
        const checkedCount = list.filter(x => x).length;
        const allChecked = list.length === checkedCount;
        this.disabled$.next(!allChecked);
    }

    async onGo(): Promise<void> {
        this.checklistComplete.emit();
        await this.modalController.dismiss();
    }
}
