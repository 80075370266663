import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { reducer } from './reducer';
import { EffectsModule } from '@ngrx/effects';
import { DriverBusyTimeStoreEffects } from './effects';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature('driverBusyTime', reducer),
        EffectsModule.forFeature([DriverBusyTimeStoreEffects])
    ],
    providers: []
})
export class DriverBusyTimeStoreModule {}
