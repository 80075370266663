import { Injectable } from '@angular/core';
import { Unsubscribe } from '@angular/fire/firestore';
import { ObjectHelper } from '@classes/object-helper';
import { Chat } from '@models/chat.model';
import { Observable, Subject, of } from 'rxjs';
import { DbService } from './db.service';
import { Filter } from '@models/filter.model';

const COLLECTION = 'chats';

@Injectable({
    providedIn: 'root'
})
export class ChatService {
    constructor(
        private dbService: DbService
    ) {}

    saveChat(chat: Chat): Observable<Chat> {
        return this.dbService.saveObj(COLLECTION, chat);
    }

    getChatChanges(id: string, subs$: Subject<Chat>): Unsubscribe {
        const otherFixes = (chat: Chat) => this.fixDates(chat);
        return this.dbService.getObjChanges(COLLECTION, id, subs$, otherFixes);
    }

    getChatsChangesByTowId(towId: string, subs$: Subject<Chat[]>): Unsubscribe {
        const filters: Filter[] = [{ field: 'towId', operator: '==', value: towId }];
        const otherFixes = (chat: Chat) => this.fixDates(chat);
        return this.dbService.getListChanges(COLLECTION, subs$, filters, undefined, undefined, undefined, otherFixes);
    }

    getChats(ids: string[]): Observable<Chat[]> {
        if (ids.length === 0) {
            return of([]);
        }

        const filters: Filter[] = [{ field: 'id', operator: '!=', value: ids }];
        const otherFixes = (chat: Chat) => this.fixDates(chat);
        return this.dbService.getList(COLLECTION, filters, undefined, undefined, undefined, otherFixes);
    }

    private fixDates(chat: Chat): void {
        chat.messages.forEach(message => {
            ObjectHelper.fixDate(message, 'creationDate');
        });
    }
}
