import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Experience } from '@custom-types/experience.type';
import { GeoData } from '@models/geo-data.model';

@Component({
    selector: 'app-tow-locations',
    templateUrl: './tow-locations.component.html',
    styleUrls: ['./tow-locations.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TowLocationsComponent implements OnInit {
    @Input() driverAddress?: string;
    @Input() start!: GeoData;
    @Input() destination!: GeoData;
    @Input() experience: Experience = 'driver';
    @Input() isHome = false;
    @Input() driverAddressLabel?: string;

    ngOnInit(): void {
        this.setDriverAddressLabel();
    }

    private setDriverAddressLabel(): void {
        if (this.experience === 'trailer') {
            this.driverAddressLabel = 'Driver Location';
            return;
        }

        const driverAddressLabel = this.driverAddressLabel ?? 'Current Location';
        this.driverAddressLabel = this.isHome ? 'Home' : driverAddressLabel;
    }
}
