// eslint-disable-next-line no-shadow
export enum TowRequestStatus {
    Pending,
    Accepted,
    Declined,
    Approved,
    Rejected,
    Archived,
    Available,
    Canceled
}
