import { Injectable } from '@angular/core';
import { Driver } from '@models/driver.model';
import { catchError, combineLatest, map, Observable, of, Subject, switchMap } from 'rxjs';
import { FileService } from './file.service';
import { DbService } from './db.service';
import { Filter } from '@models/filter.model';
import { Unsubscribe } from 'firebase/firestore';

const COLLECTION = 'drivers';
const IMAGES_FOLDER = 'images/driver';

@Injectable({
    providedIn: 'root'
})
export class DriverService {
    constructor(
        private fileService: FileService,
        private dbService: DbService
    ) {}

    getDrivers(ids?: string[], active?: boolean): Observable<Driver[]> {
        const filters = this.getFilters(ids, active);
        return this.dbService.getList<Driver>(COLLECTION, filters);
    }

    getDriversChanges(subs$: Subject<Driver[]>, ids?: string[]): Unsubscribe {
        const filters = this.getFilters(ids);
        return this.dbService.getListChanges(COLLECTION, subs$, filters);
    }

    getDriver(id: string): Observable<Driver> {
        return this.dbService.getObj<Driver>(COLLECTION, id).pipe(
            catchError(error => {
                // Don't worry if we don't find a driver record. We make this call when user logs-in to set the active status
                if (error.name === 'NotFoundErrorImpl') {
                    throw new Error();
                }

                throw error;
            })
        );
    }

    saveDriver(driver: Driver): Observable<Driver> {
        // If they have supplied photo(s), upload it
        if (driver.driverLicensePhotoFrontFile || driver.driverLicensePhotoBackFile) {
            const driverLicensePhotoFront$ = driver.driverLicensePhotoFrontFile ?
                this.fileService.uploadFile(IMAGES_FOLDER, driver.driverLicensePhotoFrontFile, 'license-front') :
                of(driver.driverLicensePhotoFront);
            const driverLicensePhotoBack$ = driver.driverLicensePhotoBackFile ?
                this.fileService.uploadFile(IMAGES_FOLDER, driver.driverLicensePhotoBackFile, 'license-back') :
                of(driver.driverLicensePhotoBack);
            const save$ = combineLatest([driverLicensePhotoFront$, driverLicensePhotoBack$]).pipe(
                switchMap(([driverLicensePhotoFront, driverLicensePhotoBack]) => this.save({ ...driver,
                    driverLicensePhotoFront,
                    driverLicensePhotoFrontFile: undefined,
                    driverLicensePhotoBack,
                    driverLicensePhotoBackFile: undefined
                }))
            );

            // Delete the previous photo(s) (if any)
            if ((driver.driverLicensePhotoFront && driver.driverLicensePhotoFrontFile) ||
                (driver.driverLicensePhotoBack && driver.driverLicensePhotoBackFile)) {
                const frontName = driver.driverLicensePhotoFrontFile ?
                    this.fileService.getFileName(driver.driverLicensePhotoFront, IMAGES_FOLDER) :
                    null;
                const deleteFrontFile$ = frontName ? this.fileService.deleteFile(IMAGES_FOLDER, frontName).pipe(
                    catchError(() => of([])) // Don't worry if the deletion fails
                ) : of('');
                const backName = driver.driverLicensePhotoBackFile ?
                    this.fileService.getFileName(driver.driverLicensePhotoBack, IMAGES_FOLDER) :
                    null;
                const deleteBackFile$ = backName ? this.fileService.deleteFile(IMAGES_FOLDER, backName).pipe(
                    catchError(() => of([])) // Don't worry if the deletion fails
                ) : of('');
                return combineLatest([save$, deleteFrontFile$, deleteBackFile$]).pipe(
                    map(([save]) => save)
                );
            }

            return save$;
        }

        return this.save(driver);
    }

    updateDriverProperties(id: string, properties: Partial<Driver>): Observable<Partial<Driver>> {
        return this.dbService.updateObjProperties(COLLECTION, id, properties);
    }

    private save(driver: Driver): Observable<Driver> {
        return this.dbService.saveObj(COLLECTION, driver, 'set');
    }

    private getFilters(ids?: string[], active?: boolean): Filter[] {
        const filters: Filter[] = [];

        if (ids && ids.length > 0) {
            filters.push({ field: 'id', operator: 'in', value: ids });
        }

        if (active !== undefined) {
            filters.push({ field: 'active', operator: '==', value: active });
        }

        return filters;
    }
}
