// eslint-disable-next-line no-shadow
export enum TowStatus {
    Pending,
    Canceled,
    Started,
    DriverOnWay,
    TrailerOnWay,
    Delivered,
    Completed,
    Rated,
    Archived,
    PickedUp
}
