import { DriverBusyTime } from '@models/driver-busy-time.model';
import { createAction, props } from '@ngrx/store';

// Get Drivers Busy Times
export const getDriversBusyTimesRequest = createAction(
    '[DriverBusyTime] Get Drivers Busy Times Request',
    props<{ driverIds: string[], start: Date, end: Date }>()
);
export const getDriversBusyTimesFailure = createAction(
    '[DriverBusyTime] Get Drivers Busy Times Failure',
    props<{ error: string }>()
);
export const getDriversBusyTimesSuccess = createAction(
    '[DriverBusyTime] Get Drivers Busy Times Success',
    props<{ driversBusyTimes: DriverBusyTime[] }>()
);

// Get Driver Busy Times
export const getDriverBusyTimesRequest = createAction(
    '[DriverBusyTime] Get Driver Busy Times Request',
    props<{ driverId: string, start: Date }>()
);
export const getDriverBusyTimesFailure = createAction(
    '[DriverBusyTime] Get Driver Busy Times Failure',
    props<{ error: string }>()
);
export const getDriverBusyTimesSuccess = createAction(
    '[DriverBusyTime] Get Driver Busy Times Success',
    props<{ driverBusyTimes: DriverBusyTime[] }>()
);

// Save Driver Busy Time
export const saveDriverBusyTimeRequest = createAction(
    '[DriverBusyTime] Save Driver Busy Time Request',
    props<{ driverBusyTime: DriverBusyTime }>()
);
export const saveDriverBusyTimeFailure = createAction(
    '[DriverBusyTime] Save Driver Busy Time Failure',
    props<{ error: string }>()
);
export const saveDriverBusyTimeSuccess = createAction(
    '[DriverBusyTime] Save Driver Busy Time Success',
    props<{ driverBusyTime: DriverBusyTime }>()
);

// Delete Driver Busy Time
export const deleteDriverBusyTimeRequest = createAction(
    '[DriverBusyTime] Delete DriverBusyTime Request',
    props<{ towRequestId: string }>()
);
export const deleteDriverBusyTimeFailure = createAction(
    '[DriverBusyTime] Delete DriverBusyTime Failure',
    props<{ error: string }>()
);
export const deleteDriverBusyTimeSuccess = createAction(
    '[DriverBusyTime] Delete DriverBusyTime Success',
    props<{ id: string }>()
);
